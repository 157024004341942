import { Group, Button, Text, Modal, Box, Title, ActionIcon, Tooltip } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { notifications } from '@mantine/notifications'
import { Check, CircleX, Trash } from 'tabler-icons-react'
import axios from 'axios'
import routes from '../utils/routes'

const InterventionDelete = ({interventionId, machineId, isList = false}) => {
  const [opened, { close, open }] = useDisclosure(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const navigate = useNavigate()

  const deleteIntervention = () => {
    axios.delete(`/api/interventions/${interventionId}`)
      .then(res => {
        notifications.show({
          title: res.data ? 'Eliminazione riuscita!' : 'Errore!',
          message:  res.data ? 'L\'intervento è stato eliminato correttamente.' : 'Si è verificato un problema, riprova più tardi.',
          color: res.data ? 'green' : 'red',
          icon: res.data ? <Check size={18} /> : <CircleX size={18} />
        })
        setIsDeleting(false)
        if (!res.data) return
        navigate(isList ? 0 : `${routes.MACHINES_A}/${machineId}/interventions`)
      })
  }
  return(
    <>
      <Modal opened={opened} onClose={close} size="md" centered styles={{body: {padding: 0}}}>
        <Box px="lg" py="md">
          <Title ta="center">Eliminare l'intervento numero {interventionId}?</Title>
          <Text ta="center" c="dimmed" mt="lg" mb="xl">Questa azione è irreversibile.</Text>
          <Group justify="space-between">
            <Button variant="outline" onClick={close} disabled={isDeleting}>Annulla</Button>
            <Button color="red" onClick={() => deleteIntervention()} loading={isDeleting}>Elimina</Button>
          </Group>
        </Box>
      </Modal>
      {isList ?
        <Tooltip label="Elimina" withArrow>
          <ActionIcon
            size="md"
            variant="outline"
            onClick={open}
            >
            <Trash style={{width: '65%', height: '65%'}} strokeWidth={2} />
          </ActionIcon>
        </Tooltip>
      :
        <Button variant="outline" color="red" disabled={interventionId === 'new'} onClick={open}>Elimina</Button>
      }
    </>
  )
}

export default InterventionDelete
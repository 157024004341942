import { Group, Button, ThemeIcon } from '@mantine/core'
import { useViewportSize } from '@mantine/hooks'
import { ChevronLeft, ChevronRight, Calendar } from 'tabler-icons-react'
import { DatePicker, DatePickerInput } from '@mantine/dates'
import '@mantine/dates/styles.css'
import dayjs from 'dayjs'
import 'dayjs/locale/it'

import moment from 'moment'
import 'moment/locale/it'

const DateSelector = ({day, get}) => {
  moment.locale('it')
  const { height, width } = useViewportSize()

  return(
    <Group justify="space-between" mb={10} gap="sm">
      <Button
        onClick={() => get(moment().format('YYYYMMDD'))}
        variant="outline"
        fullWidth={(width <= 768)}
        size="xs"
        >
        Oggi
      </Button>
      <Group position="center" noWrap gap="sm">
        <ThemeIcon
          onClick={() => get(moment(day, 'YYYYMMDD').add(-1, 'days').format('YYYYMMDD'))}
          variant="outline"
          sx={{width: 36, height: 36}}
          size="md"
          >
          <ChevronLeft size={16} />
        </ThemeIcon>
        <DatePickerInput
          value={new Date(day.slice(0, 4), day.slice(4, 6)-1, day.slice(6, 8))}
          onChange={(date) => get(moment(date).format('YYYYMMDD'))}
          locale="it"
          maxDate={dayjs(new Date()).toDate()}
          valueFormat="dddd D MMMM YYYY"
          clearable={false}
          numberOfColumns={2}
          icon={<Calendar size={16} />}
          styles={{
            icon: {
              color: '#1c7ed6',
            },
            input: {
              borderColor: '#1c7ed6',
              color: '#1c7ed6',
              textTransform: 'capitalize',
              fontWeight: 600
            }
          }}
          size="xs"
          />
        <ThemeIcon
          onClick={() => get(moment(day, 'YYYYMMDD').add(1, 'days').format('YYYYMMDD'))}
          variant="outline"
          size="md"
          sx={{width: 36, height: 36}}
          disabled={moment(day, 'YYYYMMDD').diff(moment(), 'days') >= 0}
          >
          <ChevronRight size={16} />
        </ThemeIcon>
      </Group>
    </Group>
  )
}

export default DateSelector
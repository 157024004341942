import axios from 'axios'
import { createContext, useContext, useEffect, useState, } from 'react'
import { useMaibaContext } from './maibaContext'
import { notifications } from '@mantine/notifications'
import { Check, CircleX } from 'tabler-icons-react'
import { useNavigate } from 'react-router-dom'
import routes from './routes'

const MachineContext = createContext()

const MachineProvider = ({ children, id }) => {
  const {machines, setMachines } = useMaibaContext()
  const [machine, setMachine] = useState(machines.filter(e => e.id === id)[0])
  const [updating, setUpdating] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    setMachine({
      ...machines.filter(e => e.id === id)[0],
      clients: machines.filter(e => e.id === id)[0].users 
    })
  }, [machines])

  const updateMachine = (values) => {
    console.log(values)
    setUpdating(true)
    axios.post(`/api/machine/${id}`, values)
      .then(res => {
        if (res.data[0]) {
          setMachines(state => state.map(s => {
            if (s.id !== id) return s
            return {
              ...s,
              nickname: values.nickname ? values.nickname : s.nickname,
              group: 'group' in values ? values.group : s.group,
              users: values.users ? values.users : s.users,
              raspberry: values.raspberry ? values.raspberry : s.raspberry,
              address: values.address ? values.address : s.address,
              settings: values.settings ? values.settings : s.settings
            }
          }))
        }
        notifications.show({
          title: res.data[1][0],
          message: res.data[1][1],
          color: res.data[0] ? 'green' : 'red',
          icon: res.data[0] ? <Check size={18} /> : <CircleX size={18} />
        })
        setUpdating(false)
      })
  }

  const deleteMachine = () => {
    console.log(id)
    setUpdating(true)
    axios.delete(`/api/machine/${id}`)
      .then(res => {
        if (res.data[0]) {
          const index = machines.map(m => m.id).indexOf(id)
          setMachines(state => state.splice(index, 1))
          navigate(routes.MACHINES)
        }
        notifications.show({
          title: res.data[1][0],
          message: res.data[1][1],
          color: res.data[0] ? 'green' : 'red',
          icon: res.data[0] ? <Check size={18} /> : <CircleX size={18} />
        })
        setUpdating(false)
      })
  }

  return (
    <MachineContext.Provider value={{
      ...machine,
      id,
      updating,
      updateMachine,
      deleteMachine
    }}>
      {children}
    </MachineContext.Provider>
  )
}

const useMachineContext = () => {
  const context = useContext(MachineContext)

  // console.log(context);

  if (!context) {
    throw new Error('useMachineContext must be used within MachineProvider')
  }

  return context
}

export { MachineProvider, useMachineContext }

import { Center, Text, Image, Stack } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'

import arrow from '../assets/arrow.svg'

const EmptyScreen = () => {
  const isMobile = useMediaQuery('(max-width: 48em)')

  return(
    <Center bg="gray.0" style={{height: '100dvh'}}>
      <Stack justify="center" align="center">
        <Image
          src={arrow}
          w={120}
          style={{
            transform: isMobile ? 'rotate(90deg) translateY(-50px)' : 'none',
          }}
          />
        <Text c="dimmed" size="lg" style={{textShadow: '1px 1px 0 #fff'}}>Seleziona una voce dal menù</Text>
      </Stack>
    </Center>
  )
}

export default EmptyScreen

import { useRef, useEffect, useState } from 'react'
import { Group, Text, SimpleGrid, Image, Stack, Badge, ActionIcon, Modal, Center, Transition, Progress, Flex } from '@mantine/core'
import { Carousel, useAnimationOffsetEffect } from '@mantine/carousel'
import { Download, File as FileIcon, Photo, Upload, X, XboxX, ZoomIn } from 'tabler-icons-react'
import { Dropzone, MIME_TYPES } from '@mantine/dropzone'
import imageCompression from 'browser-image-compression'
import axios from 'axios'
import { useHover } from '@mantine/hooks'
import { FFmpeg } from '@ffmpeg/ffmpeg'
import { fetchFile } from '@ffmpeg/util'
import { saveAs } from 'file-saver'

import '@mantine/dropzone/styles.css'
import '@mantine/carousel/styles.css'

const InterventionImages = ({form, images, isContact = false}) => {
  const [files, setFiles] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [modal, setModal] = useState({
    opened: false,
    index: 0
  })
  const [ffmpeg, set_ffmpeg] = useState(new FFmpeg())
  const [op_progress, set_op_progress] = useState(null)

  useEffect(() => {
    const medias = !isContact && form && form.values && form.values.media ? 
      form.values.media
      :
      images ?
        images 
        : 
        []
    medias.filter(m => files.filter(f => f.name === m).length === 0).map(f => download(f))
  }, [])

  const upload = async (uploadedFile, index, tot) => {
    let compressedFile = uploadedFile
    let newName = uploadedFile.name
    set_op_progress({
      value: 0,
      label: `Compressione file ${index}/${tot}`
    })

    // image compression
    if ([MIME_TYPES.png, MIME_TYPES.jpeg].includes(uploadedFile.type)) {
      try {
        compressedFile = await imageCompression(uploadedFile, {
          maxSizeMB: .5,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
          onProgress: pr => set_op_progress({
            value: pr,
            label: `Compressione file ${index}/${tot}`
          })
        })
      } catch (error) {
        console.log(error)
      }
    }
    // video compression
    if ([MIME_TYPES.mp4, 'video/quicktime'].includes(uploadedFile.type)) {
      try {
        if (ffmpeg.loaded === false) {
          await ffmpeg.load()
          ffmpeg.on("log", ({ message }) => {
            console.log(message);
          })
          ffmpeg.on("progress", ({ progress }) => {
            console.log(`Video compression ${progress * 100}%`)
            set_op_progress({
              value: progress * 100,
              label: `Compressione file ${index}/${tot}`
            })
          })
        }
        console.log('index file', index)
        newName = `${newName}.mp4`
        await ffmpeg.writeFile(uploadedFile.name, await fetchFile(uploadedFile))
        await ffmpeg.exec([
          '-i', uploadedFile.name,
          '-preset', 'superfast',
          newName])
        const fileData = await ffmpeg.readFile(newName)
        compressedFile = new File([new Blob([fileData])], newName, { type: 'video/mp4' })

      } catch (error) {
        console.log(error)
        set_op_progress(null)
      }
    }
    
    console.log(`${uploadedFile.path} from ${uploadedFile.size / 1024 / 1024} MB to ${compressedFile.size / 1024 / 1024} MB`) // smaller than maxSizeMB

    const formData = new FormData()
    formData.append('file', compressedFile, newName)
    
    axios.post(`/api/interventions/image`, formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (event) => {
          set_op_progress({
            value: event.progress * 100,
            label: `Caricamento file ${index}/${tot}`
          })
        }
      })
      .then(res => {
        console.log('interventions/image', 'POST', res.data)
        if (!res.data) return

        form.insertListItem(isContact ? 'request.media' : 'media', res.data)
        setFiles(state => [...state, compressedFile])
        set_op_progress(null)
      })
  }

  const download = (filename) => {
    axios.get(`/api/interventions/image/${filename}`, {responseType: 'blob'})
      .then(res => {
        console.log(`interventions/image/${filename}`, 'GET', res.data)
        //if (res.data.size === 0) return

        const file = new File([res.data], filename)
        
        console.log(file)
        setFiles(state => [...state, file])
      })
  }

  const remove = (filename, index) => {
    form.removeListItem(isContact ? 'request.media' : 'media', index)
    form.insertListItem('removed_media', filename)
    setFiles(state => state.filter(s => s.name != filename))
  }
  

  return(
    <Stack gap={3}>
      {(form || images && !isContact) && <Text size="xs" fw={500}>Media</Text>}
      {form &&
        <>
          <Dropzone
            onDrop={async files => {
              console.log('accepted files', files)
              setIsLoading(true)
              let current = 1
              for(let f of files) {
                console.log('file', current)
                await upload(f, current, files.length)
                current++
              }
              setIsLoading(false)
            }}
            onReject={(files) => console.log('rejected files', files)}
            maxSize={300 * 1024 ** 2}
            accept={[
              MIME_TYPES.png,
              MIME_TYPES.jpeg,
              MIME_TYPES.mp4,
              'video/quicktime',
              MIME_TYPES.pdf,
              MIME_TYPES.xls,
              MIME_TYPES.xlsx,
              MIME_TYPES.csv,
              MIME_TYPES.doc,
              MIME_TYPES.docx
            ]}
            loading={isLoading}
            >
            <Group justify="center" gap="md" mih={120} style={{ pointerEvents: 'none' }}>
              <Dropzone.Accept style={{backgroundColor: 'var(--mantine-color-green-light)', borderRadius: 'var(--mantine-radius-default)', padding: 1}}>
                <Upload
                  size={44}
                  stroke="var(--mantine-color-green-filled)"
                  strokeWidth={1.5}
                />
              </Dropzone.Accept>
              <Dropzone.Reject style={{backgroundColor: 'var(--mantine-color-red-light)', borderRadius: 'var(--mantine-radius-default)', padding: 1}}>
                <X
                  size={44}
                  stroke="var(--mantine-color-red-filled)"
                  strokeWidth={1.5}
                />
              </Dropzone.Reject>
              <Dropzone.Idle style={{backgroundColor: 'var(--mantine-color-blue-light)', borderRadius: 'var(--mantine-radius-default)', padding: 1}}>
                <Photo
                  size={44}
                  stroke="var(--mantine-color-blue-filled)"
                  strokeWidth={1.5}
                />
              </Dropzone.Idle>
              <Text size="md" inline>
                Clicca o trascina qui le tue foto o i tuoi documenti per caricarli
              </Text>
            </Group>
          </Dropzone>
          {op_progress != null &&
            <Progress.Root size="xl" radius="xl" autoContrast>
              <Progress.Section value={op_progress.value}>
                <Progress.Label>{op_progress.label}</Progress.Label>
              </Progress.Section>
            </Progress.Root>
          }
        </>
      }
      <SimpleGrid cols={{ base: 2, sm: 4 }} mt={files.length > 0 ? 'sm' : 0}>
        {files.map((file, index) => <FilePreview key={index} file={file} index={index} setModal={setModal} remove={remove} isEdit={form ? true : false} />)}
        {!images && !form && <Text size="sm">---</Text>}
      </SimpleGrid>
      <MediaCarousel media={files} modal={modal} setModal={setModal} />
    </Stack>
  )
}

const FilePreview = ({file, index, setModal, remove, isEdit}) => {
  const { hovered, ref } = useHover()
  return(
    <div key={file.name} ref={ref}>
      <div style={{position: 'relative'}}>
        <FileViewer file={file} />
        {isEdit && 
          <ActionIcon color="red" size="sm" variant="filled" radius="xl" styles={{root: {position: 'absolute', top: 10, right: 10}}} onClick={() => remove(file.name, index)}>
            <X size={14} />
          </ActionIcon>
        }
        <Transition
          mounted={hovered}
          transition="fade"
          duration={400}
          timingFunction="ease"
          >
          {styles =>
            <ActionIcon size="lg" variant="filled" radius="xl" styles={{root: {...styles, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)'}}} onClick={() => setModal({opened: true, index})}>
              <ZoomIn size={14} />
            </ActionIcon>
          }
        </Transition>
      </div>
    </div>
  )
}

const MediaCarousel = ({media, modal, setModal}) => {
  //console.log(modal.index)
  const [index, setIndex] = useState(modal.index)
  const [embla, setEmbla] = useState(null)

  const TRANSITION_DURATION = 200

  useAnimationOffsetEffect(embla, TRANSITION_DURATION)

  return (
    <Modal
      className="carousel-modal"
      opened={modal.opened}
      withCloseButton={true}
      onClose={() => setModal({...modal, opened: false})}
      title={media[index] ? media[index].name : 'Media'}
      fullScreen
      radius={0}
      transitionProps={{ duration: TRANSITION_DURATION }}
      >
      <Carousel
        loop
        slideSize="70%"
        slideGap="md"
        withIndicators
        onSlideChange={i => setIndex(i)}
        getEmblaApi={setEmbla}
        initialSlide={modal.index}
        >
        {media.map(file => 
          <Carousel.Slide key={file.name}>
            <Center>
              <FileViewer file={file} />
            </Center>
          </Carousel.Slide>
        )}
      </Carousel>
    </Modal>
  )
}

const FileViewer = ({file}) => {
  //console.log(file.name)
  const imageUrl = URL.createObjectURL(file)
  const ext = file.name.split('.').pop().toLowerCase()

  return(
    <div style={{position: 'relative', borderRadius: 'var(--mantine-radius-sm)', backgroundColor: '#000', padding: ext === 'mp4' ? '40px 0' : 0}}>
      {[
        'jpg',
        'jpeg',
        'png'
        ].includes(ext) &&
        <Image src={imageUrl} onLoad={() => URL.revokeObjectURL(imageUrl)} radius="sm" maw="85vw" mah="80vh" />
      }
      {[
        'mp4',
        'mov',
        'm4v',
        'mkv'
        ].includes(ext) &&
        <video style={{width: '100%', borderRadius: 'var(--mantine-radius-sm)', maxWidth: '85vw', maxHeight: '80vh'}} controls controlsList="nodownload" onClick={(e) => e.preventDefault()}>
          <source src={imageUrl} onLoad={() => URL.revokeObjectURL(imageUrl)} type={`video/${ext}`} />
        </video>
      }
      {[
        'pdf',
        'xls',
        'xlsx',
        'csv',
        'doc',
        'docx'
        ].includes(ext) &&
        <Stack justify="center" align="center" p="md" w={300} maw="100%" mah="85vh" styles={{root: {aspectRatio: '9/11'}}}>
          <FileIcon size={64} strokeWidth={1} color="white" />
          <Text c="white" size="xs" ta="center" maw="100%" styles={{root: {whiteSpace: 'normal', wordBreak: 'break-word'}}}>{file.name}</Text>
        </Stack>
      }
      <Group styles={{root: {position: 'absolute', bottom: 10, left: 10, right: 10}}} gap={5} align="center">
        <ActionIcon onClick={() => saveAs(file)} size="sm" radius="xl">
          <Download size={13} />
        </ActionIcon>
        <Flex direction={{ base: 'column', sm: 'row' }} align={{ base: 'flex-end', sm: 'center'}} gap={5} ml="auto">
          <Badge color="rgba(255,255,255,.5)" size="xs">{ext}</Badge>
          <Badge color="rgba(255,255,255,.5)" size="xs">{(file.size / 1024 / 1024).toFixed(2)} MB</Badge>
        </Flex>
      </Group>
    </div>
  )
}

export default InterventionImages
import axios from 'axios'
import { useEffect } from 'react'

const toMQTT = async (messages) => {
  for (const m in messages) {
    const id = messages[m].id
    const topic = messages[m].topic
    const message = messages[m].message

    console.log('send', {id, topic, message})

    const res = await axios.post(`/api/mqtt`, {id, topic, message})
    
    console.log('receive', res.data)

    if (
      res.data.result !== 'ok'
    //  res.data.result === 'error'
      ) return 'error'

    if (parseInt(m) === messages.length-1) return res.data
  }
}

const generateMarks = (min, max) => {
  const mark = (num) => {
    return num < 1 ? num : num.toFixed(1)
  }
  return [
    { value: min, label: min },
    { value: min+(max-min)/4, label: mark(min+(max-min)/4) },
    { value: min+(max-min)/2, label: mark(min+(max-min)/2) },
    { value: min+(max-min)*3/4, label: mark(min+(max-min)*3/4) },
    { value: max, label: max }
  ]
}

const ALARMS = {
  Online: 'Connesso',
  Offline: 'Disconnesso',
  AL0: 'Mancanza pressione acqua',
  AL5: 'Carbonatore: timeout riempimento',
  AL6: 'Dati corrotti',
  AL7: 'Filtro esaurito',
  AL9: 'Orologio',
  AL10: 'Mancanza CO2',
  AL11: 'Allagamento',
  AL12: 'Lampada UV 1',
  AL13: 'Lampada UV 2',
  AL14: 'Compressore: pressione minima',
  AL15: 'Compressore: pressione massima',
  AL16: 'Bombola CO2 1 vuota',
  AL17: 'Bombola CO2 2 vuota',
  AL18: 'Bombola CO2 3 vuota',
  AL19: 'Bombola CO2 4 vuota',
  AL20: 'Lampada UV 1 guasta (lampada UV filtro)',
  AL21: 'Lampada UV 2 guasta (lampada UV erogatore)',
}
const getAlarmDefinition = (code) => {
  return ALARMS[code] || 'Codice sconosciuto'
}

const useExternalScripts = ( url ) => {
  if (document.querySelector(`script[src="${url}"]`) !== null) return

  const head = document.querySelector('head')
  const script = document.createElement('script')

  script.setAttribute('src', url)
  head.appendChild(script)
}

const interventionTypes = [
  {
    category: 'main',
    value: 'ordinary',
    label: 'Manutenzione ordinaria',
  },
  {
    category: 'main',
    value: 'straordinary',
    label: 'Manutenzione straordinaria',
  },
  {
    category: 'main',
    value: 'check&clean',
    label: 'Verifica e pulizia',
  },
  {
    category: 'main',
    value: 'deliver_co2',
    label: 'Consegna CO2',
  },
  {
    category: 'main',
    value: 'withdrawal',
    label: 'Scassettamento',
  },
  {
    category: 'main',
    value: 'requested',
    label: 'Intervento su richiesta',
  },
  {
    category: 'main',
    value: 'urgent',
    label: 'Urgente',
  },
  {
    category: 'main',
    value: 'other_type',
    label: 'Altro',
  },
  {
    category: 'sanification',
    value: 'sanification_pipelines',
    label: 'Sanificazione condutture',
  },
  {
    category: 'sanification',
    value: 'sanification_filters',
    label: 'Sanificazione sistemi di filtrazione',
  },
  {
    category: 'sanification',
    value: 'sanification_equipment',
    label: 'Sanificazione apparecchiature',
  },
  {
    category: 'sanification',
    value: 'sanification_dispensers',
    label: 'Sanificazione erogatori',
  },
  {
    category: 'cleaning',
    value: 'cleaning_crs',
    label: 'Pulizia CRS',
  },
  {
    category: 'cleaning',
    value: 'cleaning_internal_system',
    label: 'Pulizia interno',
  },
  {
    category: 'cleaning',
    value: 'cleaning_external_system',
    label: 'Pulizia esterno',
  },
  {
    category: 'checks',
    value: 'check_crs',
    label: 'Verifica CRS/pagamento',
  },
  {
    category: 'checks',
    value: 'check_sparkling',
    label: 'Verifica dose/erogazione frizzante',
  },
  {
    category: 'checks',
    value: 'check_still',
    label: 'Verifica dose/erogazione naturale',
  },
  {
    category: 'checks',
    value: 'check_keys',
    label: 'Verifica tasti',
  },
  {
    category: 'checks',
    value: 'check_uvc',
    label: 'Verifica lampada UVC',
  },
  {
    category: 'checks',
    value: 'check_refrigeration',
    label: 'Verifica/controllo gruppo frigo',
  },
  {
    category: 'checks',
    value: 'check_heatingcooling',
    label: 'Verifica raffrescamento/riscaldamento',
  },
  {
    category: 'checks',
    value: 'check_lights',
    label: 'Verifica luci',
  },
  {
    category: 'checks',
    value: 'check_traps',
    label: 'Verifica trappole',
  },
]

const interventionStatusColor = {
  'Presa in carico': 'gray',
  'In lavorazione': 'yellow',
  'Concluso': 'teal'
}

const user_info_label = 'iot-maiba-user'
const user_was_admin_label = 'iot-maiba-user-was-admin'

const COUNTS = {
  // machineTime: {
  //   label: 'Totale ore di macchina accesa',
  //   unit: 'ore'
  // },
  ambientVolTot: {
    label: 'Flowmeter - acqua a temperatura ambiente',
    unit: 'litri'
  },
  coldVolTot: {
    label: 'Flowmeter - acqua fredda',
    unit: 'litri'
  },
  gasVolTot: {
    label: 'Flowmeter - acqua gassata',
    unit: 'litri'
  },
  filterVol: {
    label: 'Contatore filtro dall\'ultimo azzeramento',
    unit: 'litri'
  },
  ambientVol: {
    label: 'Flowmeter - acqua a temperatura ambiente dall’ultimo azzeramento',
    unit: 'litri'
  },
  coldVol: {
    label: 'Flowmeter - acqua fredda dall’ultimo azzeramento',
    unit: 'litri'
  },
  gasVol: {
    label: 'Flowmeter - acqua gassata dall’ultimo azzeramento',
    unit: 'litri'
  },
}

export {toMQTT, generateMarks, ALARMS, COUNTS, getAlarmDefinition, useExternalScripts, interventionTypes, interventionStatusColor, user_info_label, user_was_admin_label}

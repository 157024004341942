import { useState, useEffect } from 'react'
import axios from 'axios'

import { useMachineContext } from '../../utils/machineContext'
import Loading from '../Loading'
import Section from './Section'
import { Button, Group, Select, Text, Title } from '@mantine/core'
import { notifications } from '@mantine/notifications'
import { Check, CircleX } from 'tabler-icons-react'

import { toMQTT } from '../../utils/func'

const Tosini = () => {
  const { id, raspberry, setRaspberry } = useMachineContext()

  const [loading, setLoading] = useState(true)
  
  const [isSending, setIsSending] = useState(false)
  const [isConnecting, setIsConnecting] = useState(raspberry ? false : true)
  const [isRebooting, setIsRebooting] = useState(false)

  const [raspberries, setRaspberries] = useState([])
  const [raspberryField, setRaspberryField] = useState()

  useEffect(() => {
    if (!isConnecting || raspberries.length > 0) {
      setLoading(false)
      return
    }

    axios.get(`/api/raspberries`)
      .then((res) => {
        console.log('raspberries', res.data)
        setRaspberries([
          'Nessuno',
          ...res.data
        ])
        setLoading(false)
      })
  }, [isConnecting])

  const saveRaspberry = () => {
    setIsSending(true)
    setRaspberry(raspberryField === 'Nessuno' ? undefined : raspberryField)
      .then(() => {
        
        axios.post(`/api/machine/${id}/raspberry`, {raspberry: raspberryField})
          .then((res) => {
            console.log('saveRaspberry', res.data)
            setIsSending(false)
            setIsConnecting(raspberryField === 'Nessuno')
            notifications({
              title: res.data[0] ? 'Abbinamento salvato' : 'Errore',
              message: <><Text size="sm">{res.data[1][0]}</Text><Text size="sm">{res.data[1][1]}</Text></>
            })
          })
      })
  }

  return(
    <Section title="Raspberry">
      {loading && <Loading />}
      {!loading &&
        <>
          <Group
            position="center"
            direction="column"
            spacing={12}
            p={24}
            my={12}
            sx={{
              border: '1px solid #eee',
              borderRadius: 12
            }}
            >
            {isConnecting &&
            <>
              <Text>Seleziona il raspberry da collegare al dispositivo</Text>
              <Group position="center" direction="row">
                <Select
                  placeholder="Seleziona un raspberry..."
                  data={raspberries}
                  value={raspberryField}
                  onChange={setRaspberryField}
                  zIndex={999999}
                  />
                {raspberry &&
                  <Button onClick={() => setIsConnecting(false)} color="red" disabled={isSending}>
                    Annulla
                  </Button>
                }
                <Button onClick={saveRaspberry} loading={isSending}>
                  Salva
                </Button>
              </Group>
            </>
            }
            {!isConnecting &&
            <>
              <Text>Il dispositivo è collegato con il raspberry</Text>
              <Group>
                <Text weight={700}>{raspberry}</Text>
                <Button onClick={() => setIsConnecting(true)} size="xs" compact variant="outline">Modifica</Button>
              </Group>
            </>
            }
          </Group>
          <Title order={4} align="center">Comandi</Title>
          <Group position="center">
            <Button
              disabled={!raspberry || isConnecting}
              loading={isRebooting}
              onClick={() => {
                setIsRebooting(true)
                toMQTT([{
                  id: raspberry,
                  topic: `/Tosini/${raspberry}/Reboot`,
                  message: {}
                }])
                  .then(res => {
                    if (res.result === 'ok') {
                      setIsRebooting(false)
                      notifications.show({
                        title: 'Successo',
                        message: 'Riavvio avvenuto con successo!',
                        color: 'green',
                        icon: <Check size={18} />
                      })
                    } else {
                      notifications.show({
                        title: 'Errore',
                        message: 'Il riavvio non è stato portato a termine!',
                        color: 'red',
                        icon: <CircleX size={18} />
                      })
                    }
                    setIsRebooting(false)
                  })
              }}
              >
              Riavvia raspberry
            </Button>
          </Group>
        </>
      }
    </Section>
  )
}

export default Tosini
import { Button, Text } from "@mantine/core"
import { Link } from "react-router-dom"

const NavItemSmall = ({ icon, route, title, subtitle }) => {
  return (
    <Button
      leftSection={icon}
      variant="subtle"
      fullWidth
      px="md"
      styles={{
        root: {
          borderRadius: 0,
          
        },
        inner: {
          justifyContent: 'flex-start',
        },
      }}
      component={Link}
      to={route}
      size="xs"
      fw="700"
      >
      <span style={{
        flexShrink: 0,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
        }}>
        {title}
      </span>
      <span style={{
        color: 'var(--mantine-color-dimmed)',
        fontWeight: 400,
        marginLeft: '5px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        flexShrink: 2,
      }}>{subtitle}</span>
    </Button>
  )
}

export default NavItemSmall
import { Text } from "@mantine/core"
import axios from "axios"
import { useEffect, useState } from "react"
import dayjs from "dayjs"

const getLastReplace = async (interventionId, machineId, what) => {
  const res = await axios.get(`/api/interventions/last-replace/${machineId}/${what}`)

  console.log('last-replace', what, res.data)
  
  if (!res.data || (!res.data.replace && !res.data.expiration)) return 'Non è mai stata effettuata una sostituzione.'

  if (interventionId === res.data.interventionId) return 'Materiale in uso attualmente.'

  const replace = dayjs(res.data.replace).isValid() ? dayjs(res.data.replace).format('DD/MM/YYYY') : '(nessuna data inserita)'
  const expiration = dayjs(res.data.expiration).isValid() ? dayjs(res.data.expiration).format('DD/MM/YYYY') : '(nessuna data inserita)'
  
  return  `Materiale in uso sostituito il ${replace} con scadenza il ${expiration}, intervento ${res.data.interventionId}.`
}

const InterventionLastReplace = ({what, machineId, interventionId}) => {
  const [phrase, set_phrase] = useState('Caricamento informazioni...')

  useEffect(() => {

    getLastReplace(interventionId, machineId, what)
      .then(phrase => set_phrase( phrase ))

  }, [])

  return(
    <Text size="xs" fs="italic" mt="xs" mb={0}>{phrase}</Text>
  )
}

export default InterventionLastReplace
export { getLastReplace }
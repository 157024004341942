
import { useForm } from '@mantine/form'
import { TextInput, Badge, NumberInput, Group, Button, Select, SimpleGrid, Stack, Chip, Text, Modal, Accordion, Table, Box, Title, MultiSelect, Textarea, Checkbox, SegmentedControl, Slider, Alert, NumberFormatter, Divider } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { DateTimePicker, DatePickerInput } from '@mantine/dates'
import { notifications } from '@mantine/notifications'
import { AlertCircle, Check, CircleX, Download } from 'tabler-icons-react'
import '@mantine/dates/styles.css'
import { useMaibaContext } from '../utils/maibaContext'
import Loading from './Loading'
import { useEffect, useState } from 'react'
import axios from 'axios'
import dayjs from 'dayjs'
import it from 'dayjs/locale/it'
import { Link, useNavigate, useParams } from 'react-router-dom'
import routes from '../utils/routes'
import { interventionTypes, interventionStatusColor } from '../utils/func'
import { InterventionPDF } from './InterventionPDF'
import InterventionImages from './InterventionImages'
import InterventionLastReplace from './InterventionLastReplace'

const InterventionView = ({ machineId }) => {
  const { machines, loading } = useMaibaContext()
  const [isLoading, setIsLoading] = useState(true)
  const [intervention, setIntervention] = useState(false)
  const navigate = useNavigate()
  const { interventionId } = useParams()

  const initialValues = {
    machineId: false,
    date: false,
    deadline: false,
    status: false,
    types: [],
    tank_temp: false,
    co2_bar: false,
    co2_level: false,
    co2_bottles: {
      '01': {
        capacity: false,
        residue: false,
      },
      '02': {
        capacity: false,
        residue: false,
      },
      '03': {
        capacity: false,
        residue: false,
      },
      '04': {
        capacity: false,
        residue: false,
      },
    },
    sanification_product: false,
    sanification_time: false,
    ph_an: false,
    ph_ag: false,
    ph_tool: false,
    filter_type: null,
    filter_capacity: null,
    sampling_microbiological: false,
    sampling_chemical: false,
    free_crs: false,
    replace: {
      uv_filter_lamp: false,
      uv_filter_lamp_date: false,
      uv_filter_lamp_expiration: false,
      uv_filter_lamp_dispenser: false,
      uv_filter_lamp_dispenser_date: false,
      uv_filter_lamp_dispenser_expiration: false,
      beccuccio: false,
      beccuccio_date: false,
      beccuccio_expiration: false,
    },
    cont_gen_m3: false,
    cont_an_m3: false,
    cont_ag_m3: false,
    parts: [],
    technicians: [],
    other_works: false,
    notes: false,
  }

  useEffect(() => {
    axios.get(`/api/interventions/${interventionId}`)
      .then(res => {
        if (!res.data) return
        console.log(res.data)
        
        setIntervention({
          ...initialValues,
          ...res.data
        })
        
        setIsLoading(false)
      })

  }, [])

  const Value = ({label, value}) => {
    if (!value || value.length === 0) value = '---'
    return(
      <Stack gap={0}>
        <Text size="xs" fw={500}>{label}</Text>
        {typeof value != 'object' ?
          <Text size="sm">{value}</Text>
          :
          <>{value}</>
        }
      </Stack>
    )
  }

  return(
    <>
      {loading.machines || isLoading && <Loading />}
      {!loading.machines && !isLoading &&
        
          <Stack gap="xl">
            <SimpleGrid cols={{base: 1, sm: 2}}>
              <Stack>
                <Value label="Intervento numero" value={<Badge size="lg" variant="light">{interventionId}</Badge>} />
                <Value label="Dispositivo" value={<><Text>{machines.filter(m => m.id === intervention.machineId)[0].nickname}</Text><Text size="xs">{intervention.machineId}</Text></>} />
              </Stack>
              <Stack>
                <Value label="Data" value={dayjs(intervention.date).locale(it).format('dddd D MMMM YYYY HH:mm')} />
                {intervention.status != 'Concluso' &&
                  <Value label="Termine lavori entro" value={dayjs(intervention.deadline).isValid() ? dayjs(intervention.deadline).locale(it).format('dddd D MMMM YYYY') : false} />
                }
              </Stack>
            </SimpleGrid>
            <Divider />
            <SimpleGrid cols={{base: 1, sm: 2}}>
              <Value label="Stato" value={<Badge color={interventionStatusColor[intervention.status]}>{intervention.status}</Badge>} />
              <Value label="Tipologia" value={interventionTypes.filter(t => t.category === 'main' && intervention.types.includes(t.value)).map(t => <Badge key={t.value} variant="light" m={2} color={t.value === 'urgent' ? 'red' : 'blue'}>{t.value === 'other_type' ? intervention.other_type : t.label}</Badge>)} />
            </SimpleGrid>
            <SimpleGrid cols={{base: 1, sm: 2, md: 2, lg: 3}}>
              <Value label="Sanificazioni" value={interventionTypes.filter(t => t.category === 'sanification' && intervention.types.includes(t.value)).map(t => <Badge key={t.value} variant="light" size="sm" m={2}>{t.label}</Badge>)} />
              <Value label="Pulizie" value={interventionTypes.filter(t => t.category === 'cleaning' && intervention.types.includes(t.value)).map(t => <Badge key={t.value} variant="light" size="sm" m={2}>{t.label}</Badge>)} />
              <Value label="Verifiche" value={interventionTypes.filter(t => t.category === 'checks' && intervention.types.includes(t.value)).map(t => <Badge key={t.value} variant="light" size="sm" m={2}>{t.label}</Badge>)} />
            </SimpleGrid>
            <Divider />
            <Stack>
              <SimpleGrid cols={{base: 1, sm: 3}}>
                <Value label="Temperatura vasca" value={intervention.tank_temp ? <NumberFormatter value={intervention.tank_temp} suffix="°" decimalScale={1} decimalSeparator="," /> : '---'} />
                
                <Value label="Pressione CO2" value={intervention.co2_bar ? <NumberFormatter value={intervention.co2_bar} suffix=" bar" decimalScale={2} decimalSeparator="," /> : '---'} />

                <Value label="Livello CO2" value={intervention.co2_level ? <NumberFormatter value={intervention.co2_level} suffix="%" /> : '---'} />
              </SimpleGrid>
              <SimpleGrid cols={{base: 1, xs: 2, sm: 2, md: 2, lg: 4}}>
                {['01', '02', '03', '04'].map(n =>
                  <Stack key={n} p="xs" pb="md" styles={{root: {border: '1px solid var(--mantine-color-gray-3)', borderRadius: 'var(--mantine-radius-sm)'}}}>
                    <Value label={`Bombola ${n} presente`} value={intervention.co2_bottles[n].is_present ? 'Sì' : false} />
                    <Value label={`Capienza bombola ${n}`} value={intervention.co2_bottles[n].capacity} />
                    <Value label={`Residuo bombola ${n}`} value={intervention.co2_bottles[n].residue} />
                  </Stack>  
                )}
              </SimpleGrid>
            </Stack>
            <Divider />
            <SimpleGrid cols={{base: 1, sm: 3}}>
              <Value label="Prodotto sanificante" value={intervention.sanification_product} />
              <Value label="Tempo di contatto e % sol." value={intervention.sanification_time} />
            </SimpleGrid>
            <SimpleGrid cols={{base: 1, sm: 3}}>
              <Value label='Tipologia filtro installato' value={intervention.filter_type} />
              <Value label='Capacità' value={intervention.filter_capacity} />
            </SimpleGrid>
            <SimpleGrid cols={{base: 1, sm: 3}}>
              <Stack gap="xs">
                <Value label="Strumento tar. PH" value={intervention.ph_tool} />
                <Value label={<>Cont. gen. m<sup>3</sup></>} value={intervention.cont_gen_m3 ? <NumberFormatter value={intervention.cont_gen_m3} decimalScale={2} decimalSeparator="," /> : '---'} />
              </Stack>
              <Stack gap="xs">
                <Value label="Valore PH AN (da 6,5 a 9,5)" value={intervention.ph_an ? <NumberFormatter value={intervention.ph_an} decimalScale={2} decimalSeparator="," /> : '---'} />
                <Value label={<>Cont. AN m<sup>3</sup></>} value={intervention.cont_an_m3 ? <NumberFormatter value={intervention.cont_an_m3} decimalScale={2} decimalSeparator=","/> : '---'} />
              </Stack>
              <Stack gap="xs">
                <Value label="Valore PH AG (da 4,5 a 9,5)" value={intervention.ph_ag ? <NumberFormatter value={intervention.ph_ag} decimalScale={2} decimalSeparator="," /> : '---'} />
                <Value label={<>Cont. AG m<sup>3</sup></>} value={intervention.cont_ag_m3 ? <NumberFormatter value={intervention.cont_ag_m3} decimalScale={2} decimalSeparator="," /> : '---'} />
              </Stack>
            </SimpleGrid>
            <SimpleGrid cols={{base: 1, sm: 3}}>
              <Value label="Campionamento microbiologico" value={intervention.sampling_microbiological ? 'Sì' : 'No'} />
              <Value label="Campionamento chimico" value={intervention.sampling_chemical ? 'Sì' : 'No'} />
              <Value label="CRS in libera" value={intervention.free_crs ? 'Sì' : false} />
            </SimpleGrid>
            <SimpleGrid cols={{base: 1, sm: 3}}>
              {[
                {
                  label_checkbox: 'Sostituzione lamp. filtro UV',
                  key: 'uv_filter_lamp'
                },
                {
                  label_checkbox: 'Sostituzione lamp. filtro UV erogatori',
                  key: 'uv_filter_lamp_dispenser'
                },
                {
                  label_checkbox: 'Inst.ne punto di campionamento ingresso',
                  key: 'beccuccio'
                }
              ].map(fi => 
                <Stack gap="sm" key={fi.key} p="xs" styles={{root: {border: '1px solid var(--mantine-color-gray-3)', borderRadius: 'var(--mantine-radius-sm)'}}}>
                  <Value label={fi.label_checkbox} value={intervention.replace[fi.key] ? 'Sì' : 'No'} />
                  <Value label="Data sostituzione" value={dayjs(intervention.replace[`${fi.key}_date`]).isValid() ? dayjs(intervention.replace[`${fi.key}_date`]).locale(it).format('dddd D MMMM YYYY') : false} />
                  <Value label="Data scadenza" value={dayjs(intervention.replace[`${fi.key}_expiration`]).isValid() ? dayjs(intervention.replace[`${fi.key}_expiration`]).locale(it).format('dddd D MMMM YYYY') : false} />
                  <InterventionLastReplace what={fi.key} interventionId={interventionId} machineId={intervention.machineId} />
                </Stack>
              )}
            </SimpleGrid>
            <SimpleGrid cols={{base: 1, xs: 2}}>
              <Value
                label="Ricambi utilizzati"
                value={intervention.parts.map(part => <Text size="sm">{part.amount}x {part.value}</Text>)}
                />
              <Box>
                <Value
                  label="Tecnici"
                  value={intervention.technicians.join(', ')}
                  />
                <SimpleGrid cols={2}>
                  <Value
                    label="Uscite"
                    value={intervention.technicians_exits}
                    />
                  <Value
                    label="Ore"
                    value={intervention.technicians_hours}
                    />
                </SimpleGrid>
              </Box>
            </SimpleGrid>
            <SimpleGrid cols={{base: 1, xs: 2}}>
              <Box>
                <Value
                  label={intervention.request ? `Richiesta del ${dayjs(intervention.request.date).locale(it).format('D MMMM YYYY')} ${dayjs(intervention.request.date).format('hh:mm')} da ${intervention.request.email}` : 'Nessuna richiesta dal cliente'}
                  value={intervention.request ? intervention.request.message : ''}
                  />
                <InterventionImages images={intervention.request?.media} isContact />
              </Box>
              <Value
                label="Altri lavori eseguiti"
                value={intervention.other_works}
                />
            </SimpleGrid>
            <Value
              label="Segnalazioni e suggerimenti"
              value={intervention.notes}
              />
            <InterventionImages images={intervention.media} />
            <Group justify="center" py="xl">
              <InterventionPDF interventionId={interventionId} buttonLarge />
            </Group>
          </Stack>
      }
    </>
  )
}

export default InterventionView
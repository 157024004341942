import { Group, Text, Title, Card, useMantineTheme, Stack } from '@mantine/core'
import { ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts'

const Chart = ({title, definition, data, values}) => {
  console.log('chart', title)
  
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length > 0) {
      // console.log('tooltip', payload, label)
      return (
        <Card shadow="lg" p="sm">
          <Text pb={1} align="center" weight={700}>{label}</Text>
          <Stack gap={0}>
            {!payload && <Text>Nessun valore!</Text>}
            {payload.map(item => {
              const LabelList = ({name, color}) => {
                const [none, key] = item.dataKey.split('.')
                const value = (definition[key]) ?
                  (key === 'alarm') ?
                    definition.alarm[name]
                    :
                    (typeof definition[key] === 'string') ?
                      definition[key].replace('[value]', item.value)
                      :
                      definition[key][item.value]
                  :
                  'Valore sconosciuto'
                return (
                  <Group key={name} gap="xs" noWrap>
                    <div style={{width: 8, height: 8, borderRadius: 10, marginTop: 1, backgroundColor: color}}></div>
                    {name !== 'payload.alarm.value' &&
                      <Text size="xs">{name}</Text>
                    }
                    <Text size="xs" weight={700}>{value}</Text>
                  </Group>
                )
              }
              if (item.name === 'payload.alarm.value') {
                return item.payload.payload.alarm.codes.map(code => {
                  return <LabelList key={code} name={code} color={item.color} />
                })
              } else {
                return <LabelList key={item.name} name={item.name} color={item.color} />
              }
            })}
          </Stack>
        </Card>
      )
    }
  
    return null;
  }
  let alarmData = []
  if (title === 'Allarmi') {
    alarmData = data.map(status => {
      return {
        ...status,
        payload: {
          ...status.payload,
          alarm: {
            codes: status.payload.alarm,
            value: status.payload.alarm.length || 0
          }
        }
      }
    })
    console.log(alarmData)
  }

  const theme = useMantineTheme()

  return (
    <>
      <Title order={4} align="center" mb={10}>{title}</Title>
      <ResponsiveContainer width="100%" height={250}>
        <LineChart
          data={title === 'Allarmi' ? alarmData : data}
          >
          {values.map((value, i) => {
            const color = value.value === 'payload.co2MassDelta' ? '#999' : theme.colors.blue[9-i*2]
            return(
              <Line
                key={value.value}
                type="natural"
                dataKey={value.value}
                name={value.label}
                stroke={color}
                strokeWidth={value.value === 'payload.co2MassDelta' ? 1 : 3}
                activeDot={{stroke: color, fill: '#fff', strokeWidth: 3, r: 6}}
                yAxisId={value.value === 'payload.co2MassDelta' ? 'delta' : 0}
                />
          )})}
          <CartesianGrid stroke="#ddd" strokeDasharray="4 8" />
          <XAxis
            dataKey="xLabel"
            style={{fontSize: '.8em'}}
            />
          <YAxis
            yAxisId={0}
            dataKey={values.filter(e => e.value === 'payload.co2MassDelta').length === 0 ? undefined : 'payload.co2Mass'}
            width={30}
            style={{fontSize: '.8em'}}
            domain={[
              dataMin => {
                const space = isFinite(dataMin/10) && dataMin/10 >= 1 ? dataMin/10 : 1
                return dataMin < 0 ? Math.floor(dataMin - space) : 0
              },
              dataMax => {
                const space = isFinite(dataMax/10) && dataMax/10 >= 1 ? dataMax/10 : 1
                return Math.floor(dataMax + space)
              }
            ]}
            allowDecimals={false}
            />
          <YAxis
            hide={values.filter(e => e.value === 'payload.co2MassDelta').length === 0}
            yAxisId="delta"
            dataKey="payload.co2MassDelta"
            width={20}
            axisLine={{stroke: '#999'}}
            padding={{right: 5}}
            style={{fontSize: '.6em'}}
            domain={[
              dataMin => {
                const space = isFinite(dataMin/10) && dataMin/10 >= 1 ? dataMin/10 : 1
                return Math.floor(dataMin - space)
              },
              dataMax => {
                const space = isFinite(dataMax/10) && dataMax/10 >= 1 ? dataMax/10 : 1
                return Math.floor(dataMax + space)
              }
            ]}
            allowDecimals={false}
            />
          <Tooltip
            content={CustomTooltip}
            />
        </LineChart>
      </ResponsiveContainer>
    </>
  )
}

export default Chart

import { useEffect, useState } from 'react'

import { Title, Card, Group, Text, Center, Loader, Stack, Button, ScrollArea, ActionIcon, Box, Badge } from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import { useViewportSize, useInterval } from '@mantine/hooks'
import { ALARMS } from '../utils/func'
import axios from 'axios'
import dayjs from 'dayjs'
import it from 'dayjs/locale/it'
import { Check, Eye, EyeOff, Refresh } from 'tabler-icons-react'
import { Link } from 'react-router-dom'
import routes from '../utils/routes'
import { useMaibaContext } from '../utils/maibaContext'
var duration = require('dayjs/plugin/duration')
dayjs.extend(duration)

const AlarmsManager = () => {
  const { machines } = useMaibaContext()
  const { width } = useViewportSize()
  const [loading, setLoading] = useState({
    alarms: true,
    alarmsHidden: true,
  })

  const [start, setStart] = useState(dayjs().toDate())
  const [end, setEnd] = useState(dayjs().toDate())
  const [alarms, setAlarms] = useState([])
  const [nicknames, setNicknames] = useState({})

  const generateAlarmId = (alarm) => {
    return `${alarm.topic}_${alarm.payload}_${alarm.date.replace(/\//g, '_')}_${alarm.time.replace(/:/g, '_')}`
  }
  useEffect(() => {
    getAlarms()
  }, [])
  useEffect(() => {
    if (alarms.length === 0) return
    setLoading(state => ({
      ...state,
      alarmsHidden: true,
    }))
    Promise.all(alarms.map(async (alarm) => {
      await getNickname(alarm)
      await getHidden(alarm)
    }))
    .then(success => {
      setLoading(state => ({
        ...state,
        alarmsHidden: false
      }))
    })

  }, [loading.alarms])
  const getAlarms = () => {
    setLoading({
      alarms: true,
      alarmsHidden: true,
    })
    axios.get(`/api/alarms/all/${dayjs(start).format('YYYYMMDD')}/${dayjs(end).format('YYYYMMDD')}`)
      .then(res => {
        console.log('Alarms:',res.data)
        setAlarms(state => [
          ...res.data.map(alarm => {
            if (alarms.filter(a => a.id === generateAlarmId(alarm)).length > 0) return
            return {
              ...alarm,
              id: generateAlarmId(alarm),
              nickname: machines.filter(machine => machine.id === alarm.topic)[0]?.nickname || '',
              hidden: false,
              new: true
            }
          }).filter(a => a !== undefined),
          ...state
        ])
        setLoading(state => ({
          ...state,
          alarms: false
        }))
      })
  }
  const getNickname = async (alarm) => {
    if (alarm.nickname !== '') return
    if (nicknames[alarm.topic]) {
      setAlarms(states => states.map(state => ({
        ...state,
        nickname: state.topic === alarm.topic ? nicknames[alarm.topic] : state.nickname,
      })))
      return
    }
    const res = await axios.get(`/api/machine/${alarm.topic}/nickname`)
    setNicknames(state => ({
      ...state,
      [alarm.topic]: res.data
    }))
    setAlarms(states => states.map(state => ({
      ...state,
      nickname: state.topic === alarm.topic ? res.data : state.nickname,
    })))
  }
  const getHidden = async (alarm) => {
    if (!alarm.new) return

    const res = await axios.get(`/api/alarms/hidden/${encodeURIComponent(alarm.id)}`)
    // console.log('hide', alarm.id, res.data)
    setAlarms(states => states.map(state => ({
      ...state,
      hidden: state.id === alarm.id ? res.data : state.hidden,
      new: state.id === alarm.id ? false : state.new,
    })))
  }
  const hideAlarm = (alarm, status = !alarm.hidden) => {
    axios.post(`/api/alarms/hidden/${encodeURIComponent(alarm.id)}`, {hidden: status})
      .then(res => {
        if (!res.data) return
        setAlarms(states => states.map(state => ({
          ...state,
          hidden: state.id === alarm.id ? status : state.hidden
        })))
      })
  }

  useEffect(() => {
    if (dayjs(start).isAfter(dayjs(end))) {
      setEnd(dayjs(start).toDate())
    }
  }, [start])

  const [ALARMSA, SETALARMSA] = useState(Object.entries(ALARMS).map(ALARM => ({
    code: ALARM[0],
    def: ALARM[1],
    hideAll: false,
  })))

  const defaultTimeout = 600
  const [seconds, setSeconds] = useState(defaultTimeout)
  const interval = useInterval(() => setSeconds((s) => s - 1), 1000)

  useEffect(() => {
    if (dayjs(end).isSame(dayjs(), 'day')) {
      interval.start()
    } else {
      interval.stop()
      setSeconds(defaultTimeout)
    }
  }, [end])
  useEffect(() => {
    if (seconds !== 0) return
    setStart(dayjs().toDate())
    setEnd(dayjs().toDate())
    getAlarms()
    setSeconds(defaultTimeout)
  }, [seconds])

  return (
    <div key="alarmsManager">
      <Group p="lg" justify="space-between">
        <Title order={1}>Allarmi ({alarms.length})</Title>
        <Group noWrap align="flex-end" gap="sm">
          <DatePickerInput
            value={start}
            inputFormat="D MMMM YYYY"
            onChange={setStart}
            label="Dal"
            maxDate={new Date()}
            minDate={dayjs(end).subtract(7, 'days').toDate()}
            size="xs"
            clearable={false}
            locale={it}
            styles={{root: {display: 'flex', alignItems: 'baseline', gap: width < 768 ? 0 : 8, flexDirection: width < 768 ? 'column' : 'row' }}}
          />
          <DatePickerInput
            value={end}
            inputFormat="D MMMM YYYY"
            onChange={setEnd}
            label="Al"
            maw={400}
            maxDate={new Date()}
            minDate={start}
            size="xs"
            clearable={false}
            locale={it}
            styles={{root: {display: 'flex', alignItems: 'baseline', gap: width < 768 ? 0 : 8, flexDirection: width < 768 ? 'column' : 'row' }}}
          />
          <Button
            size="xs"
            variant="outline"
            loading={loading.alarmsHidden}
            onClick={() => getAlarms()}
            sx={{ width: width < 768 ? '15%' : 'auto'}}
            >Cerca</Button>
        </Group>
        <Group>
          <Stack gap={5}>
            <Text inline size="xs" align="right" c="dimmed" maw={400}>Aggiornamento automatico tra</Text>
            <Text inline size="xs" align="right">
              {dayjs(end).isSame(dayjs(), 'day') ? dayjs.duration(seconds, 'seconds').format('m:ss') : 'Imposta la data finale ad oggi'}</Text>
          </Stack>
          <ActionIcon variant="outline" color="blue" loading={loading.alarmsHidden} onClick={() => getAlarms()}>
            <Refresh size={16} />
          </ActionIcon>
        </Group>
      </Group>
      {loading.alarms && <Center py={30}><Loader /></Center>}
      {!loading.alarms &&
        ALARMSA.map((alarma, a) => 
          <Box key={alarma.code} p={0} m={0}>
            <Group position="apart" px="lg" pt="xl" pb="md">
              <Group spacing={10} position={width < 768 ? 'apart' : 'left'} sx={{flexGrow: width < 768 ? 1 : 0}}>
                <Badge radius="sm" color={alarma.code === 'Online' ? 'teal' : alarma.code === 'Offline' ? 'red' : 'yellow'}>{alarma.code}</Badge>
                <Text inline size="md" fw={700} sx={{order: width < 768 ? 3 : 'auto', width: width < 768 ? '100%' : 'auto'}}>{alarma.def}</Text>
                <Text inline size="sm" c="dimmed">(Visibili {alarms.filter(alarm => alarma.code === alarm.payload && alarm.hidden === false).length} su {alarms.filter(alarm => alarma.code === alarm.payload).length})</Text>
              </Group>
              <Group>
                <Button
                  size="xs"
                  variant="outline"
                  onClick={() => {
                    SETALARMSA(states => states.map(state => ({
                      ...state,
                      hideAll: state.code === alarma.code ? !alarma.hideAll : state.hideAll
                    })))
                  }}
                  disabled={alarms.filter(alarm => alarma.code === alarm.payload && alarm.hidden === true).length === 0 || alarms.filter(alarm => alarma.code === alarm.payload).length === 0}
                  >
                    {alarma.hideAll ? 'Non mostrare nascosti' : 'Mostra nascosti'}
                </Button>
                <Button
                  size="xs"
                  onClick={() => {
                    alarms.map((alarm, i) => {
                      if (alarma.code !== alarm.payload) return
                      hideAlarm(alarm, true)
                    })}}
                  disabled={alarms.filter(alarm => alarma.code === alarm.payload && alarm.hidden !== true).length === 0}
                  >
                    Nascondi tutti
                </Button>
              </Group>
            </Group>
              <ScrollArea
                py="md"
                maw="100%"
                bg="gray.0"
                styles={{root: {borderTop: '1px solid #eee', borderBottom: '1px solid #eee'}}}
                >
                <Group wrap="nowrap" px={20}>
                  {alarms.map((alarm, i) => {
                    if (alarma.code !== alarm.payload) return
                    if (alarm.hidden && !alarma.hideAll) return
                    return <Stack key={i} px="md" pt="md" pb="sm" styles={{ root: {border: '1px solid #eee', borderRadius: 4, width: 200, minHeight: 220, backgroundColor: '#fff', opacity: alarm.hidden ? .6 : 1}}}>
                      <Stack gap={5} align="flex-end">
                        <Text inline size="xs">{dayjs(alarm.date).format('DD/MM/YYYY')}</Text>
                        <Text inline size="sm" weight={700}>{alarm.time}</Text>
                      </Stack>
                      <Stack gap={5}>
                        <Text inline align="center" c="dimmed" size="sm">{alarm.topic}</Text>
                        <Text align="center" fw={700}>{alarm.nickname}</Text>
                        <Link to={`${routes.MACHINES}/${alarm.topic}`}>
                          <Text align="center" size="xs" c="blue">Vai al dispositivo</Text>
                        </Link>
                      </Stack>
                      <Button
                        mt="auto"
                        size="xs"
                        leftSection={alarm.hidden ? <Eye size={16} /> : <EyeOff size={16} />}
                        variant={alarm.hidden ? 'outline' : 'filled'}
                        onClick={() => hideAlarm(alarm)}
                        >
                        {alarm.hidden ? 'Mostra' : 'Nascondi'}
                      </Button>
                    </Stack>
                  })}              
                </Group>
              </ScrollArea>
          </Box>
        )
      }
    </div>
  )
}

export default AlarmsManager

import { useEffect, useState } from 'react'
import { Routes, Route, Link, useLocation } from 'react-router-dom'
import {
  AppShell,
  Burger,
  Image,
  Group,
  ActionIcon,
  ScrollArea,
  TextInput,
  Menu,
  Box,
  Accordion,
  ThemeIcon
} from '@mantine/core'
import { useHeadroom, useDisclosure, useMediaQuery } from '@mantine/hooks'

import { useAuthContext } from '../utils/authContext'

import { useMaibaContext } from '../utils/maibaContext'
import routes from '../utils/routes'
import logo from '../assets/logo-maiba-h.svg'
import { Ripple, Users, Logout, Plus, User, Search, Bell, Briefcase, DotsVertical, X } from 'tabler-icons-react'
import EmptyScreen from '../components/EmptyScreen'
import Machine from '../components/Machine'
import Client from '../components/Client'
import AlarmsManager from '../components/AlarmsManager'
import Policies from '../components/Policies'
import Loading from '../components/Loading'
import NavItemSmall from '../components/NavItemSmall'
import NavMachines from '../components/NavMachines'
import InterventionsManager from '../components/InterventionsManager'

const Admin = () => {
  const isMobile = useMediaQuery('(max-width: 48em)')
  const [opened, handlers] = useDisclosure()
  const headPinned = useHeadroom({ fixedAt: 120 })
  let location = useLocation()

  useEffect(() => {
    handlers.close()
  }, [location])

  return(
    <AppShell
      header={{ height: { md: 65, lg: 0 }, collapsed: { mobile: !headPinned, desktop: true} }}
      navbar={{ width: { md: 260, lg: 320, xl: 400}, breakpoint: 'md', collapsed: { mobile: !opened } }}
      >
      <AppShell.Header hiddenFrom="md">
        <Head opened={opened} toggle={() => handlers.toggle()} withNavToggler />
      </AppShell.Header>
      <AppShell.Navbar style={{ top: isMobile ? 65 : 0, height: isMobile ? 'calc(100dvh - 65px)' : '100dvh' }}>
        <Head opened={opened} toggle={() => handlers.toggle()} responsive={{visibleFrom: 'md'}} />
        <Nav />
      </AppShell.Navbar>
      <AppShell.Main mt={{base: '65px', md: 0}}>
        <Routes>
          <Route path={`${routes.MACHINES}/:sn/:section?/*`} element={<Machine />} />
          {process.env.REACT_APP_NAME === 'iot-maiba-backoffice' &&
            <Route path={`${routes.USERS}/:id`} element={<Client />} />
          }
          <Route path={routes.ALARMS} element={<AlarmsManager />} />
          <Route path={`${routes.INTERVENTIONS}/*`} element={<InterventionsManager />} />
          <Route path="*" element={<EmptyScreen />} />
        </Routes>
      </AppShell.Main>
    </AppShell>
  )
}

const Head = ({opened, toggle, responsive, withNavToggler}) => {
  const { credentials, logout } = useAuthContext()
  return(
    <Group justify="space-between" px="md" py="sm" {...responsive}>
      <Menu
        size="sm"
        withArrow>
        <Menu.Target>
          <ActionIcon variant="subtle">
            <DotsVertical size={14} />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Label tt="uppercase">{credentials.email}</Menu.Label>
          <Menu.Item
            color="red"
            leftSection={<Logout size={14} />}
            onClick={logout}
            size="sm"
            >
            Esci
          </Menu.Item>
          <Menu.Divider />
          <Menu.Label tt="uppercase">Informative</Menu.Label>
          <Policies isMenu />            
        </Menu.Dropdown>
      </Menu>
      <Link
        to={routes.DASHBOARD_A}>
        <Image
          src={logo}
          alt="Depuratori Maiba"
          height={40}
          styles={{root: {objectFit: 'contain'}}}
          />
      </Link>
      {!withNavToggler && <Box p="sm"></Box>}
      {withNavToggler &&
        <Burger
          opened={opened}
          onClick={toggle}
          size="xs"
          color="blue"
          />
      }
    </Group>
  )
}
const Nav = () => {
  const { machines, users } = useMaibaContext()
  const [ filter, setFilter ] = useState({})
  const { credentials } = useAuthContext()

  return(
    <Accordion defaultValue="machines" className="maiba-custom" styles={{
      content: {
        padding: 0
      },
    }}>
      <Accordion.Item key="machines" value="machines">
        <Accordion.Control icon={<ThemeIcon color="blue" variant="light" size="md"><Ripple size={16} /></ThemeIcon>}>Dispositivi ({machines.length})</Accordion.Control>
        <Accordion.Panel px={0} pt="xs" bg="gray.0">
          <TextInput
            px="md"
            placeholder="Cerca..."
            size="xs"
            leftSection={<Search size={12} />}
            rightSection={filter.machines && <ActionIcon variant="transparent" onClick={() => setFilter(state => ({...state, machines: ''}))}><X size={12} /></ActionIcon>}
            onChange={(event) => 
              setFilter(state => ({
                ...state,
                machines: event.currentTarget.value
              })
            )}
            value={filter.machines ? filter.machines : ''}
            />
          <ScrollArea scrollbarSize={6}>
            <NavMachines filter={filter.machines} />
          </ScrollArea>
        </Accordion.Panel>
      </Accordion.Item>
      <Link to={routes.INTERVENTIONS_A}>
        <Accordion.Item key="interventions" value="interventions">
          <Accordion.Control icon={<ThemeIcon color="blue" variant="light" size="md"><Briefcase size={16} /></ThemeIcon>} chevron={<Briefcase size={0} />}>Interventi</Accordion.Control>
        </Accordion.Item>
      </Link>
      <Link to={routes.ALARMS_A}>
        <Accordion.Item key="alarms" value="alarms">
          <Accordion.Control icon={<ThemeIcon color="blue" variant="light" size="md"><Bell size={16} /></ThemeIcon>} chevron={<Bell size={0} />}>Allarmi</Accordion.Control>
        </Accordion.Item>
      </Link>
      {credentials.isAdmin &&
        <Accordion.Item key="users" value="users">
          <Accordion.Control icon={<ThemeIcon color="blue" variant="light" size="md"><Users size={16} /></ThemeIcon>}>Utenti ({users.length})</Accordion.Control>
          <Accordion.Panel pt="xs" bg="gray.0">
            <TextInput
              px="md"
              placeholder="Cerca..."
              size="xs"
              leftSection={<Search size={12} />}
              rightSection={filter.users && <ActionIcon variant="transparent" onClick={() => setFilter(state => ({...state, users: ''}))}><X size={12} /></ActionIcon>}
              onChange={(event) => 
                setFilter(state => ({
                  ...state,
                  users: event.currentTarget.value
                })
              )}
              value={filter.users ? filter.users : ''}
              />
            <ScrollArea scrollbarSize={6}>
              {users.filter(e => {
                if (filter.users === undefined || filter.users === '') return true
                const re = new RegExp(filter.users, 'i')
                return re.test(e.nickname) || re.test(e.email)
              }).sort((a, b) => a.nickname.localeCompare(b.nickname)).map(user =>
                <NavItemSmall key={user.id}
                  icon={<User color="var(--mantine-color-blue-light-color)" size={14} />}
                  route={`${routes.USERS_A}/${user.id}`}
                  title={user.nickname}
                  subtitle={user.email} />
              )}
              <NavItemSmall key="new"
                icon={<Plus color="var(--mantine-color-blue-light-color)" size={14} />}
                route={`${routes.USERS_A}/new`}
                title="Aggiungi nuovo"
                subtitle=""
                />
            </ScrollArea>
          </Accordion.Panel>
        </Accordion.Item>
      }
    </Accordion>  
  )
}

export default Admin
import { useState, useEffect } from 'react'
import axios from 'axios'

import { useMachineContext } from '../../utils/machineContext'
import Loading from '../Loading'
import Section from './Section'
import dayjs from 'dayjs'
import { Badge } from '@mantine/core'
import Table from '../Table'

const Connections = () => {
  const { id } = useMachineContext()

  const [loading, setLoading] = useState(true)
  const [connections, setConnections] = useState([])

  useEffect(() => {
    axios.get(`/api/machine/${id}/connections`)
      .then(res => {
        console.log('Connections:',res.data)
        setConnections(res.data)
        setLoading(false)
      })
  }, [])

  return(
    <Section title="Connessioni">
      {loading && <Loading />}
      {!loading &&
        <Table
          data={connections}
          columns={[
            {
              accessor: 'date',
              title: 'Data',
              render: ({date}) =>  dayjs(date, 'YYYY/MM/DD').format('DD/MM/YYYY'),
              sortable: true,
            },
            {
              accessor: 'time',
              title: 'Ora',
            },
            {
              accessor: 'topic',
              title: 'Connessione',
              render: ({topic}) => <Badge color={topic === 'Online' ? 'teal' : 'red'} radius="sm">{topic}</Badge>
            }
          ]}
          />
      }
    </Section>
  )
}
export default Connections

import { useState } from "react"
import { ChevronDown, ChevronUp, Dots, GripVertical, Pencil, PlugConnected, PlugConnectedX, Plus, X } from "tabler-icons-react"
import { useMaibaContext } from "../utils/maibaContext"
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import { Button, Collapse, Group, Stack, Text, TextInput, ActionIcon, Box } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import NavItemSmall from "./NavItemSmall"
import routes from "../utils/routes"
import axios from "axios"
import { v4 as uuidv4 } from "uuid"
import { useNavigate } from "react-router-dom"
import { useAuthContext } from "../utils/authContext"

const NavMachines = ({ filter }) => {
  const { machines, setMachines, groups, setGroups } = useMaibaContext()
  const { credentials } = useAuthContext()
  const navigate = useNavigate()

  const [newGroup, setNewGroup] = useState({
    display: false,
    name: '',
  })

  return (
    <DragDropContext
      onDragEnd={({ destination, source }) => {
        console.log(groups[source.droppableId][source.index])
        axios.post(`/api/machine/${groups[source.droppableId][source.index].id}`, {
          group: destination.droppableId,
        })
        .then(() => {
          setMachines(state => state.map(s => ({
            ...s,
            group: s.id === groups[source.droppableId][source.index].id ? destination.droppableId : s.group
          })))
        })
        
      }}
      >
      {credentials.isAdmin &&
        <>
          <Button
            onClick={() => setNewGroup({
              ...newGroup,
              display: true
            })}
            styles={{
              inner: {
                justifyContent: 'flex-start'
              }
            }}
            variant="transparent"
            leftSection={<Plus size={14} />}
            px="md"
            tt="uppercase"
            c="dimmed"
            size="xs"
            fw="700"
            fullWidth
            disabled={newGroup.display}
            >
            Aggiungi gruppo
          </Button>
          {newGroup.display &&
            <Group px="md" py="xs" gap="xs" wrap="no-wrap">
              <ActionIcon size="md" color="red" variant="outline" style={{flexGrow: 0, flexShrink: 0}}
                onClick={() => {
                  setNewGroup(state => ({
                    name: '',
                    display: false
                  }))
                }}
                >
                <X size={12} />
              </ActionIcon>
              <TextInput
                placeholder="Nome del gruppo..."
                size="xs"
                style={{flexGrow: 1, flexShrink: 0}}
                leftSection={<Pencil size={12} />}
                onChange={event => {
                  setNewGroup(state => ({
                    ...state,
                    name: event.target.value.toUpperCase()
                  }))
                }}
                value={newGroup.name}
                />
              <Button size="xs"
                style={{flexGrow: 0, flexShrink: 1, flexBasis: 1}}
                onClick={() => {
                  if (groups[newGroup.name]) return

                  setGroups(state => ({
                    [newGroup.name]: [],
                    ...state,
                  }))
                  setNewGroup(state => ({
                    name: '',
                    display: false
                  }))
                }}
                >
                Salva
              </Button>
            </Group>
          }
          <Button
            onClick={() => {
              const newMachineId = `id-${uuidv4()}`
              setMachines(state => ([
                ...state,
                {
                id: newMachineId,
                nickname: `Nuovo dispositivo ${machines.filter(m => m.id.split('-')[0] === 'id').length + 1}`,
                group: undefined,
                line: false,
                new: true,
                users: [],
                address: undefined,
                }
              ]))
              navigate(`${routes.MACHINES_A}/${newMachineId}/info`)
            }}
            styles={{
              inner: {
                justifyContent: 'flex-start'
              }
            }}
            variant="transparent"
            leftSection={<Plus size={14} />}
            px="md"
            tt="uppercase"
            c="dimmed"
            size="xs"
            fw="700"
            fullWidth
            >
            Aggiungi dispositivo
          </Button>
        </>
      }
      {Object.keys(groups).map((key, index) => {
        return(
          <NavGroup key={key} groupName={key} groupMachines={groups[key]} filter={filter} />
        )
      })}
    </DragDropContext>
  )
}

const NavGroup = ({ groupName, groupMachines, filter }) => {
  const { credentials } = useAuthContext()
  const [ open, { toggle } ] = useDisclosure(true)
  return(
    <Droppable droppableId={groupName} direction="vertical" isDropDisabled={!credentials.isAdmin}>
      {(provided, snapshot) => 
      <Box {...provided.droppableProps} ref={provided.innerRef} mt="md" style={{
          backgroundColor: snapshot.isDraggingOver ? 'var(--mantine-color-blue-light-hover)' : 'transparent',
        }}>
        <GroupName name={groupName} machinesFiltered={groupMachines.filter(m => {
            if (filter === undefined || filter === '') return true
            const re = new RegExp(filter, 'i')
            return re.test(m.id) || re.test(m.nickname)
          }).length} machinesTotal={groupMachines.length} isOpen={open} toggleOpen={toggle} />
        <Collapse in={open}>
          {groupMachines.filter(m => {
            if (filter === undefined || filter === '') return true
            const re = new RegExp(filter, 'i')
            return re.test(m.id) || re.test(m.nickname)
          }).sort((a, b) => {
            if (!a.nickname || !b.nickname) return 1
            a.nickname.localeCompare(b.nickname)
          }).map((m, i) =>
            <Draggable key={m.id} index={i} draggableId={m.id} isDragDisabled={!credentials.isAdmin}>
              {provided =>
                <Group wrap="nowrap" preventGrowOverflow gap={0} ref={provided.innerRef} {...provided.draggableProps}>
                  <NavItemSmall
                    route={`${routes.MACHINES_A}/${m.id}`}
                    icon={!m.line ? <Dots color="var(--mantine-color-yellow-light-color)" size={14} /> : m.line.status ? <PlugConnected color="var(--mantine-color-green-light-color)" size={14} /> : <PlugConnectedX color="var(--mantine-color-red-light-color)" size={14} />}
                    title={m.nickname || m.id}
                    subtitle={m.nickname ? m.id : ''}
                    />
                  {credentials.isAdmin &&
                    <ActionIcon variant="subtle" color="gray" style={{borderRadius: 0}} {...provided.dragHandleProps}>
                      <GripVertical size={14} />
                    </ActionIcon>
                  }
                </Group>
              }
            </Draggable>
          )}
        </Collapse>
        {provided.placeholder}
      </Box>
      }
    </Droppable>
  )
}
const GroupName = ({ name, machinesFiltered, machinesTotal, isOpen, toggleOpen }) => {
  const numbers = machinesFiltered !== machinesTotal ? `${machinesFiltered}/${machinesTotal}` : `${machinesTotal}`
  return (
    <Button
      onClick={toggleOpen}
      styles={{
        inner: {
          justifyContent: 'flex-start'
        }
      }}
      variant="transparent"
      leftSection={isOpen ? <ChevronUp size={14} /> : <ChevronDown size={14} />}
      px="md"
      mt="md"
      tt="uppercase"
      c="dimmed"
      size="xs"
      fw="700"
      fullWidth
      >
      {name === 'undefined' ? 'Altri dispositivi' : name} ({numbers})
    </Button>
  )
}

export default NavMachines
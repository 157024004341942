
import { useForm } from '@mantine/form'
import { TextInput, NumberInput, Group, Button, Select, SimpleGrid, Stack, Chip, Text, Modal, Accordion, Table, Box, Title, MultiSelect, Textarea, Checkbox, SegmentedControl, Slider, Alert, Combobox, useCombobox, Pill, PillsInput, ActionIcon, InputBase } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { DateTimePicker, DatePickerInput } from '@mantine/dates'
import { notifications } from '@mantine/notifications'
import { AlertCircle, Check, CircleX, Trash } from 'tabler-icons-react'
import '@mantine/dates/styles.css'
import { useMaibaContext } from '../utils/maibaContext'
import Loading from './Loading'
import { useEffect, useState } from 'react'
import axios from 'axios'
import dayjs from 'dayjs'
import it from 'dayjs/locale/it'
import { useNavigate, useParams } from 'react-router-dom'
import routes from '../utils/routes'
import { interventionTypes, interventionStatusColor } from '../utils/func'
import InterventionImages from './InterventionImages'
import InterventionDelete from './InterventionDelete'
import InterventionLastReplace from './InterventionLastReplace'
import { DataTable } from 'mantine-datatable'

const InterventionEdit = ({ machineId }) => {
  const { machines, loading } = useMaibaContext()
  const [isLoading, setIsLoading] = useState({
    intervention: true,
    fields: true,
  })
  const [isSaving, setIsSaving] = useState(false)
  const [fields, setFields] = useState({
    ph_tools: [],
    sanification_products: [],
    parts: [],
    technicians: [],
  })
  const navigate = useNavigate()
  const { interventionId } = useParams()

  useEffect(() => {
    axios.get(`/api/interventions/options`)
      .then(res => {
        if (!res.data) return
        setFields({
          ph_tools: res.data.ph_tools || [],
          sanification_products: res.data.sanification_products || [],
          parts: res.data.parts || [],
          technicians: res.data.technicians || []
        })
        setIsLoading(state => ({
          ...state,
          fields: false
        }))
      })

    if (interventionId != 'new') {
      axios.get(`/api/interventions/${interventionId}`)
        .then(res => {
          console.log('intevention.res.data', res.data)
          if (!res.data) return
          form.initialize({
            ...form.values,
            ...res.data,
            email: {
              ...res.data.email,
              admin: false
            }
          })
          console.log(form.values)
          setIsLoading(state => ({
            ...state,
            intervention: false
          }))

        })
    } else {
      setIsLoading(state => ({
        ...state,
        intervention: false
      }))
    }
    
  }, [])

  const form = useForm({
    initialValues: {
      machineId: machineId || null,
      date: dayjs().toISOString(),
      external_id: null,
      deadline: null,
      status: 'In lavorazione',
      types: [],
      other_type: 'Personalizzato',
      tank_temp: null,
      co2_bar: null,
      co2_level: null,
      co2_bottles: {
        '01': {
          is_present: false,
          capacity: null,
          residue: null,
        },
        '02': {
          is_present: false,
          capacity: null,
          residue: null,
        },
        '03': {
          is_present: false,
          capacity: null,
          residue: null,
        },
        '04': {
          is_present: false,
          capacity: null,
          residue: null,
        },
      },
      sanification_product: null,
      sanification_time: null,
      ph_an: null,
      ph_ag: null,
      ph_tool: null,
      filter_type: null,
      filter_capacity: null,
      sampling_microbiological: false,
      sampling_chemical: false,
      free_crs: false,
      replace: {
        uv_filter_lamp: false,
        uv_filter_lamp_date: null,
        uv_filter_lamp_expiration: null,
        uv_filter_lamp_dispenser: false,
        uv_filter_lamp_dispenser_date: null,
        uv_filter_lamp_dispenser_expiration: null,
        beccuccio: false,
        beccuccio_date: null,
        beccuccio_expiration: null,
      },
      cont_gen_m3: null,
      cont_an_m3: null,
      cont_ag_m3: null,
      parts: [],
      technicians: [],
      technicians_exits: null,
      technicians_hours: null,
      other_works: null,
      notes: null,
      media: [],
      removed_media: [],
      request: false,
      email: {
        admin: false,
        client: false,
        min_level_co2: false,
        min_level_bottle_01: false,
        min_level_bottle_02: false,
        min_level_bottle_03: false,
        min_level_bottle_04: false,
        max_tank_temp: false,
        free_crs: false,
      }
    },
    transformValues: (values) => ({
      ...values,
      date: values.date || dayjs().toISOString(),
      other_type: values.other_type || 'Personalizzato',
      parts: values.parts.filter(part => part.value != '' && part.amount > 0)
    })
  })

  

  return(
    <>
      {loading.machines || isLoading.intervention || isLoading.fields && <Loading />}
      {!loading.machines && !isLoading.intervention && !isLoading.fields &&
        <form onSubmit={form.onSubmit((values) => {
          setIsSaving(true)
          axios.post(`/api/interventions/${interventionId}`, values)
            .then(res => {
              notifications.show({
                title: res.data[0] ? 'Salvataggio riuscito!' : 'Errore!',
                message:  res.data[0] ? 'L\'intervento è stato salvato correttamente.' : 'Si è verificato un problema, riprova più tardi.',
                color: res.data[0] ? 'green' : 'red',
                icon: res.data[0] ? <Check size={18} /> : <CircleX size={18} />
              })
              setIsSaving(false)
              if (!res.data) return
              if (interventionId === 'new') navigate(`${routes.MACHINES_A}/${form.values.machineId}/interventions/${res.data[1]}`)
            })
        })}>
          <Stack gap="xl">
            <SimpleGrid cols={{base: 1, sm: 3}}>
              <Select
                withAsterisk
                label="Dispositivo"
                size="xs"
                searchable
                filter={({options, search}) => options.filter(o => o.value.toLowerCase().includes(search.toLowerCase()) || o.label.toLowerCase().includes(search.toLowerCase()))}
                data={machines.map(m => ({value: m.id, label: m.nickname || m.id}))}
                {...form.getInputProps('machineId')}
                />
              <DateInput
                label="Data"
                name="date"
                form={form}
                withTime={true}
                />
              <TextInput
                label="Codice esterno"
                size="xs"
                {...form.getInputProps('external_id')}
                />
            </SimpleGrid>
            <Chip.Group multiple {...form.getInputProps('types')} onChange={values => {
              const removeFromArray = (array, value) => {
                if (!array.includes(value)) return array
                return array.splice(array.indexOf(value), 1)
              }
              const ordinaryChecked = values.includes('ordinary') && !form.values.types.includes('ordinary')
              const ordinaryUnchecked = !values.includes('ordinary') && form.values.types.includes('ordinary')
              const straordinaryChecked = values.includes('straordinary') && !form.values.types.includes('straordinary')
              const straordinaryUnchecked = !values.includes('straordinary') && form.values.types.includes('straordinary')
              const checkscleanChecked = values.includes('check&clean') && !form.values.types.includes('check&clean')
              const checkscleanUnchecked = !values.includes('check&clean') && form.values.types.includes('check&clean')

              if (ordinaryChecked || straordinaryChecked || checkscleanChecked || ordinaryUnchecked || straordinaryUnchecked || checkscleanUnchecked) values = values.filter(val => interventionTypes.filter(it => it.value === val)[0].category === 'main')
              if (ordinaryChecked || straordinaryChecked) {
                values = [...values, 'sanification_pipelines', 'sanification_filters', 'sanification_equipment', 'sanification_dispensers', 'cleaning_crs', 'cleaning_internal_system', 'cleaning_external_system', 'check_crs', 'check_sparkling', 'check_still', 'check_keys', 'check_uvc', 'check_refrigeration', 'check_heatingcooling', 'check_lights', 'check_traps']
                if (ordinaryChecked) removeFromArray(values, 'straordinary')
                if (straordinaryChecked) removeFromArray(values, 'ordinary')
                removeFromArray(values, 'check&clean')
              }
              if (checkscleanChecked) {
                values = [...values, ...interventionTypes.filter(types => types.category === 'checks' || types.category === 'cleaning').map(types => types.value)]
                removeFromArray(values, 'ordinary')
                removeFromArray(values, 'straordinary')
              }
              form.setFieldValue('types', values)
            }}>
              <SimpleGrid cols={{base: 1, sm: 2, md: 2, lg: 3}}>
                <Stack gap="sm">
                  <SegmentedControl
                    size="xs"
                    fullWidth
                    data={['Presa in carico', 'In lavorazione', 'Concluso']}
                    color={interventionStatusColor[form.values.status]}  
                    {...form.getInputProps('status')}
                    />
                  <DateInput
                    label="Termine lavori entro"
                    name="deadline"
                    disabled={form.values.status === 'Concluso'}
                    form={form}
                    />
                  <Text fw={700} size="xs" px="md" pt="xs">Tipologia</Text>
                  {interventionTypes.filter(t => t.category === 'main').map(t =>
                    <Chip key={t.value} value={t.value} size="xs" variant="light" color={t.value === 'urgent' ? 'red' : 'blue'}>{t.label}</Chip>
                  )}
                  {form.values.types.includes('other_type') &&
                    <TextInput
                      label="Altra tipologia"
                      size="xs"
                      {...form.getInputProps('other_type')}
                      />
                  }
                </Stack>
                <Stack>
                  <Stack gap="xs" p="xs" styles={{root: {borderRadius: 'var(--mantine-radius-default)', border: '1px solid var(--mantine-color-gray-3)', flexGrow: 1}}}>
                    <Group gap={0}>
                      <Text fw={700} size="xs" px="md">Sanificazioni</Text>
                      <Button size="xs" variant="transparent" px={0} onClick={() => form.setFieldValue('types', form.values.types.filter(t => interventionTypes.filter(it => it.value === t)[0].category !== 'sanification')) }>Rimuovi tutto</Button>
                    </Group>
                    {interventionTypes.filter(t => t.category === 'sanification').map(t => <Chip key={t.value} value={t.value} size="xs" variant="light">{t.label}</Chip>)}
                  </Stack>
                  <Stack gap="xs" p="xs" styles={{root: {borderRadius: 'var(--mantine-radius-default)', border: '1px solid var(--mantine-color-gray-3)', flexGrow: 1}}}>
                    <Group gap={0}>
                      <Text fw={700} size="xs" px="md">Pulizie</Text>
                      <Button size="xs" variant="transparent" px={0} onClick={() => form.setFieldValue('types', form.values.types.filter(t => interventionTypes.filter(it => it.value === t)[0].category !== 'cleaning')) }>Rimuovi tutto</Button>
                    </Group>
                    {interventionTypes.filter(t => t.category === 'cleaning').map(t => <Chip key={t.value} value={t.value} size="xs" variant="light">{t.label}</Chip>)}
                  </Stack>
                </Stack>
                <Stack gap="xs" p="xs" styles={{root: {borderRadius: 'var(--mantine-radius-default)', border: '1px solid var(--mantine-color-gray-3)'}}}>
                  <Group gap={0}>
                    <Text fw={700} size="xs" px="md">Verifiche</Text>
                    <Button size="xs" variant="transparent" px={0} onClick={() => form.setFieldValue('types', form.values.types.filter(t => interventionTypes.filter(it => it.value === t)[0].category !== 'checks')) }>Rimuovi tutto</Button>
                  </Group>
                  {interventionTypes.filter(t => t.category === 'checks').map(t => <Chip key={t.value} value={t.value} size="xs" variant="light">{t.label}</Chip>)}
                </Stack>
              </SimpleGrid>
            </Chip.Group>
            <Stack>
              <SimpleGrid cols={{base: 1, sm: 3}}>
                <NumberInput
                  label="Temperatura vasca"
                  size="xs"
                  decimalScale={1}
                  decimalSeparator=","
                  step={0.1}
                  suffix="°"
                  c={form.values.tank_temp > (form.values.machineId && machines.filter(m => m.id === form.values.machineId)[0].settings && machines.filter(m => m.id === form.values.machineId)[0].settings.max_tank_temp ? machines.filter(m => m.id === form.values.machineId)[0].settings.max_tank_temp : 10) ? 'red' : 'black'}
                  {...form.getInputProps('tank_temp')}
                  />
                <NumberInput
                  label="Pressione CO2"
                  size="xs"
                  decimalScale={2}
                  step={0.01}
                  decimalSeparator=","
                  suffix=" bar"
                  {...form.getInputProps('co2_bar')}
                  />
                <TheSlider
                  label="Livello CO2"
                  alarmMin={form.values.machineId && machines.filter(m => m.id === form.values.machineId)[0].settings && machines.filter(m => m.id === form.values.machineId)[0].settings.min_level_co2 ? machines.filter(m => m.id === form.values.machineId)[0].settings.min_level_co2 : 20}
                  inputProps={form.getInputProps('co2_level')}
                  />
              </SimpleGrid>
              <SimpleGrid cols={{base: 1, xs: 2, sm: 4}}>
                {['01', '02', '03', '04'].map(n => 
                  <Stack key={n} p="xs" pb="md" gap="xs" styles={{root: {border: '1px solid var(--mantine-color-gray-3)', borderRadius: 'var(--mantine-radius-sm)'}}}>
                    <Checkbox
                      label={`Bombola ${n} presente`}
                      size="xs"
                      {...form.getInputProps(`co2_bottles.${n}.is_present`, { type: 'checkbox' })}
                      onChange={event => {
                        form.setFieldValue(`co2_bottles.${n}.is_present`, event.currentTarget.checked)
                        if (!event.currentTarget.checked) {
                          form.setFieldValue(`co2_bottles.${n}.capacity`, null)
                          form.setFieldValue(`co2_bottles.${n}.residue`, null)
                        }
                      }}
                      />
                    <Select
                      label={`Capienza bombola ${n}`}
                      size="xs"
                      data={['2kg', '4kg', '5kg', '10kg', '20kg', '30kg']}
                      disabled={!form.values.co2_bottles[n].is_present}
                      {...form.getInputProps(`co2_bottles.${n}.capacity`)}
                      />
                    <TheSlider
                      label={`Residuo bombola ${n}`}
                      disabled={!form.values.co2_bottles[n].is_present}
                      alarmMin={form.values.machineId && machines.filter(m => m.id === form.values.machineId)[0].settings && machines.filter(m => m.id === form.values.machineId)[0].settings.min_level_bottle ? machines.filter(m => m.id === form.values.machineId)[0].settings.min_level_bottle : 20}
                      inputProps={form.getInputProps(`co2_bottles.${n}.residue`)}
                      />
                  </Stack>  
                )}
              </SimpleGrid>
            </Stack>
            <SimpleGrid cols={{base: 1, sm: 3}}>
              <Select
                label="Prodotto sanificante"
                size="xs"
                clearable
                searchable
                filter={({options, search}) => options.filter(o => o.searchable.toLowerCase().includes(search.toLowerCase()))}
                data={fields.sanification_products.map(product => ({
                  value: product.id,
                  label: product.id,
                  searchable: `${product.id} ${product.time}`
                }))}
                nothingFoundMessage="Nessun risultato..."
                
                {...form.getInputProps('sanification_product')}
                onChange={value => {
                  form.setFieldValue('sanification_product', value)
                  form.setFieldValue('sanification_time', fields.sanification_products.filter(sp => sp.id === value)[0].time)
                }}
                />
              <TextInput
                label="Tempo di contatto e % sol."
                size="xs"
                {...form.getInputProps('sanification_time')}
                />
            </SimpleGrid>
            <SimpleGrid cols={{base: 1, sm: 3}}>
              <TextInput
                label={`Tipologia filtro installato`}
                size="xs"
                {...form.getInputProps('filter_type')}
                />
              <TextInput
                label={`Capacità`}
                size="xs"
                {...form.getInputProps('filter_capacity')}
                />
            </SimpleGrid>
            <SimpleGrid cols={{base: 1, sm: 3}}>
              <Stack gap="xs">
                <Select
                  label="Strumento tar. PH"
                  size="xs"
                  searchable
                  filter={({options, search}) => options.filter(o => o.value.toLowerCase().includes(search.toLowerCase()) || o.label.toLowerCase().includes(search.toLowerCase()))}
                  data={fields.ph_tools.map(tool => ({value: `${tool.model} ${tool.id} - Tarato il ${tool.last_fix}`, label: `${tool.model} ${tool.id} - Tarato il ${tool.last_fix}`}))}
                  {...form.getInputProps('ph_tool')}
                  />
                <NumberInput
                  label={<>Cont. gen. m<sup>3</sup></>}
                  size="xs"
                  decimalScale={2}
                  step={0.01}
                  decimalSeparator=","
                  {...form.getInputProps('cont_gen_m3')}
                  />
              </Stack>
              <Stack gap="xs">
                <NumberInput
                  label="Valore PH AN (da 6,5 a 9,5)"
                  size="xs"
                  decimalScale={2}
                  step={0.01}
                  decimalSeparator=","
                  min={6.50}
                  max={9.50}
                  {...form.getInputProps('ph_an')}
                  />
                <NumberInput
                  label={<>Cont. AN m<sup>3</sup></>}
                  size="xs"
                  decimalScale={2}
                  step={0.01}
                  decimalSeparator=","
                  {...form.getInputProps('cont_an_m3')}
                  />
              </Stack>
              <Stack gap="xs">
                <NumberInput
                  label="Valore PH AG (da 4,5 a 9,5)"
                  size="xs"
                  decimalScale={2}
                  step={0.01}
                  decimalSeparator=","
                  min={4.50}
                  max={9.50}
                  {...form.getInputProps('ph_ag')}
                  />
                <NumberInput
                  label={<>Cont. AG m<sup>3</sup></>}
                  size="xs"
                  decimalScale={2}
                  step={0.01}
                  decimalSeparator=","
                  {...form.getInputProps('cont_ag_m3')}
                  />
              </Stack>
            </SimpleGrid>
            <SimpleGrid cols={{base: 1, sm: 3}}>
              <Checkbox
                label="Campionamento microbiologico"
                size="xs"
                {...form.getInputProps('sampling_microbiological', { type: 'checkbox' })}
                />
              <Checkbox
                label="Campionamento chimico"
                size="xs"
                {...form.getInputProps('sampling_chemical', { type: 'checkbox' })}
                />
              <Checkbox
                label="CRS in libera"
                size="xs"
                {...form.getInputProps('free_crs', { type: 'checkbox' })}
                />
            </SimpleGrid>
            <SimpleGrid cols={{base: 1, sm: 3}}>
              {[
                {
                  label_checkbox: 'Sostituzione lamp. filtro UV',
                  key: 'uv_filter_lamp'
                },
                {
                  label_checkbox: 'Sostituzione lamp. filtro UV erogatori',
                  key: 'uv_filter_lamp_dispenser'
                },
                {
                  label_checkbox: 'Inst.ne punto di campionamento ingresso',
                  key: 'beccuccio'
                }
              ].map(fi => 
                <Stack gap={0} key={fi.key} p="xs" styles={{root: {border: '1px solid var(--mantine-color-gray-3)', borderRadius: 'var(--mantine-radius-sm)'}}}>
                  <Checkbox
                    label={fi.label_checkbox}
                    size="xs"
                    {...form.getInputProps(`replace.${fi.key}`, { type: 'checkbox' })}
                    onChange={event => {
                      form.setFieldValue(`replace.${fi.key}`, event.currentTarget.checked)
                      if (event.currentTarget.checked) {
                        form.setFieldValue(`replace.${fi.key}_date`, dayjs().toISOString())
                        form.setFieldValue(`replace.${fi.key}_expiration`, dayjs().add(machines.filter(m => m.id === form.values.machineId)[0].settings[`expiration_${fi.key}`] || 11, 'month').toISOString())
                      } else {
                        form.setFieldValue(`replace.${fi.key}_date`, null)
                        form.setFieldValue(`replace.${fi.key}_expiration`, null)
                      }
                    }}
                    />
                  <DateInput
                    label="Data sostituzione"
                    name={`replace.${fi.key}_date`}
                    form={form}
                    onChange={value => {
                      form.setFieldValue(`replace.${fi.key}_expiration`, dayjs(value).add(machines.filter(m => m.id === form.values.machineId)[0].settings[`expiration_${fi.key}`] || 11, 'month').toISOString())
                    }}
                    />
                  <DateInput
                    label="Data scadenza"
                    form={form}
                    name={`replace.${fi.key}_expiration`}
                    />
                  <InterventionLastReplace what={fi.key} interventionId={interventionId} machineId={form.values.machineId} />
                </Stack>
              )}
            </SimpleGrid>
            <SimpleGrid cols={{base: 1, xs: 2}}>
              <PartsTable
                label="Ricambi utilizzati"
                what="parts"
                form={form}
                options={form.values.parts ? [
                  ...fields.parts.map(part => ({
                    value: `${part.id}`,
                    label: <Stack gap={0} py="xs" className="ricambio"><Text size="xs" fw={700}>{part.id}</Text><Text size="xs">{part.name}</Text></Stack>,
                    searchable: `${part.id} ${part.name}`
                  })),
                  ...form.values.parts
                    .filter(v => fields.parts.filter(p => typeof v === 'string' ? p.id === v : p.id === v.value).length === 0)
                    .map(v => ({
                      value: typeof v === 'string' ? v : v.value,
                      label: <Stack gap={0} py="xs" className="ricambio"><Text size="xs" fw={700}>{typeof v === 'string' ? v : v.value}</Text></Stack>,
                      searchable: typeof v === 'string' ? v : v.value
                    }))
                ]
                :
                fields.parts.map(part => ({
                  value: `${part.id}`,
                  label: <Stack gap={0} py="xs" className="ricambio"><Text size="xs" fw={700}>{part.id}</Text><Text size="xs">{part.name}</Text></Stack>,
                  searchable: `${part.id} ${part.name}`
                }))}
                />
              <Box>
                <MultiSelect
                  label="Tecnici"
                  size="xs"
                  clearable
                  searchable
                  filter={({options, search}) => options.filter(o => o.value.toLowerCase().includes(search.toLowerCase()) || o.label.toLowerCase().includes(search.toLowerCase()))}
                  data={fields.technicians.map(technician => ({value: technician.id, label: technician.id}))}
                  nothingFoundMessage="Nessun risultato..."
                  {...form.getInputProps('technicians')}
                  />
                <SimpleGrid cols={2}>
                  <NumberInput
                    label="Uscite"
                    size="xs"
                    {...form.getInputProps('technicians_exits')}
                    />
                  <NumberInput
                    label="Ore"
                    size="xs"
                    {...form.getInputProps('technicians_hours')}
                    />
                </SimpleGrid>
              </Box>
            </SimpleGrid>
            <SimpleGrid cols={{base: 1, xs: 2}}>
              <Box>
                <Textarea
                  label={form.values.request ? `Richiesta del ${dayjs(form.values.request.date).locale(it).format('D MMMM YYYY hh:mm')} da ${form.values.request.email}` : 'Nessuna richiesta dal cliente'}
                  size="xs"
                  autosize
                  minRows={2}
                  disabled={true}
                  value={form.values.request ? form.values.request.message : ''}
                  />
                <InterventionImages images={form.values.request.media} isContact />
              </Box>
              <Textarea
                label="Altri lavori eseguiti"
                size="xs"
                autosize
                minRows={2}
                {...form.getInputProps('other_works')}
                />
            </SimpleGrid>
            <Textarea
              label="Note"
              size="xs"
              autosize
              minRows={2}
              {...form.getInputProps('notes')}
              />
            <InterventionImages form={form} />
            <Alert variant="light" color="blue" icon={<AlertCircle />} title="Invio email di notifica" mx="auto" pr="lg">
              <Text size="xs" mb="sm">Al salvataggio dell'intervento è possibile inviare un'email al cliente secondo le impostazioni seguenti:</Text>
              <Stack mb="lg" gap={5}>
                <EmailCheck
                  label="Notifica il cliente di questo intervento"
                  input="email.client"
                  refValue={1}
                  limit={2}
                  form={form}
                  />
                {[
                  {
                    label:'Notifica il cliente del livello CO2 basso',
                    input: 'email.min_level_co2',
                    refValue: form.values.co2_level,
                    limit: form.values.machineId && machines.filter(m => m.id === form.values.machineId)[0].settings && machines.filter(m => m.id === form.values.machineId)[0].settings.min_level_co2 ? machines.filter(m => m.id === form.values.machineId)[0].settings.min_level_co2 : 20,
                  },
                  {
                    label:'Notifica il cliente del livello bombola 01 basso',
                    input: 'email.min_level_bottle_01',
                    refValue: form.values.co2_bottles ? form.values.co2_bottles['01'].residue : false,
                    limit: form.values.machineId && machines.filter(m => m.id === form.values.machineId)[0].settings && machines.filter(m => m.id === form.values.machineId)[0].settings.min_level_bottle ? machines.filter(m => m.id === form.values.machineId)[0].settings.min_level_bottle : 20,
                  },
                  {
                    label:'Notifica il cliente del livello bombola 02 basso',
                    input: 'email.min_level_bottle_02',
                    refValue: form.values.co2_bottles ? form.values.co2_bottles['02'].residue : false,
                    limit: form.values.machineId && machines.filter(m => m.id === form.values.machineId)[0].settings && machines.filter(m => m.id === form.values.machineId)[0].settings.min_level_bottle ? machines.filter(m => m.id === form.values.machineId)[0].settings.min_level_bottle : 20,
                  },
                  {
                    label:'Notifica il cliente del livello bombola 03 basso',
                    input: 'email.min_level_bottle_03',
                    refValue: form.values.co2_bottles ? form.values.co2_bottles['03'].residue : false,
                    limit: form.values.machineId && machines.filter(m => m.id === form.values.machineId)[0].settings && machines.filter(m => m.id === form.values.machineId)[0].settings.min_level_bottle ? machines.filter(m => m.id === form.values.machineId)[0].settings.min_level_bottle : 20,
                  },
                  {
                    label:'Notifica il cliente del livello bombola 04 basso',
                    input: 'email.min_level_bottle_04',
                    refValue: form.values.co2_bottles ? form.values.co2_bottles['04'].residue : false,
                    limit: form.values.machineId && machines.filter(m => m.id === form.values.machineId)[0].settings && machines.filter(m => m.id === form.values.machineId)[0].settings.min_level_bottle ? machines.filter(m => m.id === form.values.machineId)[0].settings.min_level_bottle : 20,
                  },
                  {
                    label:'Notifica il cliente della temperatura vasca alta',
                    input: 'email.max_tank_temp',
                    refValue: form.values.tank_temp,
                    limit: form.values.machineId && machines.filter(m => m.id === form.values.machineId)[0].settings && machines.filter(m => m.id === form.values.machineId)[0].settings.max_tank_temp ? machines.filter(m => m.id === form.values.machineId)[0].settings.max_tank_temp : 15,
                    isMinor: false,
                  },
                  {
                    label: 'Notifica il cliente CRS in libera',
                    input: 'email.free_crs',
                    refValue: form.values.free_crs,
                    limit: .5,
                    isMinor: false
                  }
                ].map(ef => 
                  <EmailCheck
                    key={ef.input}
                    label={ef.label}
                    input={ef.input}
                    refValue={ef.refValue}
                    limit={ef.limit}
                    isMinor={ef.isMinor}
                    form={form}
                    />
                )}
              </Stack>
            </Alert>
            <Group justify="space-between" py="xl">
              <InterventionDelete interventionId={interventionId} machineId={form.values.machineId} />
              <InterventionsOptions fields={fields} setFields={setFields} />
              <Button type="submit" loading={isSaving}>Salva</Button>
            </Group>
          </Stack>
        </form>
      }
    </>
  )
}

export default InterventionEdit

const InterventionsOptions = ({fields, setFields}) => {
  const [opened, { open, close }] = useDisclosure(false)
  const [fieldsMod, setFieldsMod] = useState(fields)
  const [isSaving, setIsSaving] = useState(false)
  const fieldsDefs = [
    {
      key: 'ph_tools',
      title: 'Strumenti taratura PH',
      columns: [
        {
          accessorKey: 'model',
          header: 'Modello',
          placeholder: 'Nome modello...'
        },
        {
          accessorKey: 'id',
          header: 'Matricola',
          placeholder: 'HAxxxxxxxx'
        },
        {
          accessorKey: 'last_fix',
          header: 'Ultima taratura',
          placeholder: 'AAAA-MM-GG'
        }
      ]
    },
    {
      key: 'sanification_products',
      title: 'Prodotti sanificanti',
      columns: [
        {
          accessorKey: 'id',
          header: 'Prodotto',
          placeholder: 'Nome prodotto...'
        },
        {
          accessorKey: 'time',
          header: 'Tempo di contatto e % sol.',
          placeholder: 'Tempo di contatto e % sol.'
        },
      ]
    },
    {
      key: 'parts',
      title: 'Ricambi',
      columns: [
        {
          accessorKey: 'id',
          header: 'Codice',
          placeholder: 'Inserire un codice univoco...'
        },
        {
          accessorKey: 'name',
          header: 'Descrizione',
          placeholder: 'Inserire una breve descrizione...'
        },
      ]
    },
    {
      key: 'technicians',
      title: 'Tecnici',
      columns: [
        {
          accessorKey: 'id',
          header: 'Nome e cognome',
          placeholder: 'Inserisci il nome...'
        }
      ]
    }
  ]
  
  useEffect(() => {
    setFieldsMod(fields)
  }, [fields])
  useEffect(() => {
    fieldsDefs.map(def => {
//      console.log(def.key, fieldsMod[def.key])
      const columnsKeys = def.columns.map(c => c.accessorKey)
      const [lastValue] = fieldsMod[def.key].slice(-1)
//      console.log('last', lastValue)
      const isAdding = lastValue !== undefined ? Object.values(lastValue).filter(v => v !== '').length > 0 : true
//      console.log('addrow?', isAdding)
      if (isAdding) {
        setFieldsMod(state => ({
          ...state,
          [def.key]: [
            ...state[def.key],
            columnsKeys.reduce((obj, current) => ({ ...obj, [current]: '' }), {})
          ]
        }))
      }
    })
  }, [fieldsMod])

  const handleSave = () => {
    setIsSaving(true)
    let theFields = fieldsMod
    fieldsDefs.map(def => {
      theFields = {
        ...theFields,
        [def.key]: theFields[def.key].filter(row => Object.values(row).filter(v => v === '').length === 0)
      }
    })
//    console.log(fieldsMod, theFields)

    axios.post(`/api/interventions/options`, theFields)
      .then(res => {
        notifications.show({
          title: res.data ? 'Salvataggio riuscito!' : 'Errore!',
          message:  res.data ? 'I nuovi dati sono stati salvati correttamente.' : 'Si è verificato un problema, riprova più tardi.',
          color: res.data ? 'green' : 'red',
          icon: res.data ? <Check size={18} /> : <CircleX size={18} />
        })
        setIsSaving(false)
        if (!res.data) return
        setFields(theFields)
      })
    
  }

  return(
    <>
      <Modal opened={opened} onClose={close} centered size="xl" styles={{body: {padding: 0}}}>
        <Accordion>
          {fieldsDefs.map(d => 
            <Accordion.Item key={d.key} value={d.key}>
              <Accordion.Control><Title order={3}>{d.title}</Title></Accordion.Control>
              <Accordion.Panel>
                <Table horizontalSpacing="sm" striped>
                  <Table.Thead>
                    <Table.Tr>
                      {d.columns.map(c => <Table.Th key={c.accessorKey}>{c.header}</Table.Th>)}
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>
                    {fieldsMod[d.key].length > 0 && fieldsMod[d.key].map((row, rowIndex) => 
                      <Table.Tr key={rowIndex}>
                        {d.columns.map(column => 
                          <Table.Td key={column.accessorKey}>
                            <TextInput
                              size="xs"
                              variant="unstyled"
                              placeholder={column.placeholder}
                              value={fieldsMod[d.key][rowIndex][column.accessorKey]}
                              onChange={(e) => {
                                setFieldsMod(state => ({
                                  ...state,
                                  [d.key]: state[d.key].map((stateRow, stateRowIndex) => ({
                                    ...state[d.key][stateRowIndex],
                                    [column.accessorKey]: stateRowIndex === rowIndex ? e.target.value : stateRow[column.accessorKey]
                                  })),
                                }))
                              }}
                              disabled={isSaving}
                              />
                          </Table.Td>
                        )}
                      </Table.Tr>
                    )}
                  </Table.Tbody>
                </Table>
              </Accordion.Panel>
            </Accordion.Item>
          )}
        </Accordion>
        <Box px="lg" py="md">
          <Button
            fullWidth
            onClick={() => handleSave()}
            loading={isSaving}
            >
            Salva
          </Button>
          <Text mt="sm" ta="center" size="xs" c="dimmed">Salvando si elimineranno automaticamente le righe che contengono celle vuote.</Text>
        </Box>
      </Modal>

      <Button variant="outline" onClick={open}>Opzioni generali</Button>
    </>
  )
}

const TheSlider = ({ label, alarmMin, inputProps, disabled = false }) => 
  <Stack>
    <Group mt={7} mb={-10} gap={6}>
      <Text size="xs" fw={500}>{label}</Text>
      <Text size="xs" fw={500} c="dimmed">{inputProps.value ? `${inputProps.value}%` : ''}</Text>
    </Group>
    <Slider
      size="sm"
      color={inputProps.value < alarmMin ? 'red' : inputProps.value < 75 ? 'yellow' : 'teal'}
      label={v => `${v}%`}
      disabled={disabled}
      marks={[
        { value: alarmMin, label: `${alarmMin}%` },
        { value: 50, label: '50%' },
        { value: 75, label: '75%' }
      ]}
      mb="sm"
      styles={{markLabel: {fontSize: 11}}}
      {...inputProps}
      />
  </Stack>

const EmailCheck = ({ label, input, refValue, limit, isMinor = true, form }) => {
  const isTriggered = !refValue ? false : isMinor ? refValue < limit : refValue > limit

  useEffect(() => {
    if (input === 'email.client') return
    form.setFieldValue(input, isTriggered)
  }, [refValue])
  
  return (
  <>
    {isTriggered &&
      <Checkbox
        label={label}
        size="xs"
        disabled={!form.values.email.client && input !== 'email.client'}
        {...form.getInputProps(input, { type: 'checkbox' })}
        />
    }
  </>
)}

const DateInput = ({ label, name, form, withTime = false, disabled = false, onChange = false }) => {
  const value = name.split('.').reduce((theValue, element) => {
    //console.log(element, theValue)
    return theValue[element] !== undefined ? theValue[element] : theValue
  }, form.values)
  //console.log(name, value)
  const dateValue = dayjs(value).isValid() ? dayjs(value).toDate() : null
  //console.log(name, dateValue)

  return(
    withTime ?
      <DateTimePicker
        label={label}
        locale="it"
        valueFormat={withTime ? 'dddd D MMMM YYYY - HH:mm' : 'dddd D MMMM YYYY'}
        clearable={name != 'date'}
        numberOfColumns={2}
        size="xs"
        styles={{
          input: {
            textTransform: 'capitalize',
          }
        }}
        disabled={disabled}
        value={dateValue}
        onChange={val => {
          console.log('onchange', val)
          form.setFieldValue(name, val === null ? null : dayjs(val).toISOString())
          if (onChange) {
            onChange(val)
          }
        }}
        />
      :
      <DatePickerInput
        label={label}
        locale="it"
        valueFormat="dddd D MMMM YYYY"
        clearable={name != 'date'}
        numberOfColumns={2}
        size="xs"
        styles={{
          input: {
            textTransform: 'capitalize',
          }
        }}
        disabled={disabled}
        value={dateValue}
        onChange={val => {
          console.log('onchange', val)
          form.setFieldValue(name, val === null ? null : dayjs(val).toISOString())
          if (onChange) {
            onChange(val)
          }
        }}
        />
  )
}

const MultiSelectCreatable = ({what, label, form, initialData}) => {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
  });
  if (!form.values[what]) form.setFieldValue(what, [])
  
  const [search, setSearch] = useState('');
  const [data, setData] = useState(initialData)
  const [value, setValue] = useState(form.values[what] ? form.values[what] : [])

  const exactOptionMatch = data.some((item) => item === search);

  const handleValueSelect = (val) => {
    if (val === '$create') {
      const newData = {searchable: search, value: search, label: <Text fw={700} size="xs">{search}</Text>}
      setData((current) => [...current, newData])
      setValue((current) => [...current, search])
      form.insertListItem(what, search)
    } else {
      if (value.includes(val)) {
        handleValueRemove(val)
      } else {
        form.insertListItem(what, val)
        setValue(current => [...current, val])
      }
    }
    console.log(form.values)
    setSearch('')
  };

  const handleValueRemove = (val) => {
    form.removeListItem(what, value.indexOf(val))
    setValue(current => current.filter(v => v != val))
  }

  const values = value ? value.map(item => {
    return <Pill key={item} withRemoveButton onRemove={() => handleValueRemove(item)}>
      {item}
    </Pill>
  }
  )
  :
  []

  const options = data
    .filter(item => item.searchable.toLowerCase().includes(search.trim().toLowerCase()))
    .map((item) => (
      <Combobox.Option value={item.value} key={item.value} active={value.includes(item.value)}>
        <Group gap="sm" wrap="nowrap">
          {value.includes(item.value) ? <Check size={14} /> : null}
          {item.label}
        </Group>
      </Combobox.Option>
    ));

  return (
    <Combobox store={combobox} onOptionSubmit={handleValueSelect} withinPortal={false} styles={{options: {overflow: 'auto'}}}>
      <Combobox.DropdownTarget>
        <PillsInput onClick={() => combobox.openDropdown()} size="xs" label={label}>
          <Pill.Group>
            {values}

            <Combobox.EventsTarget>
              <PillsInput.Field
                onFocus={() => combobox.openDropdown()}
                onBlur={() => combobox.closeDropdown()}
                value={search}
                placeholder="Cerca..."
                onChange={(event) => {
                  combobox.updateSelectedOptionIndex();
                  setSearch(event.currentTarget.value);
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Backspace' && search.length === 0) {
                    event.preventDefault();
                    handleValueRemove(form.values[what][form.values[what].length - 1]);
                  }
                }}
              />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>
      </Combobox.DropdownTarget>

      <Combobox.Dropdown>
        <Combobox.Options mah={220}>
          {options}

          {!exactOptionMatch && search.trim().length > 0 && (
            <Combobox.Option value="$create"><Text size="xs">Aggiungi <span style={{fontWeight: 700}}>{search}</span>...</Text></Combobox.Option>
          )}

          {exactOptionMatch && search.trim().length > 0 && options.length === 0 && (
            <Combobox.Empty>Nessun risultato!</Combobox.Empty>
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}

const PartsTable = ({ options, label, form, what }) => {
  if (!form.values[what]) form.setFieldValue(what, [])
  return(
    <Stack gap="xs">
      <Table striped horizontalSpacing={5}>
        <Table.Thead>
          <Table.Tr>
            <Table.Td><Text size="xs" fw={500} inline>{label}</Text></Table.Td>
            <Table.Td><Text size="xs" fw={500} inline>Quantità</Text></Table.Td>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {form.values[what].map((row, i) => 
            <PartsTableRow key={i} i={i} form={form} what={what} options={options} />
          )}
        </Table.Tbody>
      </Table>
      <Button variant="light" size="xs" onClick={() => form.insertListItem(what, {value: '', amount: 1})}>Aggiungi</Button>
    </Stack>
  )
}
const PartsTableRow = ({ options, i, form, what }) => {

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
  });
  
  if (typeof form.values[what][i] === 'string') {
    form.setFieldValue(`${what}.${i}`, {value: form.values[what][i], amount: 1})
  }
  
  const [search, setSearch] = useState('');
  const [data, setData] = useState(options)

  const exactOptionMatch = data.some((item) => item.value === search);

  const handleValueSelect = (val) => {
    if (val === '$create') {
      const newData = {searchable: search, value: search, label: <Text fw={700} size="xs">{search}</Text>}
      setData((current) => [...current, newData])
      form.setFieldValue(`${what}.${i}.value`, search)
    } else {
      form.setFieldValue(`${what}.${i}.value`, val )
    }
    setSearch('')
    combobox.closeDropdown()
  }

  const the_options = data
    .filter(item => item.searchable.toLowerCase().includes(search.trim().toLowerCase()))
    .map((item) => (
      <Combobox.Option
        value={item.value}
        key={item.value}
        active={form.values[what][i].value === item.value}
        >
        <Group gap={5} wrap="nowrap">
          {form.values[what][i].value === item.value ? <Check size={14} /> : <div style={{width: 14, flexShrink: 0}} />}
          {item.label}
        </Group>
      </Combobox.Option>
    ));

  return (
    <Table.Tr>
      <Table.Td>
        <Combobox store={combobox} onOptionSubmit={handleValueSelect} withinPortal={false} withArrow styles={{ dropdown: { minWidth: 320 }, options: {overflow: 'auto'}}}>
          <Combobox.Target>
            <Button
              size="compact-xs"
              variant="transparent"
              rightSection={<Combobox.Chevron />}              
              onClick={() => combobox.openDropdown()}
              rightSectionPointerEvents="none"
              >
              {form.values[what][i].value || 'Seleziona ricambio'}
            </Button>
          </Combobox.Target>

          <Combobox.Dropdown>
            <Combobox.Search
              size="xs"
              value={search}
              onChange={(event) => setSearch(event.currentTarget.value)}
              placeholder="Cerca..."
              onBlur={() => {
                combobox.closeDropdown();
                setSearch('');
              }}
            />
            <Combobox.Options mah={220}>
              {the_options}

              {!exactOptionMatch && search.trim().length > 0 && (
                <Combobox.Option value="$create"><Text size="xs">Aggiungi <span style={{fontWeight: 700}}>{search}</span>...</Text></Combobox.Option>
              )}

              {exactOptionMatch && search.trim().length > 0 && options.length === 0 && (
                <Combobox.Empty>Nessun risultato!</Combobox.Empty>
              )}
            </Combobox.Options>
          </Combobox.Dropdown>
        </Combobox>
      </Table.Td>
      <Table.Td>
        <NumberInput
          size="xs"
          {...form.getInputProps(`${what}.${i}.amount`)}
          />
      </Table.Td>
      <Table.Td>
        <ActionIcon variant="light" size="md" onClick={() => form.removeListItem(what, i)}>
          <Trash size={16} />
        </ActionIcon>
      </Table.Td>
    </Table.Tr>
  )
}
import { Routes, Route, Link, useParams } from "react-router-dom"
import { Button, Group, Stack, Title, Badge } from "@mantine/core"
import { Plus, Briefcase } from "tabler-icons-react"

import InterventionEdit from "./InterventionEdit"
import InterventionList from "./InterventionList"

import routes from "../utils/routes"
import InterventionView from "./InterventionView"
import { useAuthContext } from "../utils/authContext"

const InterventionsManager = () => {
  const { credentials } = useAuthContext()
  return(
    <div key="alarmsManager">
      <Routes>
        <Route
          path="/:interventionId/:edit?"
          element={
            <InterventionsManagerHeader />
          }
          />
        <Route
          path="/"
          element={
            <Stack p="lg">
              <Group justify="space-between">
                <Title order={1}>Interventi</Title>
                {credentials.isAdmin || credentials.canIntervent &&
                  <Button leftSection={<Plus size={14} />} size="xs" variant="outline" component={Link} to={`${routes.INTERVENTIONS_A}/new`}>
                    Aggiungi nuovo
                  </Button>
                }
              </Group>
              <InterventionList />
            </Stack>
          }
          />
      </Routes>
    </div>
  )
}

const InterventionsManagerHeader = () => {
  const {interventionId, edit} = useParams()
  const { credentials } = useAuthContext()
  return(
    <Stack p="lg">
      <Group justify="space-between">
        <Title order={1}>
          <Group align="center">
            Intervento <Badge mt="xs" variant="light" size="xl">{interventionId === 'new' ? 'Nuovo' : interventionId}</Badge>
          </Group>
        </Title>
        <Button variant="outline" size="xs" leftSection={<Briefcase size={14} />} component={Link} to={routes.INTERVENTIONS_A}>
          Torna agli interventi
        </Button>
      </Group>
      {!edit ?
        <InterventionView />
        :
        <InterventionEdit />
      }
    </Stack>
  )
}

export default InterventionsManager
import { useState, useEffect } from 'react'
import { Badge, Text } from '@mantine/core'
import axios from 'axios'
import dayjs from 'dayjs'

import { useMachineContext } from '../../utils/machineContext'
import { getAlarmDefinition } from '../../utils/func'
import Loading from '../Loading'
import Section from './Section'
import Table from '../Table'

const Alarms = () => {
  const { id } = useMachineContext()

  const [loading, setLoading] = useState(true)
  const [alarms, setAlarms] = useState([])

  useEffect(() => {
    axios.get(`/api/machine/${id}/alarms`)
      .then(res => {
        console.log('Alarms:',res.data)
        setAlarms(res.data)
        setLoading(false)
      })
  }, [])

  return(
    <Section title="Allarmi">
      {loading && <Loading />}
      {!loading &&
        <Table
          data={alarms}
          columns={[
            {
              accessor: 'date',
              title: 'Data',
              render: ({date}) =>  dayjs(date, 'YYYY/MM/DD').format('DD/MM/YYYY'),
              sortable: true,
            },
            {
              accessor: 'time',
              title: 'Ora',
            },
            {
              accessor: 'topic',
              title: 'Allarme',
              render: (row) => <Badge color={row.payload.alarm ? 'yellow' : `${row.payload.conn ? 'teal' : 'red'}`} radius="sm">{row.payload.alarm ? `Allarme ${row.payload.alarm}: ${getAlarmDefinition(row.payload.alarm)}` : `${row.payload.conn ? `Connessione` : `Disconnessione`}`}</Badge>
            }
          ]}
          />
      }
    </Section>
  )
}
export default Alarms

import { useState, useEffect } from 'react'
import axios from 'axios'
import moment from 'moment'

import { useMachineContext } from '../../utils/machineContext'
import Loading from '../Loading'
import Section from './Section'
import UpdateBtn from './UpdateBtn'
import { NumberFormatter, SegmentedControl, Table, Text, Title } from '@mantine/core'
import dayjs from 'dayjs'
import DateSelector from './DateSelector'
import Chart from './Chart'
import Count from './Count'
import { COUNTS } from '../../utils/func'

const Counters = () => {
  const { id } = useMachineContext()

  moment.locale('it')
  const [ loading, setLoading ] = useState(true)
  const [ viewing, setViewing ] = useState('last')

  const [ day, setDay ] = useState(dayjs().format('YYYYMMDD'))

  const [ counters, setCounters ] = useState([])
  const [ lastCounters, setLastCounters ] = useState({
    date: false,
    time: false,
    payload: [
      {
          key: 'ambientVolTot',
          value: 0
      },
      {
          key: 'coldVolTot',
          value: 0
      },
      {
          key: 'gasVolTot',
          value: 0
      },
      {
          key: 'filterVol',
          value: 0
      },
      {
          key: 'ambientVol',
          value: 0
      },
      {
          key: 'coldVol',
          value: 0
      },
      {
          key: 'gasVol',
          value: 0
      }
  ]})
  const [ periodCounters, setPeriodCounters ] = useState({
    data: {},
    period: false
  })

  const getCounterDefinition = (code) => {
    if (code === 'ALL') return {
      machineTime: '[value] litri',
      ambientVolTot: '[value] litri',
      coldVolTot: '[value] litri',
      gasVolTot: '[value] litri',
      filterVol: '[value] litri',
      ambientVol: '[value] litri',
      ambientVol: '[value] litri',
      coldVol: '[value] litri',
      gasVol: '[value] litri',
    }

    return COUNTS[code] || {label: code, unit: false}
  }
  useEffect(() => {
    setLoading(true)
    axios.get(`/api/machine/${id}/counters`)
      .then(res => {
        console.log('lastCounters', res.data)
        if (res.data) {
          setLastCounters(res.data.counters)
          setPeriodCounters(res.data.period)
        }   

        setLoading(false)
      })
    getCounters()
  }, [])

  const getCounters = (when) => {
    if (day != when) {
      const theDay = when || day
      axios.get(`/api/machine/${id}/counters/${theDay}`)
        .then(res => {
          console.log('counters', res.data)
          setDay(theDay)
          setCounters(
            res.data.map(value => ({
              ...value,
              datetime: `${value.date} ${value.time}`,
              xLabel: value.time.slice(0, -3),
            }))
          )
          

          setLoading(false)
        })
    }
  }

  // aggiungo last status a status
  useEffect(() => {
    if (lastCounters) {
      if (counters.length > 0) {
        if (lastCounters.date === counters[0].date && counters.filter(e => e.time === lastCounters.time).length === 0) {
          setCounters(state => [
            ...state,
            {
              ...lastCounters,
              datetime: `${lastCounters.date} ${lastCounters.time}`,
              xLabel: lastCounters.time.slice(0, -3),
            }
          ])
        } else if (lastCounters.date != counters[0].date) {
          getCounters(dayjs().format('YYYYMMDD'))
        }
      } else {
        if (lastCounters.date === dayjs().format('D/M/YYYY')) {
          setCounters(state => [
            {
              ...state,
              datetime: `${lastCounters.date} ${lastCounters.time}`,
              xLabel: lastCounters.time.slice(0, -3),
            }
          ])
        }
      }
    }
  }, [lastCounters])

  return(
    <Section title="Contatori">
      <SegmentedControl
        data={[
          { label: 'Ultima lettura', value: 'last' },
          { label: 'Storico', value: 'history' }
        ]}
        value={viewing}
        onChange={(value) => setViewing(value)}
        sx={{ alignSelf: 'flex-end' }}
        />
      {loading && <Loading />}
      {!loading && viewing === 'last' &&
        <Table horizontalSpacing="md" striped>
          <Table.Tbody>
            {lastCounters.payload.map(counter => {
              const count = getCounterDefinition(counter.key)
              return(
                <Table.Tr key={counter.key}>
                  <Table.Td><Text align="right">{count.label}:</Text></Table.Td>
                  <Table.Td>
                    <Title order={2} weight={700}>
                      <NumberFormatter thousandSeparator="." decimalSeparator="," decimalScale={2} value={counter.value} suffix={` ${count.unit}`} />
                    </Title>
                  </Table.Td>
                </Table.Tr>
              )
            })}
          </Table.Tbody>
        </Table>
      }
      {!loading && viewing === 'history' &&
        <>
          <DateSelector day={day} get={getCounters} />
          <Chart
            title="Contatori"
            data={counters}
            values={lastCounters.payload.map(counter => {
              const dict = getCounterDefinition(counter.key)
              return ({
                value: `payload.${counter.key}`,
                label: getCounterDefinition(counter.key).label
              })})}
            definition={getCounterDefinition('ALL')}
            />
          <Count />
        </>
      }
      {!loading &&
        <UpdateBtn date={lastCounters.date} time={lastCounters.time} options={{topic: 'Counters/Read', id}} set={setLastCounters} period={periodCounters} setPeriod={setPeriodCounters} />
      }
    </Section>
  )
}

export default Counters


import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'


import { MachineProvider } from '../utils/machineContext'
import Status from './machine/Status'
import Counters from './machine/Counters'
import Recipes from './machine/Recipes'
import Settings from './machine/Settings'
import Infos from './machine/Infos'
import Alarms from './machine/Alarms'
import Connections from './machine/Connections'
import Commands from './machine/Commands'
import Tosini from './machine/Tosini'
import { Card, Group, Tabs, Title, Text, Center, Loader, Stack, Badge } from '@mantine/core'
import { useViewportSize } from '@mantine/hooks'
import { Activity, Adjustments, Bell, Cpu, GlassFull, Id, InfoCircle, Keyboard, Math, Plug, Send, Tools, Users, Briefcase } from 'tabler-icons-react'
import { useMaibaContext } from '../utils/maibaContext'
import Clients from './machine/Clients'
import Contact from './machine/Contact'
import { useAuthContext } from '../utils/authContext'
import CRS from './machine/CRS'
import Firmware from './machine/Firmware'
import Loading from './Loading'
import Interventions from './machine/Interventions'
import routes from '../utils/routes'

const Machine = () => {
  const {loading, machines, setMachines} = useMaibaContext()
  const {credentials} = useAuthContext()
  let { sn, section } = useParams()
  const navigate = useNavigate()
  const { width } = useViewportSize()
  const [redirectCheck, setRedirectCheck] = useState(false)

  useEffect(() => {
    if (loading.machines) return
    if (machines.filter(e => e.id === sn).length === 0) {
      navigate(routes.DASHBOARD_A)
    } else {
      setRedirectCheck(true)
    }
  }, [loading.machines])

  const tabs = {
    info: {
      label: 'Informazioni',
      icon: <InfoCircle size={width > 768 ? 16 : 20} />,
      component: <Infos />,
    },
    status: {
      label: 'Status',
      icon: <Activity size={width > 768 ? 16 : 20} />,
      component: <Status />
    },
    counters: {
      label: 'Contatori',
      icon: <Math size={width > 768 ? 16 : 20} />,
      component: <Counters />,
    },
    recipes: {
      label: 'Ricette',
      icon: <GlassFull size={width > 768 ? 16 : 20} />,
      component: <Recipes />,
    },
    settings: {
      label: 'Impostazioni',
      icon: <Tools size={width > 768 ? 16 : 20} />,
      component: <Settings />,
    },
    commands: {
      label: 'Comandi',
      icon: <Adjustments size={width > 768 ? 16 : 20} />,
      component: <Commands />,
    },
    alarms: {
      label: 'Allarmi',
      icon: <Bell size={width > 768 ? 16 : 20} />,
      component: <Alarms />,
    },
    connections: {
      label: 'Connessioni',
      icon: <Plug size={width > 768 ? 16 : 20} />,
      component: <Connections />,
    },
    clients: {
      label: 'Clienti',
      icon: <Users size={width > 768 ? 16 : 20} />,
      component: <Clients />,
    },
    raspberry: {
      label: 'Raspberry',
      icon: <Keyboard size={width > 768 ? 16 : 20} />,
      component: <Tosini />,
    },
    crs: {
      label: 'CRS',
      icon: <Id size={width > 768 ? 16 : 20} />,
      component: <CRS />,
    },
    firmware: {
      label: 'Firmware',
      icon: <Cpu size={width > 768 ? 16 : 20} />,
      component: <Firmware />,
    },
    contact: {
      label: 'Richiedi intervento',
      icon: <Send size={width > 768 ? 16 : 20} />,
      component: <Contact />,
    },
    interventions: {
      label: 'Interventi',
      icon: <Briefcase size={width > 768 ? 16 : 20} />,
      component: <Interventions />
    }
  }

  let sections = credentials.isAdmin ?
      [ 'info', 'status', 'counters', 'recipes', 'settings', 'commands', 'alarms', 'connections', 'interventions', 'clients', 'raspberry', 'crs', 'firmware' ]
    : credentials.canEdit ?
      [ 'info', 'status', 'counters', 'recipes', 'settings', 'commands', 'alarms', 'connections', 'interventions', 'firmware', 'contact' ]
    : 
      [ 'info', 'status', 'counters', 'alarms', 'connections', 'interventions', 'firmware', 'contact' ]

  if (sn.split('-')[0] === 'id' || sn.split('-')[0] === 'new') sections = sections.filter(s => !['status', 'counters', 'recipes', 'settings', 'commands', 'alarms', 'connections', 'raspberry', 'crs', 'firmware'].includes(s))

  return(
    <>
    {loading.machines && !redirectCheck && <Loading />}
    {!loading.machines && redirectCheck &&
    <Stack key={machines.filter(e => e.id === sn)[0].id} mih="100dvh" gap={0}>
      {machines.filter(e => e.id === sn).length === 0 && <Loading />}
      {machines.filter(e => e.id === sn).length === 1 &&
        <MachineProvider id={sn}>
          <Stack gap={0} px={{base: 'sm', sm: 'md', md: 'xl'}} py="md" style={{borderBottom: 'calc(0.125rem*var(--mantine-scale)) solid var(--mantine-color-gray-3)'}}>
            <Title order={1} fz={{base: 22, sm: 34, md: 56}} ta={{base: 'center', sm: 'left'}}>{machines.filter(e => e.id === sn)[0].nickname !== '' ? machines.filter(e => e.id === sn)[0].nickname : machines.filter(e => e.id === sn)[0].id}</Title>
            <Group wrap="nowrap" justify={width <= 768 ? 'center' : 'flex-start'}>
              <Badge variant="light" size={{base: 'sm', md: 'lg'}} styles={{root: { flexShrink: 0 }}}>{machines.filter(e => e.id === sn)[0].group !== undefined ? machines.filter(e => e.id === sn)[0].group : 'ALTRI DISPOSITIVI'}</Badge>
              <Title order={4} fw={400} fz={{base: '.7rem', sm: '.9rem', md: '1.125rem'}} c="dimmed">{machines.filter(e => e.id === sn)[0].nickname !== '' ? machines.filter(e => e.id === sn)[0].id : ''}</Title>
            </Group>
          </Stack>
          <Tabs
            defaultValue="info"
            value={section}
            onChange={(value) => navigate(`/dashboard/machines/${sn}/${value}`)}
            orientation="vertical"
            keepMounted={false}
            styles={{
              root: {
                flexGrow: 2
              },
              body: {
                flexGrow: 1,
              },
              tabLabel: {
                whiteSpace: 'nowrap'
              },
              tabSection: {
                marginRight: 0
              }
            }}
            >
              <Tabs.List>
                {sections.map(s =>
                  <Tabs.Tab key={s} value={s} leftSection={tabs[s].icon} pl={{base: 'sm', sm: 'md', md: 'xl'}} pr={{base: 'sm', sm: 'md' }}>
                    <Text inline visibleFrom="sm" ml="sm" size="sm">{tabs[s].label}</Text>
                  </Tabs.Tab>
                )}
              </Tabs.List>
            {sections.map(s =>
              <Tabs.Panel key={s} value={s}>
                {tabs[s].component}
              </Tabs.Panel>  
            )}
          </Tabs>
        </MachineProvider>
      }
    </Stack>
    }
    </>
  )
}

export default Machine

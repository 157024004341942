import { useMachineContext } from '../../utils/machineContext'
import Section from './Section'

import { useMaibaContext } from '../../utils/maibaContext'
import { Chip, Group, Button, Text } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useEffect } from 'react'

const Clients = () => {
  const { clients, updateMachine, updating } = useMachineContext()
  const { users } = useMaibaContext()
  console.log('clients', clients)
  console.log('users', users)
  const form = useForm({
    initialValues: {
      clients: []
    }
  })
  
  useEffect(() => {
    if (!clients) return
    form.initialize({ clients })
  }, [clients])

  return(
    <Section title="Clienti">
      <Text size="sm" ta="center">Seleziona i clienti che hanno accesso a questo dispositivo:</Text>
      <form onSubmit={form.onSubmit(values => {
        updateMachine({
          users: values.clients
        })
      })}>
        <Chip.Group multiple {...form.getInputProps('clients')}>
          <Group justify="center" m="lg">
            {users.sort((a, b) => a.nickname.localeCompare(b.nickname)).map(user => <Chip key={user.id} value={user.id} variant="light" disabled={updating}>{user.nickname}</Chip>)}
          </Group>
        </Chip.Group>
        <Group
          justify="flex-end"
          py="xl"
          >
          <Button
            loading={updating}
            type="submit"
            >
            Salva
          </Button>
        </Group>
      </form>
    </Section>
  )
}

export default Clients

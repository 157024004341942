import { Button, Group, Stack, Title } from '@mantine/core'
import { Link } from 'react-router-dom'
import { ArrowLeft } from 'tabler-icons-react'
import { useViewportSize } from '@mantine/hooks'

const Section = ({title, buttons, backLink, children}) => {
  const { height, width } = useViewportSize()
  return(
    <Stack px={{base: 'md', sm: 'xl'}} pt="sm" pb="lg" position="center" align="stretch">
      <Group justify="space-between" gap="xs" my="lg">
        <Group gap="xl">
          {backLink && <Button variant="outline" size="xs" leftSection={<ArrowLeft size={14} />} component={Link} to={backLink}>Torna indietro</Button>}
          <Title order={2} align="center">{title}</Title>
        </Group>
        
        {buttons &&
          <Button.Group orientation={width < 768 ? 'vertical' : 'horizontal'}>
            {buttons.map(button => button)}
          </Button.Group>
        }
      </Group>
      {children}
    </Stack>
  )
}

export default Section
const routes = {
  LOGIN: '/login',
  DASHBOARD: '/dashboard/*',
  DASHBOARD_A: '/dashboard',
  USERS: '/users',
  USERS_A: '/dashboard/users',
  MACHINES: '/machines',
  MACHINES_A: '/dashboard/machines',
  ALARMS: '/alarms',
  ALARMS_A: '/dashboard/alarms',
  INTERVENTIONS: '/interventions',
  INTERVENTIONS_A: '/dashboard/interventions',
}

export default routes

import { useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import 'moment/locale/it'

import { useMachineContext } from '../../utils/machineContext'
import { toMQTT } from '../../utils/func'
import { Group, Text, Tooltip, Button, ActionIcon, Menu, Stack } from '@mantine/core'
import { notifications } from '@mantine/notifications'
import { Check, ChevronDown, CircleX, Refresh } from 'tabler-icons-react'
import { useAuthContext } from '../../utils/authContext'

const UpdateBtn = ({date, time, options, set, period, setPeriod, hidePeriod = false}) => {
  const { line } = useMachineContext()
  const { credentials } = useAuthContext()
  
  moment.locale('it')
  const [isSending, setIsSending] = useState(false)
  const [isOnline] = useState(line ? line.status : false)

  const [periodField] = useState([
    {
      period: false,
      value: '0',
      label: 'Non ripetere'
    },
    {
      period: { 
        sec: 0,
        min: 15,
        hour: 0,
        day: 0
      },
      value: '15',
      label: 'Ogni 15 minuti'
    },
    {
      period: { 
        sec: 0,
        min: 30,
        hour: 0,
        day: 0
      },
      value: '30',
      label: 'Ogni 30 minuti'
    },
    {
      period: { 
        sec: 0,
        min: 0,
        hour: 1,
        day: 0
      },
      value: '60',
      label: 'Ogni ora'
    },
    {
      period: { 
        sec: 0,
        min: 0,
        hour: 2,
        day: 0
      },
      value: '120',
      label: 'Ogni 2 ore'
    },
    {
      period: { 
        sec: 0,
        min: 0,
        hour: 6,
        day: 0
      },
      value: '360',
      label: 'Ogni 6 ore'
    },
    {
      period: { 
        sec: 0,
        min: 0,
        hour: 12,
        day: 0
      },
      value: '720',
      label: 'Ogni 12 ore'
    },
    {
      period: { 
        sec: 0,
        min: 0,
        hour: 0,
        day: 1
      },
      value: '1440',
      label: 'Ogni giorno'
    },
  ])
  
  const handleUpdate = (newPeriod) => {
    if (!newPeriod) newPeriod = period ? periodField.filter(e => _.isEqual(e.period, period))[0].value : '0'

    const periodAPI = periodField.filter(e => e.value === newPeriod)[0].period

    setIsSending(true)
    toMQTT([{
      id: options.id,
      topic: options.topic,
      message: {
        data: {},
        period: periodAPI
      }}])
      .then(res => {
        if (res.result === 'ok') {
          set(res)
          setPeriod(periodAPI)
          notifications.show({
            title: 'Successo',
            message: 'I valori sono stati aggiornati!',
            color: 'green',
            icon: <Check size={18} />
          })
        } else {
          notifications.show({
            title: 'Errore',
            message: 'La richiesta è andata in timeout!',
            color: 'red',
            icon: <CircleX size={18} />
          })
        }
        setIsSending(false)
      })
  }
  return(
    <Group justify="flex-end" mt="auto" pt={60} w="100%">
      <Stack align="flex-end" gap={0}>
        <Text c="dimmed" size="xs">Aggiornato al</Text>
        <Text size="sm">
          {date ? 
            `${moment(date, 'YYYY/MM/DD').format('DD/MM/YYYY')} ${time}`
            :
            `Nessun dato disponibile!`
          }
        </Text>
      </Stack>
      {process.env.REACT_APP_NAME === 'iot-maiba-backoffice' &&
        <Group>
          <ActionIcon
            onClick={() => handleUpdate()}
            aria-label="Aggiorna"
            loading={isSending}
            disabled={!isOnline}
            size="lg"
            color="blue"
            variant="outline"
            sx={{width: 36, height: 36}}
            >
            <Refresh size={16} />
          </ActionIcon>
          {!hidePeriod && 
            <Menu
              position="top"
              placement="end"
              withArrow
              >
              <Menu.Target>
                <Button variant="outline" rightIcon={<ChevronDown size={16} />} disabled={!isOnline || isSending}>
                  Invia {period && periodField.filter(e => _.isEqual(e.period, period))[0] && periodField.filter(e => _.isEqual(e.period, period))[0].label.toLowerCase()}
                </Button>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Label>Periodicità</Menu.Label>
                {periodField.map(field => 
                  <Menu.Item
                    key={field.label}
                    onClick={() => handleUpdate(field.value)}
                    >
                    {field.label}
                  </Menu.Item>)
                }
              </Menu.Dropdown>
            </Menu>
          }
        </Group>
      }
    </Group>
  )
}

export default UpdateBtn
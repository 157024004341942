import { useState } from "react"
import dayjs from "dayjs"
import { useMachineContext } from "../../utils/machineContext"
import { Button, Stack, Box, Text, Divider, Flex, Loader, SimpleGrid, Badge, NumberFormatter } from '@mantine/core'
import { useViewportSize } from '@mantine/hooks'
import { DatePickerInput } from '@mantine/dates'
import { Calendar } from "tabler-icons-react"
import axios from "axios"
import { COUNTS } from "../../utils/func"

const Count = () => {
  const { width } = useViewportSize()
  const { id } = useMachineContext()
  const [calculating, set_calculating] = useState(false)
  const [dates, set_dates] = useState([null, null])
  const [counts, set_counts] = useState(false)

  return(
    <Box p="md" style={{border: 'calc(.0625rem* var(--mantine-scale)) solid var(--mantine-color-gray-2)', borderRadius: 'var(--mantine-radius-default)'}}>
    <Stack gap="md" align="center">
      <Text align="center" fw={700}>Calcola le quantità erogate</Text>
      <Flex gap="sm" wrap="wrap">
        <DatePickerInput
          type="range"
          placeholder="Seleziona un periodo..."
          value={dates}
          onChange={set_dates}
          maxDate={new Date()}
          valueFormat="dddd D MMMM YYYY"
          clearable={false}
          numberOfColumns={2}
          locale="it"
          leftSection={<Calendar size={16} />}
          styles={{
            root: {
              flexGrow: 2
            },
            input: {
              textTransform: 'capitalize'
            }
          }}
          disabled={calculating}
          />
        <Button
          fullWidth={width < 768}
          onClick={() => {
            if (dates.includes(null)) return
            set_calculating(true)
            axios.get(`/api/machine/${id}/count/${dayjs(dates[0]).format('YYYYMMDD')}/${dayjs(dates[1]).format('YYYYMMDD')}`)
              .then(res => {
                console.log('counts', res.data)
                if (res.data) {
                  set_counts(res.data)
                }
                set_calculating(false)
              })
          }}
          loading={calculating}
          loaderProps={{type: 'bars'}}
          >
          Calcola
        </Button>
      </Flex>
      {calculating && <Loader type="bars" />}
      {!calculating && counts &&
        <SimpleGrid cols={{ base: 1, sm: 2, md: 3 }} w="100%">
          <CountDetail
            title="Prima lettura disponibile"
            datetime={`${dayjs(counts.start_date).format('DD/MM/YYYY')} ${counts.start_time}`}
            values={counts.start_data}
            />
          <CountDetail
            title="Ultima lettura disponibile"
            datetime={`${dayjs(counts.end_date).format('DD/MM/YYYY')} ${counts.end_time}`}
            values={counts.end_data}
            />
          <CountDetail
            title="Litri erogati"
            datetime={`${dayjs(counts.end_date).diff(dayjs(counts.start_date), 'day')} giorni`}
            values={Object.keys(COUNTS).reduce((acc, curr, i) => ({
              ...acc,
              [curr]: counts.end_data[curr] && counts.start_data[curr] ? counts.end_data[curr] - counts.start_data[curr] : '---'
            }), {})}
            colored={true}
            />
          
        </SimpleGrid>
      }
    </Stack>
    </Box>
  )
}

const CountDetail = ({title, datetime, values, colored = false}) => {
  return(
    <Stack gap="xs" p="sm" bg={colored ? 'var(--mantine-color-blue-light)' : 'transparent'} styles={{root: {borderRadius: 'var(--mantine-radius-default)'}}}>
      <Stack gap={0}>
        <Text size="sm" fw={700}>{title}</Text>
        <Text size="sm">{datetime}</Text>
      </Stack>
      <Divider />
      {Object.keys(COUNTS).map(key => {
        return(
          <Stack gap={5}>
            <Text size="xs">{COUNTS[key].label}</Text>
            <Badge size="md" variant="light" color="blue"><NumberFormatter thousandSeparator="." decimalSeparator="," value={values[key] ?? '---'} /> {COUNTS[key].unit}</Badge>
          </Stack>
        )
      })}
    </Stack>
  )
}

export default Count
import { Group, Button, Modal, Menu } from '@mantine/core'
import Iubenda from 'react-iubenda-policy'
import { useState } from 'react'
import Loading from './Loading'

const Policies = ({isMenu = false}) => {
  const [ opened, setOpened ] = useState(false)
  const [ loading, setLoading ] = useState(true)

  const doOpen =(which) => {
    setLoading(true)
    const element = which === 'privacy' ? document.createElement('iframe') : document.createElement('script')
    if (which === 'privacy') {
      element.src = 'https://www.iubenda.com/privacy-policy/71498095'
      element.width = '100%'
      element.height = 600
      element.style = 'border: 0;'
    } else {
      element.src = "https://consent.cookiebot.com/53fbcf45-a548-4203-a9d9-410c9aaf3aab/cd.js"
      element.async = true
    }
    setOpened(true)
    setTimeout(() => {
      setLoading(false)
      document.getElementById('policy').appendChild(element)
    }, 400)
  }
  if (isMenu) {
    return (
      <>
        <Menu.Item>
          <Iubenda id="71498095" type="privacy" />
        </Menu.Item>
        <Menu.Item onClick={() => doOpen('cookie')}>
          Cookie Policy
        </Menu.Item>
        <Modal
          opened={opened}
          onClose={() => setOpened(false)}
          size="xl"
          title="Cookie Policy"
          closeOnClickOutside
          closeOnEscape
          withCloseButton
          >
          {loading && <Loading />}
          <div id="policy" />
        </Modal>
      </>
    )
  }
  return(
    <Group justify="center">
      <Button variant="transparent" size="xs-compact" p={0}>
        <Iubenda id="71498095" type="privacy" />
      </Button>
      <Button variant="transparent" size="xs-compact" p={0} onClick={() => doOpen('cookie')}>
        Cookie Policy
      </Button>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        size="xl"
        title="Cookie Policy"
        closeOnClickOutside
        closeOnEscape
        withCloseButton
        >
        {loading && <Loading />}
        <div id="policy" />
      </Modal>
    </Group>
  )
}

export default Policies
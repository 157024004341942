import { useState, useEffect } from 'react'
import axios from 'axios'
import moment from 'moment'
import 'moment/locale/it'

import { useMachineContext } from '../../utils/machineContext'
import Loading from '../Loading'
import Section from './Section'
import Chart from './Chart'
import UpdateBtn from './UpdateBtn'
import { SimpleGrid, SegmentedControl, Table, Title, Text, Card, Stack, } from '@mantine/core'
import 'dayjs/locale/it'
import DateSelector from './DateSelector'
import { useViewportSize } from '@mantine/hooks'
import GaugeComponent from 'react-gauge-component'

const Status = () => {
  const { id } = useMachineContext()
  const { width } = useViewportSize()

  moment.locale('it')

  const [ loading, setLoading ] = useState(true)
  const [ viewing, setViewing ] = useState('last')

  const [ day, setDay ] = useState(moment().format('YYYYMMDD'))
  const [ status, setStatus ] = useState([])
  const [ lastStatus, setLastStatus ] = useState({
    date: false,
    time: false,
    payload: {
        machineStatus: 0,
        h2oStatus: 0,
        co2Status: 0,
        h2oPress: 0,
        compPressMin: 0,
        compPressMax: 0,
        co2Press: 0,
        co2Mass: 0,
        tempAmbient: 0,
        tempIce: 0,
        drainFull: 0,
        glassPresence: 0,
        doorStatus: 0,
        alarm: []
    }
  })
  const [ periodStatus, setPeriodStatus ] = useState(false)

  const getStatus = (when) => {
    if (day != when) {
      const theDay = when || day
      axios.get(`/api/machine/${id}/status/${theDay}`)
        .then(res => {
          setDay(theDay)
          setStatus(
            res.data.map((value, i) => ({
              ...value,
              payload: {
                ...value.payload,
                co2MassDelta: res.data[i-1] ? value.payload.co2Mass - res.data[i-1].payload.co2Mass : 0,
              },
              datetime: `${value.date} ${value.time}`,
              xLabel: value.time.slice(0, -3),
            }))
          )
          
          console.log('status', status)

          setLoading(false)
        })
    }
  }

  useEffect(() => {
    axios.get(`/api/machine/${id}/status`)
      .then(res => {
        console.log('lastStatus', res.data)
        if (res.data) {
          setLastStatus(res.data.status)
          setPeriodStatus(res.data.period)
        }
      })
    getStatus()
  }, [])

  // aggiungo last status a status
  useEffect(() => {
    if (lastStatus) {
      if (status.length > 0) {
        if (lastStatus.date === status[0].date && status.filter(e => e.time === lastStatus.time).length === 0) {
          setStatus([
            ...status,
            {
              ...lastStatus,
              datetime: `${lastStatus.date} ${lastStatus.time}`,
              xLabel: lastStatus.time.slice(0, -3),
            }
          ])
        } else if (lastStatus.date != status[0].date) {
          getStatus(moment().format('YYYYMMDD'))
        }
      } else {
        if (lastStatus.date === moment().format('D/M/YYYY')) {
          setStatus([
            {
              ...lastStatus,
              datetime: `${lastStatus.date} ${lastStatus.time}`,
              xLabel: lastStatus.time.slice(0, -3),
            }
          ])
        }
      }
    }
  }, [lastStatus])

   // colors
  const red = 'var(--mantine-color-red-filled)'
  const yellow = 'var(--mantine-color-yellow-filled)'
  const green = 'var(--mantine-color-teal-filled)'

  // status dict
  const statusDict = [
    { key: 'h2oPress', label: 'Pressione acqua' },
    { key: 'co2Press', label: 'Pressione CO2' },
    { key: 'tempAmbient', label: 'Temperatura ambiente' },
    { key: 'tempIce', label: 'Temperatura vasca del ghiaccio' },
    { key: 'co2Mass', label: 'Massa CO2' },
    { key: 'co2Status', label: 'Presenza CO2',
      value: {
        0: 'EMPTY',
        1: 'OK',
      },
    },
    { key: 'compPressMax', label: 'Compressore press. max' },
    { key: 'compPressMin', label: 'Compressore press. min' },
    { key: 'doorStatus', label: 'Stato della porta',
      value: {
        1: 'Porta chiusa',
        0: 'Porta aperta',
      },
    },
    { key: 'drainFull', label: 'Tanica di raccolta',
      value: {
        0: 'OK',
        1: 'ALLAGAMENTO o scarico pieno',
      },
    },
    { key: 'glassPresence', label: 'Sensore bicchiere',
      value: {
        0: 'Bicchiere assente',
        1: 'Bicchiere presente',
      },
    },
    { key: 'h2oStatus', label: 'Stato acqua (?)' },
    { key: 'machineStatus', label: 'Stato macchina',
      value: {
        0: 'INIT',
        1: 'WARMPUP',
        2: 'OFF',
        3: 'ON',
        4: 'OUT OF SERVICE',
        5: 'Lavaggio breve (autoflushing)',
        6: 'Lavaggio lungo (sanificazione)',
        7: 'ozonizzazione',
      },
    },
  ]
  return(
    <Section title="Status">
      <SegmentedControl
        data={[
          { label: 'Ultima lettura', value: 'last' },
          { label: 'Storico', value: 'history' }
        ]}
        value={viewing}
        onChange={(value) => setViewing(value)}
        sx={{ alignSelf: 'flex-end' }}
        />
      {loading && <Loading />}
      {!loading && viewing === 'last' &&
      <>
        <SimpleGrid cols={{ base: 1, md: 2, lg: 2, xl: 4 }} pb="lg">
          {[
            {
              id: 'h2oPress',
              value: lastStatus.payload?.h2oPress,
              label: 'Pressione acqua',
              unit: ' bar',
              arcs: [
                { limit: 0, color: red },
                { limit: 1, color: red },
                { limit: 3, color: yellow },
                { limit: 4.5, color: green },
                { limit: 6, color: yellow },
                { limit: 10, color: red }
              ],
            },
            {
              id: 'co2Press',
              value: lastStatus.payload?.co2Press,
              label: 'Pressione CO2',
              unit: ' bar',
              arcs: [
                { limit: 0, color: red },
                { limit: 1, color: red },
                { limit: 2.5, color: yellow },
                { limit: 4, color: green },
                { limit: 6, color: yellow },
                { limit: 10, color: red }
              ],
            },
            {
              id: 'tempAmbient',
              value: lastStatus.payload?.tempAmbient,
              label: 'Temperatura ambiente',
              unit: ' °C',
              arcs: [
                { limit: -5, color: red },
                { limit: -2, color: red },
                { limit: 10, color: yellow },
                { limit: 30, color: green },
                { limit: 40, color: yellow },
                { limit: 50, color: red }
              ],
            },
            {
              id: 'tempIce',
              value: lastStatus.payload?.tempIce,
              label: 'Temperatura vasca del ghiaccio',
              unit: ' °C',
              arcs: [
                { limit: -10, color: green },
                { limit: 10, color: green },
                { limit: 15, color: yellow },
                { limit: 30, color: red },
              ],
            },
          ].map(g => 
            <Stack gap={0} maw={300}>
              <GaugeComponent
                id={g.id}
                value={g.value}
                minValue={g.arcs[0].limit}
                maxValue={g.arcs[g.arcs.length - 1].limit}
                style={{width: '100%'}}
                arc={{
                  subArcs: g.arcs.map(a => ({ ...a, showTick: true })),
                  width: .25
                }}
                labels={{
                  valueLabel: {
                    matchColorWithArc: true,
                    formatTextValue: val => `${val}${g.unit}`,
                    style: { fontSize: 30, fill: '#000', textShadow: 'none', fontWeight: 700 }
                  },
                }}
                />
              <Text size="xs" fw={700} ta="center">{g.label}</Text>
            </Stack>
          )}
        </SimpleGrid>
        <Table horizontalSpacing="md" striped>
          <Table.Tbody>
            {statusDict.map(stat => {
              return(
                <Table.Tr key={stat.key}>
                  <Table.Td><Text align="right">{stat.label}:</Text></Table.Td>
                  <Table.Td>
                    <Title order={2} weight={700}>
                      {!stat.value && lastStatus.payload[stat.key]}
                      {stat.value && stat.value[lastStatus.payload[stat.key]]}
                    </Title>
                  </Table.Td>
                </Table.Tr>
              )
            })}
          </Table.Tbody>
        </Table>
        </>
      }
      {!loading && viewing === 'history' &&
        <>
          <DateSelector day={day} get={getStatus} />
          <Chart
            title="Pressione"
            data={status}
            values={[
              {
                value: 'payload.h2oPress',
                label: 'H2O'
              },
              {
                value: 'payload.co2Press',
                label: 'CO2'
              }
            ]}
            definition={{
              h2oPress: '[value] bar',
              co2Press: '[value] bar',
            }}
            />
          <Chart
            title="Temperatura"
            data={status}
            values={[
              {
                value: 'payload.tempAmbient',
                label: 'Ambiente'
              },
              {
                value: 'payload.tempIce',
                label: 'Vasca del ghiaccio'
              }
            ]}
            definition={{
              tempAmbient: '[value] °C',
              tempIce: '[value] °C',
            }}
            />
          <Chart
            title="CO2"
            data={status} values={[
              {
                value: 'payload.co2Mass',
                label: 'Massa CO2'
              },
              {
                value: 'payload.co2MassDelta',
                label: 'Delta Massa CO2'
              },
            ]}
            definition={{
              co2Mass: '[value] kg',
              co2MassDelta: '[value]',
            }}
            />
          <Chart
            title="Compressore"
            data={status} values={[
              {
                value: 'payload.compPressMin',
                label: 'Compressore press. min.'
              },
              {
                value: 'payload.compPressMax',
                label: 'Compressore press. max.'
              },
            ]}
            definition={{
              compPressMin: '[value] bar',
              compPressMax: '[value] bar',
            }}
            />
          <Chart
            title="Varie"
            data={status}
            values={[
              {
                value: 'payload.machineStatus',
                label: 'Stato macchina'
              },
              {
                value: 'payload.co2Status',
                label: 'Presenza CO2'
              },
              {
                value: 'payload.drainFull',
                label: 'Tanica di raccolta'
              },
              {
                value: 'payload.glassPresence',
                label: 'Sensore bicchiere'
              },
              {
                value: 'payload.doorStatus',
                label: 'Stato della porta'
              }
            ]}
            definition={{
              machineStatus: {
                0: 'INIT',
                1: 'WARMPUP',
                2: 'OFF',
                3: 'ON',
                4: 'OUT OF SERVICE',
                5: 'lavaggio breve (autoflushing)',
                6: 'lavaggio lungo (sanificazione)',
                7: 'ozonizzazione',
              },
              co2Status: {
                0: 'EMPTY',
                1: 'OK',
              },
              drainFull: {
                0: 'OK',
                1: 'ALLAGAMENTO o scarico pieno',
              },
              glassPresence: {
                0: 'Bicchiere assente',
                1: 'Bicchiere presente',
              },
              doorStatus: {
                1: 'Porta chiusa',
                0: 'Porta aperta',
              },
            }}
            />
          <Chart
            title="Allarmi"
            data={status}
            values={[
              {
                value: 'payload.alarm.value',
                label: false
              },
            ]}
            definition={{
              alarm: {
                AL0: 'Mancanza pressione acqua!',
                AL5: 'Carbonatore: timeout riempimento!',
                AL6: 'Dati corrotti!',
                AL7: 'Filtro esaurito!',
                AL9: 'Orologio!',
                AL10: 'Mancanza CO2!',
                AL11: 'Allagamento!',
                AL12: 'Lampada UV 1',
                AL13: 'Lampada UV 2',
                AL14: 'Compressore: pressione minima!',
                AL15: 'Compressore: pressione massima!',
                AL16: 'Bombola CO2 1 vuota!',
                AL17: 'Bombola CO2 2 vuota!',
                AL18: 'Bombola CO2 3 vuota!',
                AL19: 'Bombola CO2 4 vuota!',
                AL20: 'Lampada UV 1 guasta (lampada UV filtro)',
                AL21: 'Lampada UV 2 guasta (lampada UV erogatore)',
              }
            }}
            />
        </>
      }
      {!loading && 
        <UpdateBtn date={lastStatus.date} time={lastStatus.time} options={{topic: 'Status/Read', id}} set={setLastStatus} period={periodStatus} setPeriod={setPeriodStatus} />
      }
    </Section>
  )
}

export default Status

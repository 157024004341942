import { useState, useEffect, useMemo } from 'react'
import { useForm } from '@mantine/form'

import { useMachineContext } from '../../utils/machineContext'
import { Autocomplete, TextInput, Group, Stack, Text, Select, Button, Accordion, NumberInput, SimpleGrid, Divider, Modal, Box, Title } from '@mantine/core'
import { useViewportSize, useDisclosure } from '@mantine/hooks'
import { InfoCircle, Settings } from 'tabler-icons-react'
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete"
import { useExternalScripts } from '../../utils/func'
import { useMaibaContext } from '../../utils/maibaContext'
import { saveAs } from 'file-saver'
import QRCode from 'qrcode' 
import { useAuthContext } from '../../utils/authContext'
import axios from 'axios'

const Infos = () => {
  const { width } = useViewportSize()
  const [opened, { close, open }] = useDisclosure(false)
  useExternalScripts(`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAPS_API_KEY}&libraries=places&callback=initAutocomplete`)
  const { groups } = useMaibaContext()
  const { id, nickname, group, address, updating, updateMachine, deleteMachine, settings } = useMachineContext()
  const { credentials } = useAuthContext()

  const form = useForm({
    initialValues: {
      nickname: nickname,
      group: group,
      address: {
        label: address?.label ? address.label : '',
        coords: {
          lat: address?.coords?.lat ? address.coords.lat : '',
          lng: address?.coords?.lng ? address.coords.lng : ''
        }
      },
      settings: {
        min_level_co2: settings && settings.min_level_co2 ? settings.min_level_co2 : 20,
        min_level_bottle: settings && settings.min_level_bottle ? settings.min_level_bottle : 20,
        min_temp: settings && settings.min_temp ? settings.min_temp : -3,
        max_temp: settings && settings.max_temp ? settings.max_temp : 50,
        min_tank_temp: settings && settings.min_tank_temp ? settings.min_tank_temp : -3,
        max_tank_temp: settings && settings.max_tank_temp ? settings.max_tank_temp : 15,
        expiration_uv_filter_lamp: settings && settings.expiration_uv_lamp ? settings.expiration_uv_lamp : 11,
        expiration_uv_filter_lamp_dispenser: settings && settings.expiration_uv_filter_lamp_dispenser ? settings.expiration_uv_filter_lamp_dispenser : 11,
        expiration_beccuccio: settings && settings.expiration_beccuccio ? settings.expiration_beccuccio : 12,
      }
    },
    transformValues: values => ({
      ...values,
      address: {
        ...values.address,
        coords: {
          lat: parseFloat(values.address.coords.lat),
          lng: parseFloat(values.address.coords.lng)
        }
      }
    })
  })

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    callbackName: "initAutocomplete",
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 1000,
  })
  const [ predictionsList, setPredictionsList ] = useState([])

  useEffect(() => {
    console.log(status, data)
    if (data.length === 0) {
      setPredictionsList(['Nessun risultato!'])
      return
    }
    setPredictionsList(data.map(p => p.description))
  }, [data])

  useEffect(() => {
    if (!ready || !address || !address.coords.lat || !address.coords.lng) return
    const initMap = async () => {
      const { Map } = await window.google.maps.importLibrary("maps")
      const { AdvancedMarkerElement } = await window.google.maps.importLibrary("marker")

      let map = new Map(document.getElementById("map"), {
        center: address.coords,
        zoom: 12,
        mapId: 'position'
      })
      const marker = new AdvancedMarkerElement({
        map: map,
        position: address.coords,
        title: 'machine',
      })
    }
    initMap()
  }, [ready, address])

  return(
    <>
      {address && address.coords && address.coords.lat && address.coords.lng  && <div id="map" style={{height: width <= 768 ? 320 : 440}}></div>}

      <Group justify="space-between" px="lg" py="sm" bg="gray.0" styles={{root: { borderBottom: '1px solid var(--mantine-color-gray-3)', borderTop: '1px solid var(--mantine-color-gray-3)' }}}>
        <Button.Group>
          <Button size="xs" variant="outline" target="_blank" onClick={async () => {
            const qrCode =await QRCode.toDataURL(`https://iot.depuratorimaiba.it/dashboard/machines/${id}`, { scale: 12 })
            saveAs(qrCode, `QR Code - ${nickname}`)
          }}>Scarica il QR Code</Button>
        </Button.Group>
        {address && address.label &&
          <Button.Group>
            <Button size="xs" variant="outline" component="a" href={`https://www.google.it/maps/place/${address.label}`} target="_blank">Apri in Google Maps</Button>
            <Button size="xs" variant="outline" component="a" href={`https://www.google.it/maps/dir//${address.label}`} target="_blank">Ottieni indicazioni</Button>
          </Button.Group>
        }
      </Group>
      <form onSubmit={form.onSubmit(async (values) => {
        updateMachine({
          address: values.address,
          nickname: values.nickname,
          group: values.group === 'ALTRI DISPOSITIVI' ? undefined : values.group,
          settings : values.settings
        })
      })}>
        <Accordion defaultValue="anagraphic" variant="contained" m="lg">
          <Accordion.Item key="anagraphic" value="anagraphic">
            <Accordion.Control icon={<InfoCircle size={18} color="var(--mantine-color-blue-filled)" />}>
              Anagrafica
            </Accordion.Control>
            <Accordion.Panel>
              <Stack>
                <TextInput
                  size="xs"
                  label="Nome dispositivo"
                  placeholder="Inserisci un nome per il dispositivo..."
                  disabled={updating || !credentials.isAdmin}
                  {...form.getInputProps('nickname')}
                  />
                <Select
                  size="xs"
                  label="Gruppo"
                  placeholder="Seleziona..."
                  data={Object.keys(groups).map(g => ({ value: g, label: g === 'undefined' ? 'ALTRI DISPOSITIVI' : g }))}
                  disabled={updating || !credentials.isAdmin}
                  {...form.getInputProps('group')}
                  />
                <Autocomplete
                  size="xs"
                  label="Posizione"
                  data={predictionsList}
                  value={form.values.address.label}
                  filter={({options}) => options}
                  onChange={v => {
                    form.setFieldValue('address.label', v)
                    setValue(v)
                  }}
                  onOptionSubmit={async v => {
                    form.setFieldValue('address.label', v)
                    setValue(v)
                    const geocode = await getGeocode({ address: v })
                    const { lat, lng } = getLatLng(geocode[0])
                    form.setFieldValue('address.coords.lat', lat)
                    form.setFieldValue('address.coords.lng', lng)
                  }}
                  disabled={updating || !credentials.isAdmin}
                  />
                <TextInput
                  size="xs"
                  label="Latitudine"
                  description="Viene compilato in automatico se viene selezionato l'indirizzo."
                  disabled={updating || !credentials.isAdmin}
                  {...form.getInputProps('address.coords.lat')}
                  />
                <TextInput
                  size="xs"
                  label="Longitudine"
                  description="Viene compilato in automatico se viene selezionato l'indirizzo."
                  disabled={updating || !credentials.isAdmin}
                  {...form.getInputProps('address.coords.lng')}
                  />
                <TextInput
                  size="xs"
                  label="Numero seriale"
                  value={id}
                  disabled
                  />
              </Stack>
            </Accordion.Panel>
          </Accordion.Item>
          {credentials.isAdmin &&
            <Accordion.Item key="settings" value="settings">
              <Accordion.Control icon={<Settings size={18} color="var(--mantine-color-blue-filled)" />}>
                Preferenze
              </Accordion.Control>
              <Accordion.Panel>
                <Text size="sm" mt="xs">Notifiche</Text>
                <Text size="xs" c="dimmed" mb="xs">Al cliente verranno notificati i valori che non soddisfano i seguenti limiti con allarmi o al salvataggio di un intervento.</Text>
                <SimpleGrid cols={{base: 1, sm: 2}}>
                  <NumberInput
                    label="Livello CO2 minimo"
                    description="Intervento"
                    size="xs"
                    clampBehavior="strict"
                    min={0}
                    max={100}
                    suffix="%"
                    {...form.getInputProps('settings.min_level_co2')}
                    />
                  <NumberInput
                    label="Livello CO2 bombole minimo"
                    description="Intervento"
                    size="xs"
                    clampBehavior="strict"
                    min={0}
                    max={100}
                    suffix="%"
                    {...form.getInputProps('settings.min_level_bottle')}
                    />
                  <NumberInput
                    label="Temperatura ambiente minima"
                    description="Allarme"
                    size="xs"
                    clampBehavior="strict"
                    suffix="°"
                    {...form.getInputProps('settings.min_temp')}
                    />
                  <NumberInput
                    label="Temperatura ambiente massima"
                    description="Allarme e intervento"
                    size="xs"
                    clampBehavior="strict"
                    suffix="°"
                    {...form.getInputProps('settings.max_temp')}
                    />
                  <NumberInput
                    label="Temperatura vasca del ghiaccio minima"
                    description="Allarme"
                    size="xs"
                    clampBehavior="strict"
                    suffix="°"
                    {...form.getInputProps('settings.min_tank_temp')}
                    />
                  <NumberInput
                    label="Temperatura vasca del ghiaccio massima"
                    description="Allarme"
                    size="xs"
                    clampBehavior="strict"
                    suffix="°"
                    {...form.getInputProps('settings.max_tank_temp')}
                    />
                </SimpleGrid>
                <Divider my="lg" />
                <Text size="sm" mt="xs">Scadenze sostituzioni</Text>
                <Text size="xs" c="dimmed" mb="xs">Nel form di modifica intervento verranno proposte delle date di scadenza in relazione ai seguenti valori.</Text>
                <SimpleGrid cols={{base: 1, sm: 3}}>
                  <NumberInput
                    label="Durata lampada UV"
                    size="xs"
                    min={0}
                    suffix=" mesi"
                    {...form.getInputProps('settings.expiration_uv_filter_lamp')}
                    />
                  <NumberInput
                    label="Durata lampada filtro UV"
                    size="xs"
                    min={0}
                    suffix=" mesi"
                    {...form.getInputProps('settings.expiration_uv_filter_lamp_dispenser')}
                    />
                  <NumberInput
                    label="Durata beccuccio"
                    size="xs"
                    min={0}
                    suffix=" mesi"
                    {...form.getInputProps('settings.expiration_beccuccio')}
                    />
                </SimpleGrid>
              </Accordion.Panel>
            </Accordion.Item>
          }
        </Accordion>
        {credentials.isAdmin &&
          <Group justify="space-between" px="lg" py="xl">
            <Modal opened={opened} onClose={close} size="md" centered styles={{body: {padding: 0}}}>
              <Box px="lg" py="md">
                <Title ta="center">Eliminare il dispositivo {id}?</Title>
                <Text ta="center" c="dimmed" mt="lg" mb="xl">Questa azione è irreversibile e cancellerà tutte le informazioni e gli interventi relativi al dispositivo.</Text>
                <Group justify="space-between">
                  <Button variant="outline" onClick={close} disabled={updating}>Annulla</Button>
                  <Button color="red" onClick={() => deleteMachine()} loading={updating}>Elimina</Button>
                </Group>
              </Box>
            </Modal>
            <Button
              color="red"
              disabled={!['id', 'new'].includes(id.split('-')[0]) || updating}
              onClick={open}
              >
              Elimina
            </Button>
            <Button
              type="submit"
              loading={updating}
              >
              Salva
            </Button>
          </Group>
        }
      </form>
    </>
  )
}

export default Infos


import { useState, useEffect } from 'react'
import axios from 'axios'
import moment from 'moment'

import { useMachineContext } from '../../utils/machineContext'
import Section from './Section'
import Loading from '../Loading'
import UpdateBtn from './UpdateBtn'
import { toMQTT } from '../../utils/func'
import { Check, CircleX } from 'tabler-icons-react'
import { Accordion, Title, Group, NumberInput, Button, SimpleGrid } from '@mantine/core'
import { DonutChart } from '@mantine/charts'
import { notifications } from '@mantine/notifications'
import { useViewportSize } from '@mantine/hooks'

import '@mantine/charts/styles.css'

const Recipes = () => {
  const { id } = useMachineContext()
  const { width } = useViewportSize()

  moment.locale('it')

  const [loading, setLoading] = useState(true)
  const [isSending, setIsSending] = useState(false)
  const [ settings, setSettings ] = useState(false)
  const [ periodSettings, setPeriodSettings ] = useState(false)

  const [impulsesLt, setImpulsesLt] = useState([])
  const [recipes, setRecipes] = useState([])

  useEffect(() => {
    axios.get(`/api/machine/${id}/settings/recipes`)
      .then(res => {
        console.log('settings', res.data)
        if (res.data) {
          setSettings(res.data.settings)
          setPeriodSettings(res.data.period)
        } else {
          setSettings({
            date: false,
            time: false,
            payload: []
          })
          setPeriodSettings(false)
        }
      })
  }, [])

  useEffect(() => {
    if (settings) {
      let impulsesLtTemp = []
      let recipesTemp = []
      for (const k in settings.payload) {
        if (!/DoseProdotto\d+Linea\d+|cv\d+Imp/g.test(k)) continue
        const [product, line] = k.match(/\d+/g)
        if (!line) {
          impulsesLtTemp.push(settings.payload[k])
        } else {
          const productInt = parseInt(product, 10)
          if (!recipesTemp[productInt]) {
            recipesTemp[productInt] = {
              ml: 0,
              lines: []
            }
          }
          recipesTemp[productInt].lines.push({
            key: k,
            impulse: settings.payload[k] || 0,
            percent: 0
          })
        }
      }
      for (const k in recipesTemp) {
        // calcolo gli ml totali
        recipesTemp[k].ml = recipesTemp[k].lines.map((line, i) => Math.round(line.impulse/impulsesLtTemp[i]*1000)).reduce((a, b) => a + b, 0) || 0
        // calcolo le percentuali
        recipesTemp[k].lines = recipesTemp[k].lines.map((line, i) => ({
          ...line,
          percent: Math.round(line.impulse/impulsesLtTemp[i]*1000/recipesTemp[k].ml*100) || 0
        }))
      }

      console.log('settings', impulsesLtTemp, recipesTemp)
      setImpulsesLt( impulsesLtTemp )
      setRecipes( recipesTemp )
      setLoading(false)
    }
  }, [settings])

  const handleSave = (item) => {
    setIsSending(true)
    let newRecipes = {}
    for (const k in item.lines) {
      newRecipes[item.lines[k].key] = Math.round(
        impulsesLt[k]*(item.ml*(item.lines[k].percent/100)/1000)
        )
    }
    console.log('newRecipe', impulsesLt, item, newRecipes)
    toMQTT([
        {
          id,
          topic: 'Settings/Write',
          message: newRecipes
        },
        {
          id,
          topic: 'Settings/Read',
          message: {
            data: {},
            period: periodSettings
          }
        }
      ])
      .then(res => {
        if (res.result === 'ok') {
          setSettings({
            date: res.date,
            time: res.time,
            payload: res.payload
          })
          notifications.show({
            title: 'Successo',
            message: 'I valori sono stati aggiornati!',
            color: 'green',
            icon: <Check size={18} />
          })
        } else {
          notifications.show({
            title: 'Errore',
            message: 'La richiesta è andata in timeout!',
            color: 'red',
            icon: <CircleX size={18} />
          })
        }
        
        setIsSending(false)
      })
  }

  const linesDefinition = {
    Linea1: 'Acqua ambiente',
    Linea2: 'Acqua fredda',
    Linea3: 'Acqua gassata',
    Linea4: 'Acqua calda',
  }

  return(
    <Section title="Ricette">
      <div style={{maxWidth: '100%'}}>
        {loading && <Loading />}
        {!loading &&
          <Accordion
          variant="contained"
            styles={{
              content: {
                paddingLeft: width <= 768 ? 0 : 29
              }
            }}
            >
            {recipes.map((recipe, i) => 
              <Accordion.Item
                key={i}
                value={`line-${i}`}
                >
                <Accordion.Control>
                  <Title order={4}>Ricetta {i}</Title>
                </Accordion.Control>
                <Accordion.Panel>
                  <Group spacing={30} noWrap={width > 768}>
                    <Group
                      direction="column"
                      align="stretch"
                      >
                      <NumberInput
                      label="Quantità erogata (ml)"
                      size="sm"
                      min={0}
                      max={1500}
                      value={recipes[i].ml}
                      onChange={(valueString) => {
                        setRecipes(recipes.map((item, o) => (
                          i === o ? 
                          {
                            ...item,
                            ml: parseInt(valueString)
                          }
                          :
                          item
                          )))
                      }}
                      />
                      
                      <SimpleGrid cols={2}>
                        {recipes[i].lines.map((line, u) => {
                          let name = ''
                          switch (u) {
                            case 0:
                              name = 'Acqua ambiente'
                              break
                            case 1:
                              name = 'Acqua fredda'
                              break
                            case 2:
                              name = 'Acqua gassata'
                              break
                            case 3:
                              name = 'Acqua calda'
                              break
                            default:
                              return false
                              break
                        }
                        return(
                          <NumberInput
                            key={u}
                            size="sm"
                            label={`${name} (%)`}
                            min={0}
                            max={100}
                            value={recipes[i].lines[u].percent}
                            onChange={(valueString) => {
                              setRecipes(recipes.map((item, o) => (
                                i === o ? 
                                {
                                  ...item,
                                  lines: recipes[i].lines.map((line, p) => (p === u) ? {
                                    ...line,
                                    percent: parseInt(valueString)
                                  }
                                  :
                                  line
                                  )
                                }
                                :
                                item
                                )))
                            }}
                            sx={{ flexGrow: 1 }}
                            />
                        )})
                      }
                      
                      </SimpleGrid>
                    </Group>
                    <DonutChart
                      size={140}
                      thickness={30}
                      strokeWidth={0}
                      withTooltip={false}
                      data={recipes[i].lines.map((line, i) => ({
                        value: line.percent,
                        color: `blue.${9 - i*2}`,
                        name: `${linesDefinition[`Linea${line.key.split('Linea')[1]}`]} ${line.percent}%`
                      }))}
                      />
                  </Group>
                  <Button
                    onClick={() => handleSave(recipes[i])}
                    loading={isSending}
                    fullWidth
                    mt={30}
                    >
                    Salva la ricetta
                  </Button>
                </Accordion.Panel>
              </Accordion.Item>
            )}
          </Accordion>
        }
        <UpdateBtn date={settings.date} time={settings.time} options={{topic: 'Settings/Read', id}} set={setSettings} period={periodSettings} setPeriod={setPeriodSettings} />
      </div>
    </Section>
  )
}
export default Recipes

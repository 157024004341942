import { useState } from 'react'
import axios from 'axios'

import { useAuthContext } from '../../utils/authContext'
import { useMachineContext } from '../../utils/machineContext'
import { notifications } from '@mantine/notifications'
import Section from './Section'
import { Button, Group, Stack, Text, TextInput, Textarea, SimpleGrid, Chip, Center } from '@mantine/core'
import InterventionImages from '../InterventionImages'
import { Check, CircleX } from 'tabler-icons-react'
import { useForm } from '@mantine/form'
import dayjs from 'dayjs'

const Contact = () => {
  const { credentials } = useAuthContext()
  const { id } = useMachineContext()
  const form = useForm({
    initialValues: {
      request: {
        id: credentials.id,
        email: credentials.email,
        message: '',
        date: new Date(),
        media: []
      },
      date: null,
      machineId: id,
      status: 'Presa in carico',
      types: ['requested'],
      email: {
        admin: true
      },
      images: []
    },
    transformValues: values => ({
      ...values,
      date: dayjs().toISOString()
    }),
  })
  const [sending, setSending] = useState(false)

  return(
    <Section title="Invia una richiesta di intervento">
        <form onSubmit={form.onSubmit((values) => {
          console.log(values)
          setSending(true)
          axios.post(`/api/interventions/new`, values)
            .then(res => {
              notifications.show({
                title: res.data[0] ? 'Invio riuscito!' : 'Errore!',
                message:  res.data[0] ? 'La segnalazione è stata inviata correttamente.' : 'Si è verificato un problema, riprova più tardi.',
                color: res.data[0] ? 'green' : 'red',
                icon: res.data[0] ? <Check size={18} /> : <CircleX size={18} />
              })
              setSending(false)
            })
        })}>
          
          <Stack gap="md">
            <Text size="sm" ta="center">La Sua richiesta sarà presa in carico da Depuratori Maiba srl nel minor tempo possibile.</Text>
            <SimpleGrid cols={{base: 1, xs: 2}}>
              <TextInput
                label="Indirizzo email di riferimento"
                value={credentials.email}
                disabled={true}
                size="xs"
                />
              <TextInput
                label="Numero seriale dispositivo"
                value={id}
                disabled={true}
                size="xs"
                />
            </SimpleGrid>
            <Textarea
              label="Richiesta"
              withAsterisk
              disabled={sending}
              placeholder="Scrivi qui la tua segnalazione..."
              size="xs"
              autosize
              minRows={2}
              {...form.getInputProps('request.message')}
              />
            <InterventionImages form={form} isContact />
            <Group justify="flex-end" py="xl">
              <Chip
                size="xs"
                variant="light"
                color="red"
                checked={form.values.types.includes('urgent')}
                onChange={checked => form.setFieldValue('types', checked ? ['requested', 'urgent'] : ['requested'])}
                >
                Urgente
              </Chip>
              <Button type="submit" loading={sending}>Invia</Button>
            </Group>
          </Stack>
        </form>
    </Section>
  )
}

export default Contact

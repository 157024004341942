import { useState, useEffect } from 'react'
import axios from 'axios'

import { useMachineContext } from '../../utils/machineContext'
import Loading from '../Loading'
import Section from './Section'
import UpdateBtn from './UpdateBtn'
import {toMQTT, generateMarks} from '../../utils/func'
import { Button, Group, Tooltip, Text, Title, useMantineTheme, List, ActionIcon, Divider } from '@mantine/core'
import { useViewportSize } from '@mantine/hooks'
import { notifications } from '@mantine/notifications'
import Fields from './Fields'
import { Dropzone, DropzoneStatus, MIME_TYPES, MS_EXCEL_MIME_TYPE } from '@mantine/dropzone'
import { Upload, Photo, X, Icon as TablerIcon, Check, CircleX, File, FileUpload, FileDownload, Trash } from 'tabler-icons-react'
import dayjs from 'dayjs'

const CRS = () => {
  const { width } = useViewportSize()
  const { id, raspberry } = useMachineContext()
  const theme = useMantineTheme()

  const [loading, setLoading] = useState(true)

  const [isUploading, setIsUploading] = useState(false)

  const [ settings, setSettings ] = useState({
    date: false,
    time: false,
    payload: {
      'controlla white-list': 0,
      max_litri_prodotto_1: 0,
      max_litri_prodotto_2: 0,
      gap_giorni_max_litri: 0,
      abilita_credito: 0,
      tempo_erogazione: 0,
      abilita_penna_usb: 0,
      costo_prodotto_1: 0,
      costo_prodotto_2: 0,
      quantita_erogazione: 0,
      nome_seriale: 0,
    }
  })
  const [downloads, setDownloads] = useState([])

  useEffect(() => {
    if (raspberry === undefined) return
  
    axios.get(`/api/raspberry/${raspberry}/settings`)
      .then(res => {
        console.log('raspberry/settings', res.data)
        if (res.data) {
          setSettings(res.data)
        }
        setLoading(false)
      })
    
    axios.get(`/api/raspberry/${raspberry}/downloads`)
      .then(res => {
        console.log('raspberry/downloads', res.data)
        if (res.data) {
          setDownloads(res.data.sort((a, b) => dayjs(a.datetime).isBefore(dayjs(b.datetime)) ? 1 : -1))
        }
        setLoading(false)
      })
    
  }, [])

  const [settingsDict] = useState([
    {
      title: 'Opzioni',
      fields: [
        {
          key: 'controlla white-list',
          label: 'Controllo white-list',
          type: 'checkbox',
          hint: 'In caso sia disabilitato, qualsiasi codice fiscale è attivo.',
          property: {
            default: true,
          }
        },
        {
          key: 'max_litri_prodotto_1',
          label: 'Litri erogabili: prodotto 1',
          type: 'slider',
          hint: 'Massimo dei litri prelevabili per il prodotto 1.',
          property: {
            min: .1,
            max: 9.99,
            step: .01,
            precision: 2,
            defaultValue: 1,
          },
          unit: 'litri',
        },
        {
          key: 'max_litri_prodotto_2',
          label: 'Litri erogabili: prodotto 2',
          type: 'slider',
          hint: 'Massimo dei litri prelevabili per il prodotto 2.',
          property: {
            min: .1,
            max: 9.99,
            step: .01,
            precision: 2,
            defaultValue: 1,
          },
          unit: 'litri',
        },
        {
          key: 'gap_giorni_max_litri',
          label: 'Giorni per verifica massimali',
          type: 'slider',
          hint: 'Numero dei giorni da verificare per controllare se sono stati superati i massimali dei litri.',
          property: {
            min: 1,
            max: 32,
            step: 1,
            precision: 0,
            defaultValue: 6,
          },
          unit: 'giorni',
        },
        {
          key: 'abilita_credito',
          label: 'Abilita credito',
          type: 'text',
          hint: 'Non gestito.',
          property: {
            disabled: true,
            defaultValue: 0,
          },
        },
        {
          key: 'tempo_erogazione',
          label: 'Tempo erogazione',
          type: 'slider',
          hint: 'Valore numerico indicante i secondi di attesa tra un prelievo ed il successivo.',
          property: {
            min: 1,
            max: 10,
            step: 1,
            precision: 0,
            defaultValue: 1,
          },
          unit: 'secondi',
        },
        {
          key: 'abilita_penna_usb',
          label: 'Penna USB',
          type: 'checkbox',
          hint: 'Abilita la lettura automatica dei codici fiscali da penna usb.',
        },
        {
          key: 'costo_prodotto_1',
          label: 'Costo prodotto 1',
          type: 'select',
          hint: 'Valore decimale indicante il “costo” che Gicar comunica al raspberry per il prodotto da erogare.',
          property: {
            options: [
              { value: '0.01', label: '0.01' },
              { value: '0.99', label: 'Gratuito' },
            ]
          }
        },
        {
          key: 'costo_prodotto_2',
          label: 'Costo prodotto 2',
          type: 'select',
          hint: 'Valore decimale indicante il “costo” che Gicar comunica al raspberry per il prodotto da erogare.',
          property: {
            options: [
              { value: '0.02', label: '0.02' },
              { value: '0.99', label: 'Gratuito' },
            ]
          }
        },
        {
          key: 'quantita_erogazione',
          label: 'Quantità erogata',
          type: 'slider',
          hint: 'Valore decimale indicante la quantità da scalare per ogni prelievo eseguito dall’utente.',
          property: {
            min: .1,
            max: 1,
            step: .1,
            precision: 1,
            defaultValue: .5,
          },
          unit: 'litri',
        },
      ]
    },
    {
      title: 'Gicar',
      fields: [
        {
          key: 'nome_seriale',
          label: 'Nome porta seriale',
          type: 'select',
          hint: 'Il nome fisico della seriale che il raspberry utilizza per la comunicazione con Gicar.',
          property: {
            options: [
              { value: '/dev/ttyAMA0', label: '/dev/ttyAMA0' },
              { value: '/dev/ttyUSB0', label: '/dev/ttyUSB0' },
            ]
          }
        },
      ]
    }
  ])

  const sendToRaspberry = (filename) => {
    setIsUploading(true)
    toMQTT([
      {
        id,
        topic: `/Tosini/${raspberry}/CRS`,
        message: {
          url: `https://iot.depuratorimaiba.it/_files/${filename}`
        }
      }
    ])
    .then(resp => {
      if (resp.result === 'ok') {
        notifications.show({
          title: 'Successo',
          message: 'Il file è stato caricato!',
          color: 'green',
          icon: <Check size={18} />
        })
      } else {
        notifications.show({
          title: 'Errore',
          message: 'La richiesta non è andata a buon fine!',
          color: 'red',
          icon: <CircleX size={18} />
        })
      }
      setIsUploading(false)
    })
  }

  const downloadFile = (filename) => {
    if (process.env.NODE_ENV === 'development') return

    axios({
      url: `https://iot.depuratorimaiba.it/_files/xslx/${filename}`,
      method: 'GET',
      responseType: 'blob', // important
      auth: {
        username: 'iot-maiba',
        password: '*VgHs7AC'
      }
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
    })
  }

  const deleteFile = (filename) => {
    setIsUploading(true)
    axios.delete(`/api/raspberry/${raspberry}/${filename}`)
      .then(res => {
        console.log('raspberry/delete', res.data)
        if (res.data) {
          setDownloads(state => 
            state.filter(e => e.filename !== filename)
          )
          notifications.show({
            title: 'Successo',
            message: 'Il file è stato eliminato!',
            color: 'green',
            icon: <Check size={18} />
          })
        } else {
          notifications.show({
            title: 'Errore',
            message: 'La richiesta non è andata a buon fine!',
            color: 'red',
            icon: <CircleX size={18} />
          })
        }
        setIsUploading(false)
      })
  }

  const onDrop = (files) => {
    setIsUploading(true)
    console.log(files)
    const formData = new FormData()
    formData.append('file', files[0])
    formData.append('id', id)
    console.log(formData.get('file'))
    axios.post(`/api/raspberry/${raspberry}/upload`, formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: ProgressEvent => {
        console.log(Math.round(
            (ProgressEvent.loaded * 100) / ProgressEvent.total
          )
        )
      }
    })
    .then(res => {
      console.log('raspberry/upload', res.data)
      if (res.data) {
        setDownloads(state => [
          {
            filename: res.data.filename.replace('.json', ''),
            datetime: dayjs().format()
          },
          ...state
        ])
        sendToRaspberry(res.data.filename)
      }
    })
  }

  function getIconColor(status, theme) {
    return status.accepted
      ? theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]
      : status.rejected
      ? theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]
      : theme.colorScheme === 'dark'
      ? theme.colors.dark[0]
      : theme.colors.gray[7]
  }
  
  function ImageUploadIcon({
    status,
    ...props
  }) {
    if (status.accepted) {
      return <Upload {...props} />
    }
  
    if (status.rejected) {
      return <X {...props} />
    }
  
    return <File {...props} />
  }
  
  const dropzoneChildren = (status, theme) => (
    <Group position="center" spacing="xl" style={{ minHeight: 220, pointerEvents: 'none' }}>
      {isUploading &&
        <Loading />
      }
      {!isUploading &&
        <Group position={width < 768 ? 'center' : 'start'} align="start" noWrap={false}>
          <ImageUploadIcon status={status} style={{ color: getIconColor(status, theme) }} size={60} />
          <div>
            <Text size="xl" inline>
              Rilascia il nuovo elenco qui o clicca per selezionarlo
            </Text>
            <List size="sm" withPadding mt={7}>
              <List.Item sx={{color: '#868e96'}}>Formato Excel (.xlsx)</List.Item>
              <List.Item sx={{color: '#868e96'}}>Dimensione massima 5mb</List.Item>
              <List.Item sx={{color: '#868e96'}}>Riempi la prima colonna con i codici fiscali</List.Item>
              <List.Item sx={{color: '#868e96'}}>Non inserire intestazione</List.Item>
            </List>
          </div>
        </Group>
      }
    </Group>
  )

  return(
    <Section title="CRS">
      {loading && <Loading />}
      {raspberry === undefined && <Title order={3} align="center">Il distributore non è pronto per ricevere le impostazioni!</Title>}
      {!loading &&
        <>
          <Title order={4} align="center">Carica nuovi Codici Fiscali</Title>
          <Dropzone
            onDrop={onDrop}
            onReject={(files) => console.log('rejected files', files)}
            maxSize={3 * 1024 ** 2}
            accept={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
            multiple={false}
            disabled={isUploading}
            >
            {(status) => dropzoneChildren(status, theme)}
          </Dropzone>
          <Title order={4} align="center" mt={30}>Codici Fiscali caricati in passato</Title>
          <Group direction="column" align="center" spacing={5}>
            {downloads.length === 0 &&
              <Text align="center" color="dimmed">Non sono stati ancora caricati file per questo dispositivo.</Text>
            }
            {downloads.map((download, i) => 
              <Group position="apart" spacing={10} px={16} py={8} sx={{minWidth: '70%', backgroundColor: i % 2 !== 0 ? '#f8f9fa' : 'transparent', borderRadius: 6}}>
                <Group direction="column" spacing={0}>
                  <Text size="sm">{download.filename}</Text>
                  <Text size="xs" color="dimmed">{dayjs(download.datetime).format('DD/MM/YY HH:mm:ss')}</Text>
                </Group>
                <Group spacing="md">
                  <Tooltip
                    label="Ricarica sul dispositivo"
                    withArrow
                    size="xs"
                    >
                    <ActionIcon disabled={isUploading} color="blue" variant="light" onClick={() => sendToRaspberry(`${download.filename}.json`)}>
                      <FileUpload size={20} />
                    </ActionIcon>
                  </Tooltip>
                  <Tooltip
                    label="Scarica ora"
                    withArrow
                    size="xs"
                    >
                    <ActionIcon color="blue" variant="light" onClick={() => downloadFile(download.filename)}>
                      <FileDownload size={20} />
                    </ActionIcon>
                  </Tooltip>
                  <Tooltip
                    label="Elimina dal server"
                    withArrow
                    size="xs"
                    >
                    <ActionIcon disabled={isUploading} color="red" variant="light" onClick={() => deleteFile(download.filename)}>
                      <Trash size={20} />
                    </ActionIcon>
                  </Tooltip>
                </Group>
              </Group>
            )}
          </Group>
          <Divider my={60} />
          <Fields
            settingsDict={settingsDict}
            settings={settings}
            setSettings={setSettings}
            period={false}
            topic={`/Tosini/${raspberry}`}
            />
        </>
      }
      <UpdateBtn date={settings.date} time={settings.time} options={{topic: `/Tosini/${raspberry}/Read`, id: false}} set={setSettings} period={false} setPeriod={false} hidePeriod={true} />
    </Section>
  )
}

export default CRS
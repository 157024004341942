import { useState, useEffect } from 'react'
import axios from 'axios'
import moment from 'moment'

import { useMachineContext } from '../../utils/machineContext'
import Loading from '../Loading'
import Section from './Section'
import UpdateBtn from './UpdateBtn'
import { Card, Group, Text } from '@mantine/core'

const Firmware = () => {
  const { id } = useMachineContext()

  moment.locale('it')
  const [ loading, setLoading ] = useState(true)

  const [ infos, setInfos ] = useState([])
  const [ periodInfos, setPeriodInfos ] = useState(false)

  useEffect(() => {
    axios.get(`/api/machine/${id}/infos`)
      .then(res => {
        console.log('infos', res.data)
        if (res.data) {
          setInfos(res.data.infos)
          setPeriodInfos(res.data.period)
        } else {
          setInfos({
            date: false,
            time: false,
            payload: []
          })
          setPeriodInfos(false)
        }
        

        setLoading(false)
      })
  }, [])

  const getInfoDefinition = (code) => {
    const INFO = {
      fwType: {
        label: 'Tipo firmware',
      },
      fwVer: {
        label: 'Versione firmware',
      },
      sn: {
        label: 'Numero seriale',
      },
    }

    return INFO[code].label || code
  }

  return(
    <Section title="Firmware">
      {loading && <Loading />}
      {!loading &&
        <>
          <Group spacing={20} justify="center">
            {infos.payload.map(info => 
              <Card key={info.key} px={20} py={10} sx={{border: '1px solid #eee'}}>
                <Text color="dimmed" size="xs">{getInfoDefinition(info.key)}</Text>
                <Text>{info.value}</Text>
              </Card>
            )}
          </Group>
          <UpdateBtn date={infos.date} time={infos.time} options={{topic: 'Info/Read', id}} set={setInfos} period={periodInfos} setPeriod={setPeriodInfos} />
        </>
      }
    </Section>
  )
}

export default Firmware

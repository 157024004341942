import { createContext, useContext, useState, useEffect } from 'react'
import axios from 'axios'
import Loading from '../components/Loading'
import { user_info_label, user_was_admin_label } from './func'

const AuthContext = createContext()

const AuthProvider = ({ children }) => {
  const [isAuth, setIsAuth] = useState(false)
  const [credentials, setCredentials] = useState(false)
  const [isLoading, setLoading] = useState(true)

  const login = ( user, redirect = false ) => {
    localStorage.setItem(user_info_label, JSON.stringify(user))
    localStorage.setItem(user_was_admin_label, JSON.stringify(user.isAdmin ? true : false))

    axios.defaults.headers.common['token'] = user.token

    axios.interceptors.response.use(response => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    }, error => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      if (error.response.status === 401) {
        logout()
      }
      return Promise.reject(error);
    });

    setIsAuth(true)
    setCredentials(user)
    setLoading(false)
    if (redirect) {
      window.location.replace(redirect)
    }
  }
  const logout = () => {
    setIsAuth(false)
    setCredentials(false)
    localStorage.removeItem(user_info_label)

    axios.defaults.headers.common['token'] = null

  }

  useEffect(() => {
    try {
      if (localStorage.getItem(user_info_label)) {
        login( JSON.parse( localStorage.getItem(user_info_label) ) )
      } else {
        setLoading(false)
      }
    } catch(e) {
      console.log(e)
    }
  }, [])


  const authFulfiller = {
    isAuth,
    credentials,
    login,
    logout
  }

  return (
    <AuthContext.Provider value={authFulfiller}>
      {isLoading &&
        <Loading />
      }
      {!isLoading &&
        children
      }
    </AuthContext.Provider>
  )
}

const useAuthContext = () => {
  const context = useContext(AuthContext)

  // console.log(context);

  if (!context) {
    throw new Error('useAuthContext must be used within AuthProvider')
  }

  return context
}

export { AuthProvider, useAuthContext }

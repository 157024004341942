import { useState, useEffect } from 'react'
import axios from 'axios'

import { useMachineContext } from '../../utils/machineContext'
import Loading from '../Loading'
import Section from './Section'
import UpdateBtn from './UpdateBtn'
import {toMQTT} from '../../utils/func'
import { Group, Button, Divider, Title, Select, Text, Stack } from '@mantine/core'
import { useViewportSize } from '@mantine/hooks'
import dayjs from 'dayjs'

const Commands = () => {
  const { id } = useMachineContext()
  const { width } = useViewportSize()

  const [isSending, setIsSending] = useState(false)

  const [commands] = useState([
    {
      title: 'Accensione',
      buttons: [
        {
          label: 'Riavvia',
          color: 'yellow',
          action: () => toMQTT([{
            id,
            topic: 'Command/Write',
            message: {
              cmd: 1
            }
          }])
        },
        {
          label: 'Forza lo stato ON',
          color: 'green',
          action: () => toMQTT([{
            id,
            topic: 'Command/Write',
            message: {
              cmd: 2
            }
          }])
        },
        {
          label: 'Forza lo stato OFF',
          color: 'red',
          action: () => toMQTT([{
            id,
            topic: 'Command/Write',
            message: {
              cmd: 3
            }
          }])
        }
      ],
    },
    {
      title: 'Lavaggio',
      buttons: [
        {
          label: 'Lavaggio manuale',
          color: 'blue',
          action: () => toMQTT([{
            id,
            topic: 'Command/Write',
            message: {
              cmd: 4
            }
          }])
        },
      ],
    }
  ])

  const [datetime, setDatetime] = useState([
    dayjs().year(),
    dayjs().month() + 1,
    dayjs().date(),
    dayjs().day() === 0 ? 7 : dayjs().day(),
    dayjs().hour(),
    dayjs().minute()
  ])
  const changeDatetime = (value, index) => {
    const newDatetime = datetime
    newDatetime[index] = value
    setDatetime(newDatetime)
  }

  return(
    <Section title="Comandi">
      <Stack  gap="md" position="center" divider={<Divider />}>
        {commands.map(command =>
          <Stack gap="md" align="center">
            <Title order={4} align="center">{command.title}</Title>
            <Button.Group orientation="vertical">
              {command.buttons.map(btn => 
                <Button
                  key={btn.label}
                  disabled={isSending}
                  color={btn.color}
                  onClick={btn.action}
                  >
                  {btn.label}
                </Button>
              )}
            </Button.Group>
          </Stack>
        )}
        <Stack gap="md" align="center">
            <Title order={4} align="center">Impostazione data/ora</Title>
            <Group>
              <Select
                label="Anno"
                value={datetime[0].toString()}
                onChange={value => changeDatetime(value, 0)}
                data={Array.from(
                  { length: 10 + 1 },
                  (value, index) => dayjs().year() - 1 + index
                  ).map(n => ({
                  value: n.toString(),
                  label: n.toString()
                }))}
                size="xs"
                />
              <Select
                label="Mese"
                value={datetime[1].toString()}
                onChange={value => changeDatetime(value, 1)}
                data={[
                  { value: '1', label: 'Gennaio' },
                  { value: '2', label: 'Febbraio' },
                  { value: '3', label: 'Marzo' },
                  { value: '4', label: 'Aprile' },
                  { value: '5', label: 'Maggio' },
                  { value: '6', label: 'Giugno' },
                  { value: '7', label: 'Luglio' },
                  { value: '8', label: 'Agosto' },
                  { value: '9', label: 'Settembre' },
                  { value: '10', label: 'Ottobre' },
                  { value: '11', label: 'Novembre' },
                  { value: '12', label: 'Dicembre' },
                ]}
                size="xs"
                />
              <Select
                label="Giorno"
                value={datetime[2].toString()}
                onChange={value => changeDatetime(value, 2)}
                data={Array.from(
                  { length: 31 },
                  (value, index) => 1 + index
                  ).map(n => ({
                  value: n.toString(),
                  label: n.toString()
                }))}
                size="xs"
                />
            </Group>
            <Select
              label="Giorno della settimana"
              value={datetime[3].toString()}
              onChange={value => changeDatetime(value, 3)}
              data={[
                { value: '1', label: 'Lunedì' },
                { value: '2', label: 'Martedì' },
                { value: '3', label: 'Mercoledì' },
                { value: '4', label: 'Giovedì' },
                { value: '5', label: 'Venerdì' },
                { value: '6', label: 'Sabato' },
                { value: '7', label: 'Domenica' },
              ]}
              size="xs"
              />
              <Group>
                <Select
                  label="Ore"
                  value={datetime[4].toString()}
                  onChange={value => changeDatetime(value, 4)}
                  data={Array.from(
                    { length: 24 },
                    (value, index) => 0 + index
                    ).map(n => ({
                    value: n.toString(),
                    label: n.toString()
                  }))}
                  size="xs"
                  />
                <Select
                  label="Minuti"
                  value={datetime[5].toString()}
                  onChange={value => changeDatetime(value, 5)}
                  data={Array.from(
                    { length: 60 },
                    (value, index) => 0 + index
                    ).map(n => ({
                    value: n.toString(),
                    label: n.toString()
                  }))}
                  size="xs"
                  />
            </Group>
            <Button
              disabled={isSending}
              color="blue"
              onClick={() => toMQTT([{
                id,
                topic: 'Command/Write',
                message: {
                  cmd: 5,
                  par1: datetime[0],
                  par2: datetime[1],
                  par3: datetime[2],
                  par4: datetime[3],
                  par5: datetime[4],
                  par6: datetime[5],
                }
              }])}
              >
              Imposta
            </Button>
          </Stack>
      </Stack>
    </Section>
  )
}

export default Commands

import { useState, useEffect } from "react"
import { DataTable, useDataTableColumns } from "mantine-datatable"
import 'mantine-datatable/styles.layer.css'
import sortBy from "lodash/sortBy"
import { Stack, Text } from "@mantine/core"
import { MoodEmpty, MoodSad } from "tabler-icons-react"
import { useId } from "@mantine/hooks"

const PAGE_SIZE = 20

const Table = ({data, columns}) => {

  const [sortStatus, setSortStatus] = useState({
    columnAccessor: 'id',
    direction: 'desc',
  })
  const [page, setPage] = useState(1)
  const [rows, setRows] = useState(data.slice(0, PAGE_SIZE))

  useEffect(() => {
    const from = (page - 1) * PAGE_SIZE
    const to = from + PAGE_SIZE
    setRows(data.slice(from, to))
  }, [page])

  useEffect(() => {
    const data = sortBy(rows, sortStatus.columnAccessor)
    setRows(sortStatus.direction === 'asc' ? data.reverse() : data);
  }, [sortStatus])

  return (
    <DataTable
      withTableBorder
      borderRadius="sm"
      withColumnBorders
      striped
      highlightOnHover
      sortStatus={sortStatus}
      onSortStatusChange={setSortStatus}
      totalRecords={data.length}
      recordsPerPage={PAGE_SIZE}
      page={page}
      onPageChange={p => setPage(p)}
      paginationSize="xs"
      styles={{
        header: {
          fontSize: '85%' 
        }
      }}
      minHeight={280}
      emptyState={
        <Stack gap="sm" align="center">
          <MoodSad size={32} strokeWidth={1} />
          <Text size="sm" inline>Nessun elemento...</Text>
        </Stack>
      }
      records={rows}
      columns={columns}
      />
  )
}

export default Table
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import axios from 'axios'

import { useAuthContext } from './utils/authContext'

import Login from './views/Login'
import Admin from './views/Admin'

import {MaibaProvider} from './utils/maibaContext'

import routes from './utils/routes'

import './App.css'
import { InterventionPDFPage } from './components/InterventionPDF'
import { user_was_admin_label } from './utils/func'


const App = () => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL

  const { isAuth } = useAuthContext()

  return (
    <BrowserRouter
      basename={process.env.PUBLIC_URL}
      >
      <Routes>
        <Route
          path={`${routes.INTERVENTIONS_A}/:interventionId/pdf`}
          element={isAuth ?
            <MaibaProvider><InterventionPDFPage /></MaibaProvider>
            :
            <NavigateWithUrl to={routes.LOGIN} />
          }
          />
        <Route
          path={routes.DASHBOARD}
          element={isAuth ? 
            <MaibaProvider><Admin /></MaibaProvider>
            :
            <NavigateWithUrl to={routes.LOGIN} />
          } />
        <Route
          path={`${routes.LOGIN}/:isAdmin?`}
          element={isAuth ?
            <Navigate to={routes.DASHBOARD_A} />
            :
            <Login />
          } />
        <Route path="*" element={<Navigate to={routes.LOGIN} />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App

const NavigateWithUrl = ({to}) => {
  const user_was_admin = JSON.parse( localStorage.getItem(user_was_admin_label) )
  const url = `${to}${user_was_admin ? '/admin' : ''}?from=${window.location.href}`
  return (
    <Navigate to={url} />
  )
}
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

import { Group, PasswordInput, Switch, TextInput, Button, Modal, Text, Stack, Box } from '@mantine/core'
import Section from './machine/Section'
import { useMaibaContext } from '../utils/maibaContext'
import { useParams } from 'react-router-dom'
import Loading from './Loading'
import { notifications } from '@mantine/notifications'
import { At, EyeOff, EyeCheck, Lock, UserCircle, Check, CircleX } from 'tabler-icons-react'
import routes from '../utils/routes'
import { useForm } from '@mantine/form'

const Client = () => {
  const { users } = useMaibaContext()
  const { id } = useParams()

  const [ loading, setLoading ] = useState(true)

  useEffect(() => {
    if (users.length > 0) {
      setLoading(false)
    }
  }, [users])

  return(
    <Box p="lg">
      {loading && <Loading />}
      {!loading &&
        <User key={id} credentials={id === 'new' ?
          {
            id: 'new',
            email: '',
            nickname: '',
            canEdit: false,
            canIntervent: false,
            sendAlerts: false,
          }
          :
          {
            id: users.filter(e => e.id === id)[0].id,
            email: users.filter(e => e.id === id)[0].email,
            nickname: users.filter(e => e.id === id)[0].nickname,
            canEdit: users.filter(e => e.id === id)[0].canEdit,
            canIntervent: users.filter(e => e.id === id)[0].canIntervent,
            sendAlerts: users.filter(e => e.id === id)[0].sendAlerts
          }
        } />
      }
    </Box>
  )
}

const User = ({ credentials, func }) => {
  const { setUsers } = useMaibaContext()
  let navigate = useNavigate()

  const [ dialog, setDialog ] = useState(false)
  const [ isSaving, setIsSaving ] = useState(false)

  const form = useForm({
    initialValues: {
      email: '',
      password: '',
      nickname: '',
      canIntervent: false,
      canEdit: false,
      sendAlerts: false,
    }
  })
  useEffect(() => {
    if (credentials.id === 'new') return

    form.initialize({
      email: credentials. email,
      password: credentials.password,
      nickname: credentials.nickname,
      canIntervent: credentials.canIntervent,
      canEdit: credentials.canEdit,
      sendAlerts: credentials.sendAlerts
    })
  }, [])

  const handleDelete = (e) => {
    e.preventDefault()
    setIsSaving(true)
    axios.delete(`/api/user/${credentials.id}`)
      .then(response => {
        console.log('delete', response.data);
        setDialog(false)

        setUsers()
        setIsSaving(false)
        notifications.show({
          title: response.data[1][0],
          message: response.data[1][1],
          color: response.data[0] ? 'green' : 'red',
          icon: response.data[0] ? <Check size={18} /> : <CircleX size={18} />
        })
        navigate(routes.DASHBOARD)
      })
  }


  return (
    <Section title={credentials.id === 'new' ? 'Aggiungi nuovo utente' : credentials.nickname}>
      <form onSubmit={form.onSubmit((values) => {
        console.log(values)
        setIsSaving(true)
        axios.post(`/api/user/${credentials.id}`, values)
          .then(response => {
            console.log('edit', response.data);
            setIsSaving(false)
            notifications.show({
              title: response.data[1][0],
              message: response.data[1][1],
              color: response.data[0] ? 'green' : 'red',
              icon: response.data[0] ? <Check size={18} /> : <CircleX size={18} />
            })
            if (!response.data[0]) return
            
            setUsers()
              .then(() => {
                if (credentials.id === 'new') navigate(`${routes.USERS}/${credentials.id}`)
              })
          })
        })}>
        <Stack position="center" align="stretch" spacing={20}>
          <TextInput
            label="Email"
            placeholder="Inserisci l'indirizzo mail..."
            disabled={isSaving}
            leftSection={<At size={16} />}
            {...form.getInputProps('email')}
            />
          <PasswordInput
            label="Password"
            placeholder={credentials.id === 'new' ? "Inserisci una password..." : "Modifica la password..."}
            disabled={isSaving}
            leftSection={<Lock size={16} />}
            visibilityToggleIcon={({ reveal, size }) =>
              reveal ? <EyeOff size={16} /> : <EyeCheck size={16} />
            }
            {...form.getInputProps('password')}
            />
          <TextInput
            label="Nickname"
            placeholder="Inserisci un nickname..."
            disabled={isSaving}
            leftSection={<UserCircle size={16} />}
            {...form.getInputProps('nickname')}
            />
          <Switch
            label="Può modificare le impostazioni dei suoi dispositivi"
            disabled={isSaving}
            onLabel="ON"
            offLabel="OFF"
            size="md"
            {...form.getInputProps('canEdit', { type: 'checkbox' })}
            />
          <Switch
            label="Può creare nuovi interventi"
            disabled={isSaving}
            onLabel="ON"
            offLabel="OFF"
            size="md"
            {...form.getInputProps('canIntervent', { type: 'checkbox' })}
            />
          <Switch
            label="Riceve gli allarmi via email"
            disabled={isSaving}
            onLabel="ON"
            offLabel="OFF"
            size="md"
            {...form.getInputProps('sendAlerts', { type: 'checkbox' })}
            />
          
          <Group mt="auto" pt={20} justify="space-between">
            {credentials.id !== 'new' &&
              <>
                <Button
                  onClick={() => setDialog(true)}
                  color="red"
                  disabled={isSaving}
                  >
                  Elimina
                </Button>
                <Modal
                  opened={dialog}
                  onClose={() => setDialog(false)}
                  title="Elimina cliente"
                  closeOnClickOutside
                  closeOnEscape
                >
                  <Text align="center">{`Sei sicuro di eliminare "${form.values.nickname}"?`}</Text>
                  <Text align="center">Il cliente non potrà più accedere al gestionale.</Text>
                  <Text align="center" weight={700}>L'azione è irreversibile.</Text>
                  <Group position="apart" mt={30}>
                    <Button onClick={() => setDialog(false)} variant="outline">
                      Annulla
                    </Button>
                    <Button color="red" onClick={handleDelete}>
                      Elimina
                    </Button>
                  </Group>
                </Modal>
              </>
            }
            <Button
              type="submit"
              loading={isSaving}
              >
              Salva
            </Button>
          </Group>
        </Stack>
      </form>
    </Section>
  )
}
User.defaultProps = {
  credentials: {
    id: 'new',
    email: '',
    nickname: '',
    sendAlerts: false
  }
}

export default Client

import { useState, useEffect } from "react"
import axios from "axios"
import { Badge, Button, Text, ActionIcon, Tooltip, Group } from "@mantine/core"
import dayjs from "dayjs"
import routes from "../utils/routes"
import { Link } from "react-router-dom"
import Loading from "./Loading"
import { interventionTypes } from "../utils/func"
import Table from "./Table"
import { useMaibaContext } from "../utils/maibaContext"
import { Download, Edit, File } from "tabler-icons-react"
import { InterventionPDF } from "./InterventionPDF"
import InterventionDelete from "./InterventionDelete"
import { useAuthContext } from "../utils/authContext"

const InterventionList = ({machineId}) => {
  const { machines } = useMaibaContext()
  const { credentials } = useAuthContext()
  const [isLoading, setIsLoading] = useState(true)
  const [interventions, setInterventions] = useState([])

  useEffect(() => {
    axios.get(machineId ? `/api/machine/${machineId}/interventions` : `/api/interventions/all`)
      .then(res => {
        if (!res.data) return

        console.log('interventi', res.data)
        setInterventions(res.data.sort((a, b) => dayjs(a.date).isBefore(b.date) ? 1 : -1))
        
        setIsLoading(false)
      })
  }, [])

  return(
    <>
      {isLoading && <Loading />}
      {!isLoading &&
        <Table
          data={interventions}
          columns={[
            {
              accessor: 'interventionId',
              // this column has a custom title
              title: '#',
              render: (cells) => 
                <Badge color={cells.status && cells.status === 'Concluso' ? 'teal' : cells.status && cells.status === 'In lavorazione' ? 'yellow' : 'gray'}>{cells.interventionId}</Badge>,
              sortable: true,
            },
            {
              accessor: 'external_id',
              // this column has a custom title
              title: 'Codice esterno',
              render: ({external_id}) => 
                <Text size="xs">{external_id}</Text>,
              sortable: true,
            },
            {
              accessor: 'date',
              title: 'Data',
              render: ({ date }) => <Text size="xs">{dayjs(date).format('DD/MM/YYYY HH:mm')}</Text>,
              sortable: true,
            },
            {
              accessor: 'machineId',
              title: 'Dispositivo',
              render: ({machineId}) => machines.filter(m => m.id === machineId).length === 1 ? <Button size="xs" p={0} variant="transparent" component={Link} to={`${routes.MACHINES_A}/${machineId}/interventions`}>{machines.filter(m => m.id === machineId)[0].nickname}</Button> : <Text size="xs">Dispositivo non trovato</Text>,
              sortable: true,
              hidden: machineId
            },
            {
              accessor: 'types',
              title: 'Tipologia',
              render: ({ types, other_type, date }) => {
                console.log(date, other_type)
                return types ? interventionTypes.filter(t => t.category === 'main').map(t => types.includes(t.value) && <Badge key={t.value} variant="light" size="sm" color={t.value === 'urgent' ? 'red' : 'blue'}>{t.value === 'other_type' ? other_type : t.label}</Badge>) : ''}
            },
            {
              accessor: 'notes',
              title: 'Note',
              render: ({ notes }) => <Text size="xs">{notes}</Text>,
            },
            {
              accessor: 'commands',
              title: '',
              render: (props) => 
                <Group wrap="nowrap" gap="xs">
                <ActionIcon.Group>
                  <Tooltip label="Visualizza" withArrow>
                    <ActionIcon
                      size="md"
                      variant="outline"
                      component={Link}
                      to={machineId ? `${routes.MACHINES_A}/${machineId}/interventions/${props.interventionId}` : `${routes.INTERVENTIONS_A}/${props.interventionId}`}
                      >
                      <File style={{width: '65%', height: '65%'}} strokeWidth={2} />
                    </ActionIcon>
                  </Tooltip>
                  {(credentials.isAdmin || credentials.canIntervent) &&
                    <Tooltip label="Modifica" withArrow>
                      <ActionIcon
                        size="md"
                        variant="outline"
                        component={Link}
                        to={machineId ? `${routes.MACHINES_A}/${machineId}/interventions/${props.interventionId}/edit` : `${routes.INTERVENTIONS_A}/${props.interventionId}/edit`}
                        >
                        <Edit style={{width: '65%', height: '65%'}} strokeWidth={2} />
                      </ActionIcon>
                    </Tooltip>
                  }
                  <InterventionPDF interventionId={props.interventionId} />
                  {(credentials.isAdmin || credentials.canIntervent) &&
                    <InterventionDelete interventionId={props.interventionId} machineId={machineId} isList />
                  }
                </ActionIcon.Group>
                
                </Group>,
              sortable: false,
            }
          ]}
          />
      }
    </>
  )
}

export default InterventionList
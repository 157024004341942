
import { useMaibaContext } from '../utils/maibaContext'
import Loading from './Loading'
import { memo, useEffect, useMemo, useState, lazy } from 'react'
import axios from 'axios'
import dayjs from 'dayjs'
import it from 'dayjs/locale/it'
import { useParams } from 'react-router-dom'
import { interventionTypes, interventionStatusColor } from '../utils/func'
import { Page, View, Text as Texto, Document, StyleSheet, Image, PDFViewer, PDFDownloadLink, usePDF, pdf } from '@react-pdf/renderer'
import logo from '../assets/logo-maiba-h.png'
import QRCode from 'qrcode'
import InterventionLastReplace, { getLastReplace } from './InterventionLastReplace'
import { useViewportSize } from '@mantine/hooks'
import { Tooltip, ActionIcon, Button } from '@mantine/core'
import { Download } from 'tabler-icons-react'
import { saveAs } from 'file-saver'

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: '5mm 5mm 12mm'
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: '5mm'
  },
  logo: {
    width: '33mm',
    height: '10mm',
    objectFit: 'contain'
  },
  qr: {
    width: '14mm',
    height: '14mm',
    objectFit: 'contain',
    marginLeft: 'auto'
  },
  section: {
    flexDirection: 'row',
    gap: '4mm',
    marginTop: '4mm',
  },
  divider: {
    borderBottom: '1pt solid #000',
    margin: '4mm 0'
  },
  text1: {
    fontSize:  8,
    fontWeight: 700
  },
  text2: {
    fontSize: 7.2,
  },
  valueContainer: {
    flexGrow: 1,
    flexBasis: '100%'
  },
  label: {
    fontSize: 7,
    fontWeight: 700,
    textTransform: 'uppercase',
    marginBottom: 2
  },
  value: {
    fontSize: 9
  },
  badge: {
    borderRadius: 100,
    fontSize: 7,
    backgroundColor: 'rgba(34,139,230,.1)',
    color: '#228be6',
    padding: '1mm 2mm',
    flexGrow: 0,
    display: 'inline',
    margin: 1,
    textTransform: 'uppercase',
    fontWeight: 700
  },
  badgeRed: {
    borderRadius: 100,
    fontSize: 7,
    backgroundColor: '#fa5252',
    color: '#fff',
    padding: '1mm 2mm',
    flexGrow: 0,
    display: 'inline',
    margin: 1,
    textTransform: 'uppercase',
    fontWeight: 700
  }
})

const InterventionPDFPage = () => {
  const { interventionId } = useParams()


  return(
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100dvh', width: '100dvw'}}>
      <InterventionPDF interventionId={interventionId} buttonLarge />
    </div>
  )
}

const InterventionPDF = ({interventionId, buttonLarge = false}) => {
  const { machines, loading } = useMaibaContext()
  const [isLoading, setIsLoading] = useState({
    machine: true,
    intervention: true,
  })
  const [intervention, setIntervention] = useState(false)
  const [machine, setMachine] = useState(false)

  const initialValues = {
    machineId: false,
    date: false,
    deadline: false,
    status: false,
    types: [],
    other_type: '',
    tank_temp: '---',
    co2_bar: '---',
    co2_level: '---',
    co2_bottles: {
      '01': {
        capacity: false,
        residue: '---',
      },
      '02': {
        capacity: false,
        residue: '---',
      },
      '03': {
        capacity: false,
        residue: '---',
      },
      '04': {
        capacity: false,
        residue: '---',
      },
    },
    sanification_product: false,
    sanification_time: false,
    ph_an: false,
    ph_ag: false,
    ph_tool: false,
    filter_type: false,
    filter_capacity: false,
    sampling_microbiological: false,
    sampling_chemical: false,
    free_crs: false,
    replace: {
      uv_filter_lamp: false,
      uv_filter_lamp_date: false,
      uv_filter_lamp_expiration: false,
      uv_filter_lamp_dispenser: false,
      uv_filter_lamp_dispenser_date: false,
      uv_filter_lamp_dispenser_expiration: false,
      beccuccio: false,
      beccuccio_date: false,
      beccuccio_expiration: false,
    },
    cont_gen_m3: false,
    cont_an_m3: false,
    cont_ag_m3: false,
    parts: [],
    technicians: [],
    other_works: false,
    notes: false,
    media: [],
  }

  useEffect(() => {
    axios.get(`/api/interventions/${interventionId}`)
      .then(res => {
        if (!res.data) return
        //console.log(res.data)
        
        setIntervention({
          ...initialValues,
          ...res.data
        })

        setIsLoading(state => ({
          ...state,
          intervention: false
        }))        
      })

  }, [])
  useEffect(() => {
    if (loading.machines || isLoading.intervention) return
    setMachine(machines.filter(m => m.id === intervention.machineId)[0])
    setIsLoading(state => ({
      ...state,
      machine: false
    }))
    
  }, [loading.machines, isLoading.machine, isLoading.intervention])

  const handleDownload = async () => {
    let last_replace = {
      uv_filter_lamp: 'Caricamento informazioni...',
      uv_filter_lamp_dispenser: 'Caricamento informazioni...',
      beccuccio: 'Caricamento informazioni...'
    }
    await Promise.allSettled(Object.keys(last_replace).map(async key => {
      const phrase = await getLastReplace(interventionId, intervention.machineId, key)
      last_replace = {
        ...last_replace,
        [key]: phrase
      }
    }))

    let images = []
    if (intervention.media) {

      images = await Promise.allSettled(intervention.media.map(async (filename) => {
        const returnedObj = {
          filename,
          ext: filename.split('.').pop().toLowerCase()
        }

        if ([
          'jpg',
          'jpeg',
          'png'
          ].includes(returnedObj.ext)) {
            const res = await axios.get(`/api/interventions/image/${filename}`, {responseType: 'blob'})
            //console.log(`interventions/image/${filename}`, 'GET', res.data)
            if (!res.data) return

            returnedObj.file = new File([res.data], filename)
            returnedObj.url = URL.createObjectURL(returnedObj.file)
        }

        return returnedObj
      }))
    }
    const doc =  await pdf(
      <InterventionDoc interventionId={interventionId} intervention={intervention} machine={machine} images={images} last_replace={last_replace} />
    ).toBlob()
    saveAs(doc, `${dayjs(intervention.date).format('DD.MM.YYYY')} ${machine.nickname || machine.id}.pdf`);
  }

  if (buttonLarge) return(
    <Button
      size="xs"
      variant="outline"
      key="pdf"
      leftSection={<Download size={14} />}
      onClick={() => handleDownload()}
      disabled={loading.machines || isLoading.intervention}
      >
      Scarica PDF
    </Button>
  )
  return(
    <Tooltip label="Scarica PDF" withArrow>
      <ActionIcon
        size="md"
        variant="outline"
        onClick={() => handleDownload()}
        disabled={loading.machines || isLoading.intervention}
        >
        <Download style={{width: '65%', height: '65%'}} strokeWidth={2} />
      </ActionIcon>
    </Tooltip>
  )
}

const InterventionDoc = ({ interventionId, intervention, machine, images, last_replace }) => {
  
  return(
    <Document title={`${dayjs(intervention.date).format('YYYYMMDD')}#${interventionId} - ${machine.nickname || machine.id}`} author="Depuratori Maiba Srl" subject={`Intervento #${interventionId}`} language="it">
      <Page size="A4" style={styles.page} wrap>
        <FixedComponents interventionId={interventionId} intervention={intervention} machine={machine} />
        <View style={styles.section}>
          <Value
            label="Stato"
            value={<Texto style={{...styles.badge, color: '#fff', backgroundColor: intervention.status === 'Concluso' ? '#12b886' : intervention.status === 'In lavorazione' ? '#fab005' : '#868e96'}}>{intervention.status}</Texto>}
            />
          <Value
            label="Tipologia"
            value={interventionTypes.filter(t => t.category === 'main' && intervention.types?.includes(t.value)).map(t => <Texto key={t.value} style={t.value === 'urgent' ? styles.badgeRed : styles.badge}>{t.value === 'other_type' ? intervention.other_type : t.label}</Texto>)}
            />
        </View>
        <View style={styles.section}>
          <Value
            label="Sanificazioni effettuate"
            value={interventionTypes.filter(t => t.category === 'sanification' && intervention.types?.includes(t.value)).map(t => <Texto key={t.value} style={styles.badge}>{t.label}</Texto>)}
            />
          <Value
            label="Pulizie effettuate"
            value={interventionTypes.filter(t => t.category === 'cleaning' && intervention.types?.includes(t.value)).map(t => <Texto key={t.value} style={styles.badge}>{t.label}</Texto>)}
            />
          <Value
            label="Verifiche effettuate"
            value={interventionTypes.filter(t => t.category === 'checks' && intervention.types?.includes(t.value)).map(t => <Texto key={t.value} style={styles.badge}>{t.label}</Texto>)}
            />
        </View>
        <View style={styles.section}>
          <Value label="Temperatura vasca" value={intervention.tank_temp ? `${typeof intervention.tank_temp === 'number' ? intervention.tank_temp.toFixed(1) : intervention.tank_temp}°` : false} />
          <Value label="Pressione CO2" value={intervention.co2_bar ? `${intervention.co2_bar} bar` : false} />
          <Value label="Livello CO2" value={intervention.co2_level ? `${intervention.co2_level}%` : false} />
        </View>
        <View style={styles.section}>
          {['01', '02', '03', '04'].map(n =>
            <View key={n} style={{border: '1pt solid #000', borderRadius: 4, padding: 4, flexDirection: 'row', flexWrap: 'wrap', gap: 4}}>
              <Value label={`Bombola ${n} presente`} value={intervention.co2_bottles && intervention.co2_bottles[n].is_present ? 'Sì' : false} />
              <Value label={`Capienza bombola ${n}`} value={intervention.co2_bottles && intervention.co2_bottles[n].capacity ? intervention.co2_bottles[n].capacity : false} />
              <Value label={`Residuo bombola ${n}`} value={intervention.co2_bottles && intervention.co2_bottles[n].residue ? `${intervention.co2_bottles[n].residue}%` : false} />
            </View>  
          )}
        </View>
        <View style={styles.section}>
          <Value label="Prodotto sanificante" value={intervention.sanification_product} />
          <Value label="Tempo di contatto e % sol." value={intervention.sanification_time} />
        </View>
        <View style={styles.section}>
          <Value label="Tipologia filtro installato" value={intervention.filter_type} />
          <Value label="Capacità" value={intervention.filter_capacity} />
        </View>
        <View style={styles.section}>
          <View style={{flexDirection: 'row', flexWrap: 'wrap', gap: 4}}>
            <Value label="Strumento tar. PH" value={intervention.ph_tool} />
            <Value label={<>Cont. gen. m<sup>3</sup></>} value={intervention.cont_gen_m3} />
          </View>
          <View style={{flexDirection: 'row', flexWrap: 'wrap', gap: 4}}>
            <Value label="Valore PH AN (da 6,5 a 9,5)" value={intervention.ph_an} />
            <Value label={<>Cont. AN m<sup>3</sup></>} value={intervention.cont_an_m3} />
          </View>
          <View style={{flexDirection: 'row', flexWrap: 'wrap', gap: 4}}>
            <Value label="Valore PH AG (da 4,5 a 9,5)" value={intervention.ph_ag} />
            <Value label={<>Cont. AG m<sup>3</sup></>} value={intervention.cont_ag_m3} />
          </View>
        </View>
        <View style={styles.section}>
          <Value label="Campionamento microbiologico" value={intervention.sampling_microbiological ? 'Sì' : 'No'} />
          <Value label="Campionamento chimico" value={intervention.sampling_chemical ? 'Sì' : 'No'} />
          <Value label="CRS in libera" value={intervention.free_crs ? 'Sì' : false} />
        </View>
        <View style={styles.section}>
          {[
            {
              label_checkbox: 'Sostituzione lamp. filtro UV',
              key: 'uv_filter_lamp'
            },
            {
              label_checkbox: 'Sostituzione lamp. filtro UV erogatori',
              key: 'uv_filter_lamp_dispenser'
            },
            {
              label_checkbox: 'Inst.ne punto di campionamento ingresso',
              key: 'beccuccio'
            }
          ].map(fi => 
            <View key={fi.key} style={{border: '1pt solid #000', borderRadius: 4, padding: 4, flexDirection: 'row', flexWrap: 'wrap', gap: 4, flexBasis: '100%'}}>
              <Value label={fi.label_checkbox} value={intervention.replace && intervention.replace[fi.key] ? 'Sì' : 'No'} />
              <Value label="Data sostituzione" value={dayjs(intervention.replace[`${fi.key}_date`]).isValid() ? dayjs(intervention.replace[`${fi.key}_date`]).locale(it).format('DD/MM/YYYY') : false} />
              <Value label="Data scadenza" value={dayjs(intervention.replace[`${fi.key}_expiration`]).isValid() ? dayjs(intervention.replace[`${fi.key}_expiration`]).locale(it).format('DD/MM/YYYY') : false} />
              <Texto style={{marginTop: '1mm', fontStyle: 'italic', fontSize: '3mm'}}>{last_replace[fi.key]}</Texto>
            </View>
          )}
        </View>
        <View style={styles.section}>
          <Value
            label="Ricambi utilizzati"
            value={<View style={{flexDirection: 'column'}}>
              {intervention.parts.map(part => <Texto key={part.value} style={styles.value}>{part.amount}x {part.value}</Texto>)}
              </View>}
            />
        </View>
        <View style={styles.section}>
          <Value
            label="Tecnici"
            value={intervention.technicians.join(', ')}
            />
          <Value
            label="Uscite"
            value={intervention.technicians_exits}
            />
          <Value
            label="Ore"
            value={intervention.technicians_hours}
            />
        </View>
        <View style={styles.section}>
          
          <Value
            label="Altri lavori eseguiti"
            value={intervention.other_works}
            />
        </View>
        <View style={styles.section}>
          <Value
            label="Note"
            value={intervention.notes}
            />
        </View>
        {intervention.request &&
          <View style={styles.section}>
            <Value
              label={intervention.request ? `Richiesta del ${dayjs(intervention.request.date).locale(it).format('D MMMM YYYY')} ${dayjs(intervention.request.date).format('hh:mm')} da ${intervention.request.email}` : 'Nessuna richiesta dal cliente'}
              value={intervention.request ? intervention.request.message : ''}
              />
          </View>
        }
      </Page>
      {images && images.length > 0 &&
        <Page size="A4" style={styles.page} wrap>
          <FixedComponents interventionId={interventionId} intervention={intervention} machine={machine} />
          <View style={{...styles.section, flexDirection: 'column'}}>
            <Texto fixed style={{...styles.label, marginTop: '5mm'}}>Media</Texto>
            <View style={{flexDirection: 'row', flexWrap: 'wrap', gap: '5mm', alignItems: 'center' }}>
              {images.map(imgP => {
                const img = imgP.value
                console.log(img)
                if ([
                  'jpg',
                  'jpeg',
                  'png'
                  ].includes(img.ext)) {
                    return(
                      <Image key={img.name} style={{
                        width: '97.5mm',
                        height: '97.5mm',
                        flexGrow: 0,
                        flexShrink: 0,
                        borderRadius: 2,
                        margin: 0,
                        padding: 0,
                        objectFit: 'contain',
                        objectPosition: 'center',
                        border: '1px solid #eee',
                      }}
                      src={img.url}
                      cache={false}
                      alt={img.filename}
                      />
                    )
                } else {
                  return(
                    <View key={img.name} style={{
                      width: '97.5mm',
                      height: '97.5mm',
                      flexGrow: 0,
                      flexShrink: 0,
                      borderRadius: 2,
                      margin: 0,
                      padding: '5mm',
                      border: '1px solid #eee',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '4mm'
                      }}>
                      <Texto style={{fontSize: '3mm', textAlign: 'center'}}>{img.filename}</Texto>
                      <Texto style={{fontSize: '2mm', textAlign: 'center'}}>MATERIALE DISPONIBILE ONLINE</Texto>
                    </View>
                  )
                }
              })}
            </View>
          </View>
        </Page>
      }
    </Document>
  )
}

const FixedComponents = ({interventionId, intervention, machine}) => {
  
  const qrCode = useMemo(async () => await QRCode.toDataURL(`https://iot.depuratorimaiba.it/dashboard/machines/${intervention.machineId}/interventions/${interventionId}`), [interventionId, intervention.machineId])

  return(
  <>
    <View fixed>
      <View style={styles.header}>
        <Image src={logo} style={styles.logo} />
        <View>
          <Texto style={styles.text1}>DEPURATORI MAIBA srl - P.IVA 0239445067</Texto>
          <Texto style={styles.text2}>Sede Chieti - Via Filippo Masci snc - 66100 Chieti CH</Texto>
          <Texto style={styles.text2}>Sede Milano - Via Marzabotto, 55 - 20037 Paderno Dugnano MI</Texto>
          <Texto style={styles.text2}>info@depuratorimaiba.it - maibasrl@pec.it</Texto>
        </View>
        <Image src={qrCode} style={styles.qr} />
      </View>
      <View style={styles.divider}></View>
      <View style={{...styles.section, justifyContent: 'space-between', marginTop: 0}}>
        <View style={{maxWidth: '70%'}}>
          <Texto style={{fontSize: 16}}>{machine.nickname ? machine.nickname : machine.id}</Texto>
          {machine.nickname !== undefined && <Texto style={{fontSize: 12, marginTop: 2}}>{machine.id}</Texto>}
          {machine.address && <Texto style={{fontSize: 12, marginTop: 2}}>{machine.address.label}</Texto>}
        </View>
        <View style={{alignItems: 'flex-end', maxWidth: '30%'}}>
          <Texto style={{fontSize: 16}}>Intervento #{interventionId}</Texto>
          <Texto style={{fontSize: 12, marginTop: 2}}>Eseguito il {dayjs(intervention.date).format('DD/MM/YYYY hh:mm')}</Texto>
          {intervention.status != 'Concluso' && intervention.deadline &&
            <Texto style={{fontSize: 12, marginTop: 2}}>Termine lavori il {dayjs(intervention.deadline).format('DD/MM/YYYY hh:mm')}</Texto>
          }
        </View>
      </View>
      <View style={styles.divider}></View>
    </View>
    <Texto style={{fontSize: 8, position: 'absolute', bottom: 10, textAlign: 'center', width: '100%'}} render={({ pageNumber, totalPages }) => (
      `Pagina ${pageNumber} di ${totalPages}`
    )} fixed />
  </>
  )
}

const Value = ({label, value}) => {
  if (!value || value.length === 0) value = '---'
  return(
    <View style={styles.valueContainer}>
      <Texto style={styles.label}>{label}</Texto>
      {typeof value != 'object' ?
        <Texto style={styles.value}>{value}</Texto>
        :
        <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>{value}</View>
      }
    </View>
  )
}

export { InterventionPDF, InterventionPDFPage }

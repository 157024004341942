import { useState, useEffect } from 'react'
import axios from 'axios'
import moment from 'moment'

import { useMachineContext } from '../../utils/machineContext'
import Section from './Section'
import Loading from '../Loading'
import UpdateBtn from './UpdateBtn'
import Fields from './Fields'

const Settings = () => {
  const { id } = useMachineContext()

  moment.locale('it')

  const [loading, setLoading] = useState(true)
  const [settings, setSettings] = useState(false)
  const [periodSettings, setPeriodSettings] = useState(false)

  const [settingsDict] = useState([
    {
      title: 'Pressione acqua',
      fields: [
        {
          key: 'H2OPressMin',
          label: 'Acqua: pressione minima',
          type: 'select',
          property: {
            options: [
              { value: 0, label: '0' },
              { value: 5, label: '0,5' },
              { value: 10, label: '1' },
              { value: 15, label: '1,5' },
              { value: 20, label: '2' },
              { value: 25, label: '2,5' },
              { value: 30, label: '3' },
              { value: 35, label: '3,5' },
              { value: 40, label: '4' },
              { value: 45, label: '4,5' },
              { value: 50, label: '5' },
              { value: 55, label: '5,5' },
              { value: 60, label: '6' },
              { value: 65, label: '6,5' },
              { value: 70, label: '7' },
              { value: 75, label: '7,5' },
              { value: 80, label: '8' },
              { value: 85, label: '8,5' },
              { value: 90, label: '9' },
              { value: 95, label: '9,5' },
              { value: 100, label: '10' }
            ]
          },
          unit: 'bar',
        },
      ]
    },
    {
      title: 'Carbonatore',
      fields: [
        {
          key: 'CarbonatoreAb',
          label: 'Carbonatore',
          type: 'checkbox',
        },
        {
          key: 'CarbonatoreTORiempimento',
          label: 'Carbonatore: timeout riempimento',
          type: 'slider',
          property: {
            min: 0,
            max: 60,
            step: 1,
            defaultValue: 10,
          },
          unit: 'min',
        },
      ]
    },
    {
      title: 'Pressione CO2',
      fields: [
        {
          key: 'CO2PressMin',
          label: 'CO2: pressione minima',
          type: 'select',
          property: {
            options: [
              { value: 0, label: '0' },
              { value: 5, label: '0,5' },
              { value: 10, label: '1' },
              { value: 15, label: '1,5' },
              { value: 20, label: '2' },
              { value: 25, label: '2,5' },
              { value: 30, label: '3' },
              { value: 35, label: '3,5' },
              { value: 40, label: '4' },
              { value: 45, label: '4,5' },
              { value: 50, label: '5' },
              { value: 55, label: '5,5' },
              { value: 60, label: '6' },
              { value: 65, label: '6,5' },
              { value: 70, label: '7' },
              { value: 75, label: '7,5' },
              { value: 80, label: '8' },
              { value: 85, label: '8,5' },
              { value: 90, label: '9' },
              { value: 95, label: '9,5' },
              { value: 100, label: '10' }
            ]
          },
          unit: 'bar',
        },
        {
          key: 'CO2MassaMin',
          label: 'CO2: massa minima',
          type: 'slider',
          property: {
            min: 0,
            max: 400,
            step: 1,
            defaultValue: 100,
            precision: 0,
          },
          unit: 'kg',
        },
        {
          key: 'CO2MassaTara',
          label: 'CO2: tara',
          type: 'slider',
          property: {
            min: 0,
            max: 400,
            step: 1,
            defaultValue: 0,
            precision: 0,
          },
          unit: 'kg',
        },
      ]
    },
    {
      title: 'Autoflushing',
      fields: [
        {
          key: 'AutoflushingAb',
          label: 'Autoflushing',
          type: 'checkbox',
        },
        {
          key: 'AutoflushingDurata',
          label: 'Autoflushing: durata',
          type: 'slider',
          property: {
            min: 0,
            max: 300,
            step: 1,
            defaultValue: 20,
            precision: 0,
          },
          unit: 's',
        },
        {
          key: 'AutoflushingFreq',
          label: 'Autoflushing: frequenza',
          type: 'slider',
          property: {
            min: 0,
            max: 360,
            step: 1,
            defaultValue: 20,
            precision: 0,
          },
          unit: 'min',
        },
      ]
    },
    {
      title: 'Ozonizzatore',
      fields: [
        {
          key: 'OzonizzatoreDurata',
          label: 'Ozonizzatore: durata',
          type: 'slider',
          property: {
            min: 0,
            max: 300,
            step: 1,
            precision: 0,
          },
          unit: 's',
        },
        {
          key: 'OzonizzatoreFreq',
          label: 'Ozonizzatore: frequenza',
          type: 'slider',
          property: {
            min: 0,
            max: 300,
            step: 1,
            precision: 0,
          },
          unit: 'min',
        },
      ]
    },
    {
      title: 'Lavaggio automatico',
      fields: [
        {
          key: 'LavaggioAutAb',
          label: 'Lavaggio Automatico',
          type: 'checkbox',
        },
        {
          key: 'LavaggioAutOra',
          label: 'Lavaggio Automatico: ore',
          type: 'select',
          property: {
            options: [
              { value: 0, label: '00' },
              { value: 1, label: '01' },
              { value: 2, label: '02' },
              { value: 3, label: '03' },
              { value: 4, label: '04' },
              { value: 5, label: '05' },
              { value: 6, label: '06' },
              { value: 7, label: '07' },
              { value: 8, label: '08' },
              { value: 9, label: '09' },
              { value: 10, label: '10' },
              { value: 11, label: '11' },
              { value: 12, label: '12' },
              { value: 13, label: '13' },
              { value: 14, label: '14' },
              { value: 15, label: '15' },
              { value: 16, label: '16' },
              { value: 17, label: '17' },
              { value: 18, label: '18' },
              { value: 19, label: '19' },
              { value: 20, label: '20' },
              { value: 21, label: '21' },
              { value: 22, label: '22' },
              { value: 23, label: '23' },
            ]
          },
        },
        {
          key: 'LavaggioAutMin',
          label: 'Lavaggio Automatico: minuti',
          type: 'select',
          property: {
            options: [
              { value: 0, label: '00' },
              { value: 1, label: '01' },
              { value: 2, label: '02' },
              { value: 3, label: '03' },
              { value: 4, label: '04' },
              { value: 5, label: '05' },
              { value: 6, label: '06' },
              { value: 7, label: '07' },
              { value: 8, label: '08' },
              { value: 9, label: '09' },
              { value: 10, label: '10' },
              { value: 11, label: '11' },
              { value: 12, label: '12' },
              { value: 13, label: '13' },
              { value: 14, label: '14' },
              { value: 15, label: '15' },
              { value: 16, label: '16' },
              { value: 17, label: '17' },
              { value: 18, label: '18' },
              { value: 19, label: '19' },
              { value: 20, label: '20' },
              { value: 21, label: '21' },
              { value: 22, label: '22' },
              { value: 23, label: '23' },
              { value: 24, label: '24' },
              { value: 25, label: '25' },
              { value: 26, label: '26' },
              { value: 27, label: '27' },
              { value: 28, label: '28' },
              { value: 29, label: '29' },
              { value: 30, label: '30' },
              { value: 31, label: '31' },
              { value: 32, label: '32' },
              { value: 33, label: '33' },
              { value: 34, label: '34' },
              { value: 35, label: '35' },
              { value: 36, label: '36' },
              { value: 37, label: '37' },
              { value: 38, label: '38' },
              { value: 39, label: '39' },
              { value: 40, label: '40' },
              { value: 41, label: '41' },
              { value: 42, label: '42' },
              { value: 43, label: '43' },
              { value: 44, label: '44' },
              { value: 45, label: '45' },
              { value: 46, label: '46' },
              { value: 47, label: '47' },
              { value: 48, label: '48' },
              { value: 49, label: '49' },
              { value: 50, label: '50' },
              { value: 51, label: '51' },
              { value: 52, label: '52' },
              { value: 53, label: '53' },
              { value: 54, label: '54' },
              { value: 55, label: '55' },
              { value: 56, label: '56' },
              { value: 57, label: '57' },
              { value: 58, label: '58' },
              { value: 59, label: '59' },
            ]
          },
        },
        {
          key: 'LavaggioManAb',
          label: 'Lavaggio Manuale',
          type: 'checkbox',
        },
        {
          key: 'LavaggioDurata',
          label: 'Lavaggio: durata risciacquo',
          type: 'slider',
          property: {
            min: 0,
            max: 99,
            step: 1,
            precision: 0,
          },
          unit: 'min',
        },
        {
          key: 'LavaggioDurataPompa',
          label: 'Lavaggio: durata lavaggio',
          type: 'slider',
          property: {
            min: 0,
            max: 99,
            step: 1,
            precision: 0,
          },
          unit: 'min',
        },
        {
          key: 'LavaggioDurataPausa',
          label: 'Lavaggio: durata pausa',
          type: 'slider',
          property: {
            min: 0,
            max: 99,
            step: 1,
            precision: 0,
          },
          unit: 'min',
        },
        {
          key: 'LavaggioLinea1Ab',
          label: 'Lavaggio: linea 1',
          type: 'checkbox',
        },
        {
          key: 'LavaggioLinea2Ab',
          label: 'Lavaggio: linea 2',
          type: 'checkbox',
        },
        {
          key: 'LavaggioLinea3Ab',
          label: 'Lavaggio: linea 3',
          type: 'checkbox',
        },
        {
          key: 'LavaggioLinea4Ab',
          label: 'Lavaggio: linea 4',
          type: 'checkbox',
        },
      ]
    },
    {
      title: 'Frigo',
      fields: [
        {
          key: 'FrigoAb',
          label: 'Frigo',
          type: 'checkbox',
        },
        {
          key: 'FrigoModalita',
          label: 'Frigo: modalità',
          type: 'select',
          property: {
            options: [
              { value: 0, label: 'Inverno' },
              { value: 1, label: 'Estate' },
            ]
          }
        },
        {
          key: 'FrigoTempSet',
          label: 'Frigo: temperatura set',
          type: 'select',
          property: {
            options: [
              { value: 1000, label: '0° C' },
              { value: 1010, label: '1° C' },
              { value: 1020, label: '2° C' },
              { value: 1030, label: '3° C' },
              { value: 1040, label: '4° C' },
              { value: 1050, label: '5° C' },
              { value: 1060, label: '6° C' },
              { value: 1070, label: '7° C' },
              { value: 1080, label: '8° C' },
              { value: 1090, label: '9° C' },
              { value: 1100, label: '10° C' },
            ]
          }
        },
        {
          key: 'FrigoIstTempSet',
          label: 'Frigo: temperatura isteresi',
          type: 'select',
          property: {
            options: [
              { value: 0, label: '0° C' },
              { value: 1, label: '1° C' },
              { value: 2, label: '2° C' },
              { value: 3, label: '3° C' },
              { value: 4, label: '4° C' },
              { value: 5, label: '5° C' },
              { value: 6, label: '6° C' },
              { value: 7, label: '7° C' },
              { value: 8, label: '8° C' },
              { value: 9, label: '9° C' },
              { value: 10, label: '10° C' },
            ]
          },
        },
        {
          key: 'FrigoTOOffMin',
          label: 'Frigo: timeout off min.',
          type: 'slider',
          property: {
            min: 0,
            max: 60,
            step: 1,
          },
          unit: 'min',
        },
        {
          key: 'FrigoTORaffreddamento',
          label: 'Frigo: timeout raffreddamento',
          type: 'slider',
          property: {
            min: 0,
            max: 60,
            step: 1,
            precision: 0,
          },
          unit: 'min',
        },
      ]
    },
    {
      title: 'Compressore',
      fields: [
        {
          key: 'CompressorePressMinAb',
          label: 'Compressore: allarme press. min.',
          type: 'checkbox',
        },
        {
          key: 'CompressorePressMin',
          label: 'Compressore: press. min.',
          type: 'select',
          property: {
            options: [
              { value: 1000, label: '0' },
              { value: 1005, label: '0,5' },
              { value: 1010, label: '1' },
              { value: 1015, label: '1,5' },
              { value: 1020, label: '2' },
              { value: 1025, label: '2,5' },
              { value: 1030, label: '3' },
              { value: 1035, label: '3,5' },
              { value: 1040, label: '4' },
              { value: 1045, label: '4,5' },
              { value: 1050, label: '5' },
              { value: 1055, label: '5,5' },
              { value: 1060, label: '6' },
              { value: 1065, label: '6,5' },
              { value: 1070, label: '7' },
              { value: 1075, label: '7,5' },
              { value: 1080, label: '8' },
              { value: 1085, label: '8,5' },
              { value: 1090, label: '9' },
              { value: 1095, label: '9,5' },
              { value: 1100, label: '10' }
            ]
          },
          unit: 'bar',
        },
        {
          key: 'CompressorePressMaxAb',
          label: 'Compressore: allarme press. max.',
          type: 'checkbox',
        },
        {
          key: 'CompressorePressMax',
          label: 'Compressore: press. max.',
          type: 'select',
          property: {
            options: [
              { value: 1000, label: '0' },
              { value: 1005, label: '0,5' },
              { value: 1010, label: '1' },
              { value: 1015, label: '1,5' },
              { value: 1020, label: '2' },
              { value: 1025, label: '2,5' },
              { value: 1030, label: '3' },
              { value: 1035, label: '3,5' },
              { value: 1040, label: '4' },
              { value: 1045, label: '4,5' },
              { value: 1050, label: '5' },
              { value: 1055, label: '5,5' },
              { value: 1060, label: '6' },
              { value: 1065, label: '6,5' },
              { value: 1070, label: '7' },
              { value: 1075, label: '7,5' },
              { value: 1080, label: '8' },
              { value: 1085, label: '8,5' },
              { value: 1090, label: '9' },
              { value: 1095, label: '9,5' },
              { value: 1100, label: '10' },
              { value: 1105, label: '10,5' },
              { value: 1110, label: '11' },
              { value: 1115, label: '11,5' },
              { value: 1120, label: '12' },
              { value: 1125, label: '12,5' },
              { value: 1130, label: '13' },
              { value: 1135, label: '13,5' },
              { value: 1140, label: '14' },
              { value: 1145, label: '14,5' },
              { value: 1150, label: '15' },
              { value: 1155, label: '15,5' },
              { value: 1160, label: '16' },
              { value: 1165, label: '16,5' },
              { value: 1170, label: '17' },
              { value: 1175, label: '17,5' },
              { value: 1180, label: '18' },
              { value: 1185, label: '18,5' },
              { value: 1190, label: '19' },
              { value: 1195, label: '19,5' },
              { value: 1200, label: '20' },
              { value: 1205, label: '20,5' },
              { value: 1210, label: '21' },
              { value: 1215, label: '21,5' },
              { value: 1220, label: '22' },
              { value: 1225, label: '22,5' },
              { value: 1230, label: '23' },
              { value: 1235, label: '23,5' },
              { value: 1240, label: '24' },
              { value: 1245, label: '24,5' },
              { value: 1250, label: '25' },
              { value: 1255, label: '25,5' },
              { value: 1260, label: '26' },
              { value: 1265, label: '26,5' },
              { value: 1270, label: '27' },
              { value: 1275, label: '27,5' },
              { value: 1280, label: '28' },
              { value: 1285, label: '28,5' },
              { value: 1290, label: '29' },
              { value: 1295, label: '29,5' },
              { value: 1300, label: '30' },
              { value: 1305, label: '30,5' },
              { value: 1310, label: '31' },
              { value: 1315, label: '31,5' },
              { value: 1320, label: '32' },
              { value: 1325, label: '32,5' },
              { value: 1330, label: '33' },
              { value: 1335, label: '33,5' },
              { value: 1340, label: '34' },
              { value: 1345, label: '34,5' },
              { value: 1350, label: '35' },
              { value: 1355, label: '35,5' },
              { value: 1360, label: '36' },
              { value: 1365, label: '36,5' },
              { value: 1370, label: '37' },
              { value: 1375, label: '37,5' },
              { value: 1380, label: '38' },
              { value: 1385, label: '38,5' },
              { value: 1390, label: '39' },
              { value: 1395, label: '39,5' },
              { value: 1400, label: '40' },
            ]
          },
          unit: 'bar',
        },
      ]
    },
    {
      title: 'Sensori',
      fields: [
        {
          key: 'SensorePresenzaAb',
          label: 'Sensore Presenza',
          type: 'checkbox',
        },
        {
          key: 'SensorePortaAb',
          label: 'Sensore Porta',
          type: 'checkbox'
        },
        {
          key: 'SensoreLampadaUV1Ab',
          label: 'Sensore Lampada UV 1 (filtro)',
          type: 'checkbox'
        },
        {
          key: 'SensoreLampadaUV2Ab',
          label: 'Sensore Lampada UV 2 (erogatore)',
          type: 'checkbox'
        }
      ]
    },
    {
      title: 'Impulsi',
      fields: [
        {
          key: 'cv1Imp',
          label: 'Impulsi/Litro CV Linea 1',
          type: 'slider',
          property: {
            min: 1,
            max: 999,
            step: 1,
            precision: 0,
          },
          unit: '',
        },
        {
          key: 'cv2Imp',
          label: 'Impulsi/Litro CV Linea 2',
          type: 'slider',
          property: {
            min: 1,
            max: 999,
            step: 1,
            precision: 0,
          },
          unit: '',
        },
        {
          key: 'cv3Imp',
          label: 'Impulsi/Litro CV Linea 3',
          type: 'slider',
          property: {
            min: 1,
            max: 999,
            step: 1,
            precision: 0,
          },
          unit: '',
        },
        {
          key: 'cv4Imp',
          label: 'Impulsi/Litro CV Linea 4',
          type: 'slider',
          property: {
            min: 1,
            max: 999,
            step: 1,
            precision: 0,
          },
          unit: '',
        },
      ]
    },
    {
      title: 'Sistema di pagamento',
      fields: [
        {
          key: 'SistemaPagamentoAb',
          label: 'Sistema di pagamento',
          type: 'checkbox',
        },
        {
          key: 'SistemaPagamentoTipoProtocollo',
          label: 'Tipo protocollo',
          type: 'select',
          property: {
            options: [
              { value: 0, label: 'CRS' },
              { value: 1, label: 'Executive' },
            ]
          },
          unit: '',
        },
        {
          key: 'SistemaPagamentoGestionePrezzi',
          label: 'Gestione prezzi',
          type: 'select',
          property: {
            options: [
              { value: 0, label: 'In macchina' },
              { value: 1, label: 'Price holding' },
            ]
          },
          unit: '',
        },
        {
          key: 'SistemaPagamentoDecPrezzo',
          label: 'Decimali prezzo',
          type: 'slider',
          property: {
            min: 1,
            max: 3,
            step: 1,
            precision: 0,
          },
          unit: '',
        },
        {
          key: 'SistemaPagamentoPrezzoProdotto1Dose1',
          label: 'Prezzo prodotto 1: dose 1',
          type: 'slider',
          property: {
            min: 1,
            max: 65500,
            step: 5,
            precision: 0,
          },
          unit: '€',
        },
        {
          key: 'SistemaPagamentoPrezzoProdotto1Dose2',
          label: 'Prezzo prodotto 1: dose 2',
          type: 'slider',
          property: {
            min: 1,
            max: 65500,
            step: 5,
            precision: 0,
          },
          unit: '€',
        },
        {
          key: 'SistemaPagamentoPrezzoProdotto2Dose1',
          label: 'Prezzo prodotto 2: dose 1',
          type: 'slider',
          property: {
            min: 1,
            max: 65500,
            step: 5,
            precision: 0,
          },
          unit: '€',
        },
        {
          key: 'SistemaPagamentoPrezzoProdotto2Dose2',
          label: 'Prezzo prodotto 2: dose 2',
          type: 'slider',
          property: {
            min: 1,
            max: 65500,
            step: 5,
            precision: 0,
          },
          unit: '€',
        },
        {
          key: 'SistemaPagamentoPrezzoProdotto3Dose1',
          label: 'Prezzo prodotto 3: dose 1',
          type: 'slider',
          property: {
            min: 1,
            max: 65500,
            step: 5,
            precision: 0,
          },
          unit: '€',
        },
        {
          key: 'SistemaPagamentoPrezzoProdotto3Dose2',
          label: 'Prezzo prodotto 3: dose 2',
          type: 'slider',
          property: {
            min: 1,
            max: 65500,
            step: 5,
            precision: 0,
          },
          unit: '€',
        },
        {
          key: 'SistemaPagamentoPrezzoProdotto4Dose1',
          label: 'Prezzo prodotto 4: dose 1',
          type: 'slider',
          property: {
            min: 1,
            max: 65500,
            step: 5,
            precision: 0,
          },
          unit: '€',
        },
        {
          key: 'SistemaPagamentoPrezzoProdotto4Dose2',
          label: 'Prezzo prodotto 1: dose 1',
          type: 'slider',
          property: {
            min: 1,
            max: 65500,
            step: 5,
            precision: 0,
          },
          unit: '€',
        },
        {
          key: 'SistemaPagamentoFattoreScalaProdotto1',
          label: 'Fattore scala prodotto 1',
          type: 'slider',
          property: {
            min: 1,
            max: 65500,
            step: 5,
            precision: 0,
          },
          unit: '€',
        },
        {
          key: 'SistemaPagamentoFattoreScalaProdotto2',
          label: 'Fattore scala prodotto 2',
          type: 'slider',
          property: {
            min: 1,
            max: 65500,
            step: 5,
            precision: 0,
          },
          unit: '€',
        },
      ]
    },
    {
      title: 'Accensione/Spegnimento automatico',
      fields: [
        {
          key: 'AccensioneAutomaticaOra',
          label: 'Accensione automatica: ore',
          type: 'select',
          property: {
            options: [
              { value: 0, label: '00' },
              { value: 1, label: '01' },
              { value: 2, label: '02' },
              { value: 3, label: '03' },
              { value: 4, label: '04' },
              { value: 5, label: '05' },
              { value: 6, label: '06' },
              { value: 7, label: '07' },
              { value: 8, label: '08' },
              { value: 9, label: '09' },
              { value: 10, label: '10' },
              { value: 11, label: '11' },
              { value: 12, label: '12' },
              { value: 13, label: '13' },
              { value: 14, label: '14' },
              { value: 15, label: '15' },
              { value: 16, label: '16' },
              { value: 17, label: '17' },
              { value: 18, label: '18' },
              { value: 19, label: '19' },
              { value: 20, label: '20' },
              { value: 21, label: '21' },
              { value: 22, label: '22' },
              { value: 23, label: '23' },
              { value: 100, label: 'Disabilitato'}
            ]
          },
        },
        {
          key: 'AccensioneAutomaticaMinuti',
          label: 'Accensione automatica: minuti',
          type: 'select',
          property: {
            options: [
              { value: 0, label: '00' },
              { value: 1, label: '01' },
              { value: 2, label: '02' },
              { value: 3, label: '03' },
              { value: 4, label: '04' },
              { value: 5, label: '05' },
              { value: 6, label: '06' },
              { value: 7, label: '07' },
              { value: 8, label: '08' },
              { value: 9, label: '09' },
              { value: 10, label: '10' },
              { value: 11, label: '11' },
              { value: 12, label: '12' },
              { value: 13, label: '13' },
              { value: 14, label: '14' },
              { value: 15, label: '15' },
              { value: 16, label: '16' },
              { value: 17, label: '17' },
              { value: 18, label: '18' },
              { value: 19, label: '19' },
              { value: 20, label: '20' },
              { value: 21, label: '21' },
              { value: 22, label: '22' },
              { value: 23, label: '23' },
              { value: 24, label: '24' },
              { value: 25, label: '25' },
              { value: 26, label: '26' },
              { value: 27, label: '27' },
              { value: 28, label: '28' },
              { value: 29, label: '29' },
              { value: 30, label: '30' },
              { value: 31, label: '31' },
              { value: 32, label: '32' },
              { value: 33, label: '33' },
              { value: 34, label: '34' },
              { value: 35, label: '35' },
              { value: 36, label: '36' },
              { value: 37, label: '37' },
              { value: 38, label: '38' },
              { value: 39, label: '39' },
              { value: 40, label: '40' },
              { value: 41, label: '41' },
              { value: 42, label: '42' },
              { value: 43, label: '43' },
              { value: 44, label: '44' },
              { value: 45, label: '45' },
              { value: 46, label: '46' },
              { value: 47, label: '47' },
              { value: 48, label: '48' },
              { value: 49, label: '49' },
              { value: 50, label: '50' },
              { value: 51, label: '51' },
              { value: 52, label: '52' },
              { value: 53, label: '53' },
              { value: 54, label: '54' },
              { value: 55, label: '55' },
              { value: 56, label: '56' },
              { value: 57, label: '57' },
              { value: 58, label: '58' },
              { value: 59, label: '59' },
              { value: 100, label: 'Disabilitato'}
            ]
          },
        },
        {
          key: 'SpegnimentoAutomaticoOra',
          label: 'Spegnimento automatico: ore',
          type: 'select',
          property: {
            options: [
              { value: 0, label: '00' },
              { value: 1, label: '01' },
              { value: 2, label: '02' },
              { value: 3, label: '03' },
              { value: 4, label: '04' },
              { value: 5, label: '05' },
              { value: 6, label: '06' },
              { value: 7, label: '07' },
              { value: 8, label: '08' },
              { value: 9, label: '09' },
              { value: 10, label: '10' },
              { value: 11, label: '11' },
              { value: 12, label: '12' },
              { value: 13, label: '13' },
              { value: 14, label: '14' },
              { value: 15, label: '15' },
              { value: 16, label: '16' },
              { value: 17, label: '17' },
              { value: 18, label: '18' },
              { value: 19, label: '19' },
              { value: 20, label: '20' },
              { value: 21, label: '21' },
              { value: 22, label: '22' },
              { value: 23, label: '23' },
              { value: 100, label: 'Disabilitato'}
            ]
          },
        },
        {
          key: 'SpegnimentoAutomaticoMinuti',
          label: 'Spegnimento automatico: minuti',
          type: 'select',
          property: {
            options: [
              { value: 0, label: '00' },
              { value: 1, label: '01' },
              { value: 2, label: '02' },
              { value: 3, label: '03' },
              { value: 4, label: '04' },
              { value: 5, label: '05' },
              { value: 6, label: '06' },
              { value: 7, label: '07' },
              { value: 8, label: '08' },
              { value: 9, label: '09' },
              { value: 10, label: '10' },
              { value: 11, label: '11' },
              { value: 12, label: '12' },
              { value: 13, label: '13' },
              { value: 14, label: '14' },
              { value: 15, label: '15' },
              { value: 16, label: '16' },
              { value: 17, label: '17' },
              { value: 18, label: '18' },
              { value: 19, label: '19' },
              { value: 20, label: '20' },
              { value: 21, label: '21' },
              { value: 22, label: '22' },
              { value: 23, label: '23' },
              { value: 24, label: '24' },
              { value: 25, label: '25' },
              { value: 26, label: '26' },
              { value: 27, label: '27' },
              { value: 28, label: '28' },
              { value: 29, label: '29' },
              { value: 30, label: '30' },
              { value: 31, label: '31' },
              { value: 32, label: '32' },
              { value: 33, label: '33' },
              { value: 34, label: '34' },
              { value: 35, label: '35' },
              { value: 36, label: '36' },
              { value: 37, label: '37' },
              { value: 38, label: '38' },
              { value: 39, label: '39' },
              { value: 40, label: '40' },
              { value: 41, label: '41' },
              { value: 42, label: '42' },
              { value: 43, label: '43' },
              { value: 44, label: '44' },
              { value: 45, label: '45' },
              { value: 46, label: '46' },
              { value: 47, label: '47' },
              { value: 48, label: '48' },
              { value: 49, label: '49' },
              { value: 50, label: '50' },
              { value: 51, label: '51' },
              { value: 52, label: '52' },
              { value: 53, label: '53' },
              { value: 54, label: '54' },
              { value: 55, label: '55' },
              { value: 56, label: '56' },
              { value: 57, label: '57' },
              { value: 58, label: '58' },
              { value: 59, label: '59' },
              { value: 100, label: 'Disabilitato'}
            ]
          },
        },
      ]
    }
  ])

  useEffect(() => {
    axios.get(`/api/machine/${id}/settings/settings`)
      .then(res => {
        console.log('settings', res.data)
        if (res.data) {
          setSettings(res.data.settings)
          setPeriodSettings(res.data.period)
        } else {
          setSettings({
            date: false,
            time: false,
            payload: []
          })
          setPeriodSettings(false)
        }
        setLoading(false)
      })
    
  }, [])

  return(
    <Section title="Impostazioni">
      <>
        {loading && <Loading />}
        {!loading && settingsDict && 
          <Fields
            settingsDict={settingsDict}
            settings={settings}
            setSettings={setSettings}
            period={periodSettings}
            topic="Settings"
            />
        }
        <UpdateBtn date={settings.date} time={settings.time} options={{topic: 'Settings/Read', id}} set={setSettings} period={periodSettings} setPeriod={setPeriodSettings} />
      </>
    </Section>
  )
}

export default Settings

import { useMachineContext } from '../../utils/machineContext'
import Section from './Section'

import { ArrowLeft, Briefcase, Check, Edit, File, Plus, X } from 'tabler-icons-react'
import { Badge, Box, Button, Group } from '@mantine/core'
import { Link, Route, Routes, useParams } from 'react-router-dom'

import routes from '../../utils/routes'
import InterventionEdit from '../InterventionEdit'
import InterventionView from '../InterventionView'
import InterventionList from '../InterventionList'
import { InterventionPDF } from '../InterventionPDF'
import { useAuthContext } from '../../utils/authContext'

const Interventions = () => {
  const { id } = useMachineContext()
  const { credentials } = useAuthContext()

  return(
    <Routes>
      <Route
        path="/:interventionId/:edit?"
        element={<InterventionSection id={id} />}
        />
      <Route
        path="/"
        element={
          <Section title="Tutti gli interventi"
            buttons={credentials.isAdmin || credentials.canIntervent ? [
              <Button leftSection={<Plus size={14} />} size="xs" variant="outline" component={Link} to={`${routes.MACHINES_A}/${id}/interventions/new/edit`}>
                Aggiungi nuovo
              </Button>
            ] : []}>
            <InterventionList machineId={id} />
          </Section>
        }
        />
    </Routes>
  )
}

const InterventionSection = ({id}) => {
  const {interventionId, edit} = useParams()
  const { credentials } = useAuthContext()
  return(
    <Section
      title={
        <Group align="center">
          Intervento
          {edit && <Badge mt={4} variant="light" size="xl">{interventionId === 'new' ? 'Nuovo' : `${interventionId}`}</Badge>}
        </Group>
      }
      buttons={[
        <Button key="back" leftSection={<Briefcase size={14} />} size="xs" variant="outline" component={Link} to={`${routes.MACHINES_A}/${id}/interventions`}>
          Torna agli interventi
        </Button>,
        interventionId === 'new' || !credentials.isAdmin || !credentials.canIntervent ? 
          <></>
          :
          !edit ? 
            <Button key="edit" leftSection={<Edit size={14} />} size="xs" variant="outline" component={Link} to={`${routes.MACHINES_A}/${id}/interventions/${interventionId}/edit`}>
              Modifica
            </Button>
            :
            <Button key="view" leftSection={<File size={14} />} size="xs" variant="outline" component={Link} to={`${routes.MACHINES_A}/${id}/interventions/${interventionId}`}>
              Visualizza
            </Button>,
        <InterventionPDF interventionId={interventionId} buttonLarge />
      ]}>
      {edit ?
        <InterventionEdit machineId={id} />
        :
        <InterventionView machineId={id} />
      } 
    </Section>
  )
}

export default Interventions
import axios from 'axios'
import { useState } from 'react'
import { Card, Center, Image, TextInput, PasswordInput, Button, Stack } from '@mantine/core'
import { At, Lock, EyeCheck, EyeOff } from 'tabler-icons-react'
import { useAuthContext } from '../utils/authContext'
import logo from '../assets/logo-maiba-h.svg'
import Policies from '../components/Policies'
import { GoogleLogin } from '@react-oauth/google'
import { useParams } from 'react-router-dom'

export default function Login() {
  let { isAdmin } = useParams()
  return (
    <Center mih="100dvh">
      <Card shadow="sm">
        <Card.Section p="lg" pb="md">
          <Image
            src={logo}
            alt="Depuratori Maiba"
            height={60}
            fit="contain"
            pb={15}
            />
        </Card.Section>
        <Card.Section p="lg" py={0}>
          {isAdmin ?
            <LoginAdmin />
            :
            <LoginClient />
          }
        </Card.Section>
        <Card.Section p="lg" pt="md">
          <Policies />
        </Card.Section>
      </Card>
    </Center>
  )
}


const LoginAdmin = () => {
  const { login } = useAuthContext()
  let params = new URL(window.location.href).searchParams

  const responseGoogle = (googleResponse) => {
    console.log(googleResponse)
    axios.post(`/login-admin`, {tokenId: googleResponse.credential})
      .then(response => {
        console.log('login-admin', response.data);
        if (response.data.accessGranted) {
          login(response.data, params.get('from'))
        }

        // GESTIONE ERRORI LOGIN: FORBIDDEN, INVALID

      })
      .catch(function (error) {
        console.log(error)
      })
  }
  return(
    <GoogleLogin
      onSuccess={responseGoogle}
      onFailure={() => {
        console.log('Google Login Failed!')
      }}
      useOneTap
      auto_select
      />
  )
}

const LoginClient = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [show, setShow] = useState(false)
  const handleClick = () => setShow(!show)

  const { login } = useAuthContext()

  const handleLogin = (e) => {
    e.preventDefault()
    axios.post(`/login`, {email, password})
      .then(response => {
        console.log(response.data)
        if (response.data.accessGranted) {
          login(response.data)
        }
      })
  }
  return(
    <Stack gap="xs" miw={280}>
      <TextInput
        size="sm"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        label="Email"
        placeholder="Inserisci l'indirizzo mail..."
        leftSection={<At size={16} />}
        />
      <PasswordInput
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        label="Password"
        placeholder="Inserisci una password..."
        leftSection={<Lock size={16} />}
        visibilityToggleIcon={({ reveal, size }) =>
          reveal ? <EyeOff size={16} /> : <EyeCheck size={16} />
        }
        />
      <Button
        onClick={handleLogin}
        >
        Entra
      </Button>
    </Stack>
  )
}
import { createContext, useContext, useState, useEffect } from 'react'
import axios from 'axios'

import { useAuthContext } from './authContext'

const MaibaContext = createContext()

const MaibaProvider = ({ children }) => {
  const [loading, setLoading] = useState({
    machines: true,
    users: true
  })
  const [machines, setMachines] = useState([])
  const [groups, setGroups] = useState({})
  const [users, setUsersState] = useState([])

  const { credentials } = useAuthContext()

  useEffect(() => {
    axios.get(`/api/machines`)
      .then(res => {
        console.log('machines list', res.data)
        setMachines(res.data.sort((a, b) => a.nickname?.localeCompare(b.nickname)))
        setLoading(state => ({
          ...state,
          machines: false,
        }))
        return res.data
      }).then(theMachinesRaw => {
        if (!credentials.isAdmin) return
        // get online status and new machines
        axios.get(`/api/machines/online`)
          .then(res => {
            console.log('machine online', res.data)
            let theMachines = theMachinesRaw
            res.data.map(item => {
              if (theMachines.filter(i => i.id === item.id).length === 0) {
                theMachines = [
                  ...theMachines,
                  {
                    id: item.id,
                    line: {
                      status: item.line,
                      date: item.date,
                      time: item.time
                    },
                    nickname: '',
                    users: [],
                    new: false,
                  }
                ]
              } else {
                theMachines = theMachines.map(m => {
                  return m.id === item.id ?
                    {
                      ...m,
                      line: {
                        status: item.status,
                        date: item.date,
                        time: item.time,
                      }
                    }
                    :
                    m
                })
              }
            })
            // ordine alfabetico, prima i dispositivi online
            setMachines(theMachines.sort((a, b)=> b.line?.status - a.line?.status || a.nickname?.localeCompare(b.nickname)))
            console.log('machines', theMachines)
          })
      })
    if (!credentials.isAdmin) return
    setUsers()
  }, [])

  useEffect(() => {
    if (loading.machines) return

    const groupsRaw = machines.reduce((r, a) => {
      r[a.group] = r[a.group] || [];
      r[a.group].push(a);
      r[a.group] = r[a.group].filter(_ => true)
      r[a.group].sort((a, b) => {
        return a.line && b.line && a.line.status && b.line.status ? 
          b.new - a.new || b.line.status - a.line.status || a.nickname?.localeCompare(b.nickname)
          :
          b.new - a.new || a.nickname?.localeCompare(b.nickname)
      })
      return r;
    }, Object.create(null))
    const groupsSorted = Object.keys(groupsRaw).sort().reduce((a, k) => {
      a[k] = groupsRaw[k]
      return a
    }, Object.create(null))
    console.log(groupsSorted)
    setGroups(groupsSorted)

  }, [loading.machines, machines])
  
  const setUsers = async () => {
    setLoading(state => ({
      ...state,
      users: true
    }))
    const res = await axios.get(`/api/users`)
    // console.log('users list', res.data)
    setUsersState(res.data)
    setLoading(state => ({
      ...state,
      users: false
    }))
  }


  return (
    <MaibaContext.Provider value={{
      loading,
      machines,
      setMachines,
      groups,
      setGroups,
      users,
      setUsers,
    }}>
      {children}
    </MaibaContext.Provider>
  )
}

const useMaibaContext = () => {
  const context = useContext(MaibaContext)

  // console.log(context);

  if (!context) {
    throw new Error('useMaibaContext must be used within MaibaProvider')
  }

  return context
}

export { MaibaProvider, useMaibaContext }

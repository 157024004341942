import { useEffect, useState } from 'react'
import moment from 'moment'
import { Group, Text, Title, Accordion, Button, Slider, Select, Switch, Stack } from '@mantine/core'

import { useMachineContext } from '../../utils/machineContext'

import { toMQTT, generateMarks } from '../../utils/func'
import { notifications } from '@mantine/notifications'
import { Check, CircleX } from 'tabler-icons-react'

const Fields = ({settingsDict, settings, setSettings, period, topic}) => {
  const {id} = useMachineContext()
  moment.locale('it')

  const [isSending, setIsSending] = useState({})
  const [newSettings, setNewSettings] = useState(settings)

  useEffect(() => {
    setNewSettings(settings)
  }, [settings])

  const handleSave = (key) => {
    setIsSending({
      ...isSending,
      [key]: true
    })
    toMQTT([
        {
          id,
          topic: `${topic}/Write`,
          message: {
            [key]: newSettings.payload[key]
          }
        },
        {
          id,
          topic: `${topic}/Read`,
          message: {
            data: {},
            period: period
          }
        }
      ])
      .then(res => {
        if (res.result === 'ok') {
          setSettings({
            date: res.date,
            time: res.time,
            payload: {
              ...settings.payload,
              [key]: res.payload[key]
            }
          })
          notifications.show({
            title: 'Successo',
            message: 'I valori sono stati aggiornati!',
            color: 'green',
            icon: <Check size={18} />
          })
        } else {
          notifications.show({
            title: 'Errore',
            message: 'La richiesta è andata in timeout!',
            color: 'red',
            icon: <CircleX size={18} />
          })
        }
        setIsSending({
          ...isSending,
          [key]: false
        })
      })
  }

  return(
      <>
        {settingsDict.map((setting, i) => {
          const groupIsModified = setting.fields.filter(e => settings.payload[e.key] != newSettings.payload[e.key]).length > 0
          return(
          <>
            <Group key={setting.title} position="center" align="baseline">
              <Title mt={i === 0 ? 0 : 60} order={3} align="center" c={groupIsModified ? 'blue' : 'black'}>
                {setting.title}
              </Title>
              {groupIsModified &&
                <Title order={4} c="red">Impostazioni modificate, ma non salvate!</Title>
              }
            </Group>
            <Accordion variant="contained">
              {setting.fields.map(field => {
                const isModified = settings.payload[field.key]?.toString() === newSettings.payload[field.key]?.toString()
                return(
                  <Accordion.Item
                    key={field.key}
                    value={field.key}
                    >
                    <Accordion.Control>
                      <Group justify="space-between">
                          <Text size="lg" c={isModified ? 'black' : 'red'}>{field.label}</Text>
                          {settings.payload[field.key] === undefined && 
                            <Text size="xl" weight={700} c="dimmed">
                              ---
                            </Text>
                          }
                          {settings.payload[field.key] !== undefined && 
                            <Group gap="xs" align="baseline" px="md">
                              <Text size="xl" fw={700}>
                                {field.type === 'checkbox' ?
                                  settings.payload[field.key] === 1 ? 'Attivo' : 'Inattivo'
                                  :
                                  field.type === 'select' ?
                                    field.property.options.filter(e => 
                                      e.value.toString() === settings.payload[field.key]?.toString()).length > 0 ? 
                                      field.property.options.filter(e => e.value.toString() === settings.payload[field.key]?.toString())[0].label 
                                      : ''
                                    :
                                    settings.payload[field.key]
                                }
                              </Text>
                              {field.unit && <Text size="md" color="dimmed">{field.unit}</Text>}
                            </Group>
                          }
                        </Group>
                    </Accordion.Control>
                    <Accordion.Panel>
                      <Stack
                        align="stretch"    
                        p={10}
                        >
                        {field.type === 'checkbox' &&
                          <Switch
                            checked={newSettings.payload[field.key]}
                            onChange={(event) => setNewSettings(state => ({
                              ...state,
                              payload: {
                                ...state.payload,
                                [field.key]:event.target.checked ? 1 : 0
                              }
                            }))}
                            label={`Attiva ${field.label.replace(':', '').toLowerCase()}`}
                            onLabel="ON"
                            offLabel="OFF"
                            size="md"
                            sx={{ flexGrow: 1 }}
                            />
                        }
                        {field.type === 'select' &&
                          <Select
                            value={newSettings.payload[field.key]?.toString()}
                            onChange={(value) => setNewSettings(state => ({
                              ...state,
                              payload: {
                                ...state.payload,
                                [field.key]: value
                              }
                            }))}
                            data={field.property.options.map(option => ({
                              value: option.value.toString(),
                              label: `${option.label}${field.unit ? ` ${field.unit}` : ``}`
                            }))}
                            sx={{ flexGrow: 1 }}
                            />
                        }
                        {field.type === 'slider' &&
                          <Slider
                            value={newSettings.payload[field.key]}
                            onChange={(value) => setNewSettings(state => ({
                              ...state,
                              payload: {
                                ...state.payload,
                                [field.key]: value
                              }
                            }))}
                            marks={generateMarks(field.property.min, field.property.max)}
                            label={(value) => `${value} ${field.unit}`}
                            labelAlwaysOn
                            {...field.property}
                            sx={{ flexGrow: 1 }}
                            styles={{
                              markWrapper: {
                                width: 'fit-content',
                                fontSize: 14,
                                color: '#868e96',
                              },
                            }}
                            />
                        }
                        <Text size="xs" mt={20} color="dimmed">{field.hint}</Text>
                        <Group justify="space-between" mt={30}>
                          <Button
                            onClick={() => setNewSettings({
                              ...newSettings,
                              payload: {
                                ...newSettings.payload,
                                [field.key]: settings.payload[field.key]
                            }})}
                            size="sm"
                            color="red"
                            disabled={isSending[field.key] || isModified}
                            >
                            Reset
                          </Button>
                          <Button
                            onClick={() => handleSave(field.key)}
                            loading={isSending[field.key] || false}
                            size="sm"
                            sx={{
                              minWidth: 16
                            }}
                            color="green"
                            disabled={isModified}
                            >
                            Salva
                          </Button>
                        </Group>
                      </Stack>
                    </Accordion.Panel>
                  </Accordion.Item>
                )}
              )}
            </Accordion>
          </>
        )}
        )}
      </>
  )
}

export default Fields
